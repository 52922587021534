import React from "react";
import "../assets/css/loader.css";
import GOLD from "../assets/Icons/Gold.png";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="coin-spinner">
        <img src={GOLD} alt="Loading..." className="coin" />
      </div>
      <p className="loading-text">Loading, please wait...</p>
    </div>
  );
};

export default Loader;
