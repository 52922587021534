import React, { useEffect, useState } from "react";
import "../../assets/css/goldFdDetails.css";
import axios from "axios";
import Swal from "sweetalert2";
import LockerImg from "../../assets/images/almera_gold.png";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../utils/baseUrl";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";

const GoldFdDetails = () => {
  const token = sessionStorage.getItem("token");
  const { clientId, id } = useParams();
  const navigate = useNavigate();

  const [filterdata, setFilterData] = useState([]);
  const [deatilsData, setDetailsData] = useState([]);
  const [preData, setPreData] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [tandC, setTandC] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsError, setTermsError] = useState("");
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [multipliedValue, setMultipliedValue] = useState(0);

  const interest = preData.interest;

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    try {
      const url = `${baseUrl}/partnerPortal/fd/getPort/${clientId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const fdData = response.data.data[0];
      setDetailsData(fdData);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  useEffect(() => {
    preOrderCal();
  }, []);

  const preOrderCal = async () => {
    try {
      const url = `${baseUrl}/partnerPortal/fd/preOrderCalculate/${clientId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const preData = {
        fdSchemeId: id,
        quantity: 1,
      };
      const response = await axios.post(url, preData, { headers });;
      const fdData = response.data.data.result.data;
      setPreData(fdData);
      setUniqueId(fdData.uniqueId);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  useEffect(() => {
    if (uniqueId) {
      termAndCondition();
    }
  }, [uniqueId]);

  const termAndCondition = async () => {
    try {
      const url = `${baseUrl}/fd/terms&Condition`;

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = {
        fdSchemeId: id,
        uniqueId: uniqueId,
      };
      const response = await axios.post(url, body, { headers });
      const TandCData = response.data.data;
      setTandC(TandCData);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const getFdList = async () => {
    try {
      const url = `${baseUrl}/partnerPortal/fd/fdSchemeList/${clientId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const fdData = response.data.data.result.data;

      const filteredData = fdData.find((data) => data.id === id);

      setFilterData(filteredData);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  useEffect(() => {
    getFdList();
  }, []);

  const maturity =
    parseFloat(preData.interest) +
    parseFloat(deatilsData.augmont?.gold?.balQuantity);

  const handleQuantityChange = (event) => {
    const value = event.target.value;
    setQuantity(value);

    // Multiply the input value by 2
    const multiplied = value * interest;
    setMultipliedValue(multiplied);

    const availableGold = Number(deatilsData.augmont?.gold?.balQuantity || 0);
    const enteredQuantity = Number(value);

    if (enteredQuantity <= 0) {
      setQuantityError("Quantity must be greater than zero.");
    } else if (enteredQuantity > availableGold) {
      setQuantityError(
        `Entered quantity exceeds available gold in locker (${availableGold} gm).`
      );
    } else {
      setQuantityError("");
    }
  };

  const handleTermsChange = (event) => {
    setTermsChecked(event.target.checked);
    setTermsError("");
  };

  const createFd = async () => {
    if (quantityError || !termsChecked) {
      if (!termsChecked) {
        setTermsError("Please accept the terms and conditions.");
      }
      return;
    }

    const body = {
      fdSchemeId: String(id),
      quantity: Number(quantity),
    };

    try {
      const url = `${baseUrl}/partnerPortal/fd/create/${clientId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(url, body, { headers });

      if (response.status === 200) {
        Swal.fire({
          title: "Order Created Successfully",
          // text: `Order ID: ${orderData.order_no}`,
          icon: "success",
        });
        navigate("/gold-fd");
      }
    } catch (error) {
      if (error.response) {
        Swal.fire({
          title: "Order Creation Failed",
          text: error.response.data.message.message,
          icon: "error",
        });
      } else {
        console.error("Error creating order:", error.message);
        Swal.fire({
          title: "Order Creation Failed",
          text: `Error: ${error.message}`,
          icon: "error",
        });
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createFd();
  };

  const handleModalConfirm = () => {
    setTermsChecked(true);
    setShowTermsModal(false);
  };

  return (
    <div className="" style={{ width: "100%", padding: "1rem" }}>
      <div className="row">
        <main className="col-lg-6">
          <div className="header">
            <div
              className="col-md-6"
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                width: "50%",
              }}
            >
              <div>
                <h3>{filterdata.interestRate}% Interest p.a.</h3>
                <p style={{ backgroundColor: "#4d16a4", color: "#fff" }}>
                  Maturity in {filterdata.tenure} days
                </p>
              </div>
            </div>

            <div
              className="col-md-6"
              style={{
                display: "flex",
                justifyContent: "end",
                width: "50%",
              }}
            >
              <img
                src={LockerImg}
                alt="LockerImg"
                srcSet=""
                style={{ maxWidth: "50%" }}
              />
            </div>
          </div>
          <hr style={{ backgroundColor: "#4d16a4", height: "2px" }} />
          <div className="checkout-container">
            <div className="form-container">
              <form onSubmit={handleSubmit}>
                <div className="row row-bottom">
                  <div className="col-md-12">
                    <label>Gold Fd Amount</label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="number"
                        name="quantity"
                        value={quantity}
                        onChange={handleQuantityChange}
                        isInvalid={!!quantityError}
                        required
                      />
                      <InputGroup.Text style={{ border: "2px solid" }}>
                        <span className="rupee">gm</span>
                      </InputGroup.Text>

                      <Form.Control.Feedback type="invalid">
                        {quantityError || "Please enter Gold Fd Amount."}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <p>
                    Gold In Locker: {deatilsData.augmont?.gold?.balQuantity} gm
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#dcdaff",
                    padding: "0.5rem",
                    borderRadius: "8px",
                    marginBottom: "1rem",
                  }}
                >
                  <div className="col-md-6" style={{ width: "50%" }}>
                    <p
                      style={{
                        display: "flex",
                        marginBottom: "0px",
                      }}
                    >
                      gm on maturity: {quantity}
                    </p>
                    <p
                      style={{
                        color: "green",
                        display: "flex",
                        marginBottom: "0px",
                      }}
                    >
                      +{multipliedValue.toFixed(4)} gm as interest
                    </p>
                  </div>
                  <div className="col-md-6" style={{ width: "50%" }}>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginBottom: "0px",
                      }}
                    >
                      Start date : {filterdata.startDate}
                    </p>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginBottom: "0px",
                      }}
                    >
                      End Date : {filterdata.endDate}
                    </p>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <Form.Check
                      type="checkbox"
                      label={
                        <div
                          style={{
                            display: "flex",
                          }}
                          onClick={() => setShowTermsModal(true)}
                        >
                          {termsChecked ? (
                            <p
                              style={{
                                marginLeft: "1rem",
                              }}
                            >
                              I have read and agree to the
                            </p>
                          ) : (
                            <p
                              style={{
                                marginLeft: "1rem",
                              }}
                            >
                              Please Accept the
                            </p>
                          )}
                          <span
                            style={{
                              color: "blue",
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            Terms and conditions
                          </span>
                        </div>
                      }
                      checked={termsChecked}
                      onChange={handleTermsChange}
                      isInvalid={!!termsError}
                    />
                    <Form.Control.Feedback type="invalid">
                      {termsError}
                    </Form.Control.Feedback>
                  </div>

                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#4a2a7d",
                      border: "none",
                      width: "100%",
                      height: "45px",
                      marginBottom: "1rem",
                    }}
                  >
                    Book Gold FD
                  </Button>
                </div>
              </form>
            </div>
          </div>
          {/* Modal for Terms and Conditions */}
          <Modal
            show={showTermsModal}
            onHide={() => setShowTermsModal(false)}
            style={{
              marginTop: "10rem",
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <h3 className="text-center fw-bold form_header ">
              {tandC.heading}
            </h3>
            <div>
              <div className="form-group p-3">
                <p style={{ display: "flex", textAlign: "justify" }}>
                  {tandC.paragraphs}
                </p>
              </div>
              <Button
                variant="contained"
                type="button"
                className="btn btn-primary mt-2 mb-3"
                style={{ margin: "35%" }}
                onClick={handleModalConfirm}
              >
                Accept T&C
              </Button>
            </div>
          </Modal>
        </main>
        <aside className="col-lg-6">
          <div className="border rounded-4 mb-3 d-flex justify-content-center">
            <div className="row p-2">
              <h4
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Scheme Details
              </h4>
              <dt className="col-6">Scheme Name:</dt>
              <dd
                className="col-6"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "0px",
                }}
              >
                {filterdata.name}
              </dd>
              <dt className="col-6">jewellers Name:</dt>
              <dd
                className="col-6"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "0px",
                }}
              >
                {filterdata.jewellerUserAccountName}
              </dd>
              <dt className="col-6">Tenure:</dt>
              <dd
                className="col-6"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "0px",
                }}
              >
                {filterdata.tenure}
              </dd>
              <dt className="col-6">remaining Tenure:</dt>
              <dd
                className="col-6"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "0px",
                }}
              >
                {filterdata.remaingTenure}
              </dd>
              <dt className="col-6">Interest Rate:</dt>
              <dd
                className="col-6"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "0px",
                }}
              >
                {filterdata.interestRate}
              </dd>
              <dt className="col-6">TDS Rate:</dt>
              <dd
                className="col-6"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "0px",
                }}
              >
                {filterdata.tdsRate}
              </dd>
              <dt className="col-6">Start Date:</dt>
              <dd
                className="col-6"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "0px",
                }}
              >
                {filterdata.startDate}
              </dd>
              <dt className="col-6">End Date:</dt>
              <dd
                className="col-6"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "0px",
                }}
              >
                {filterdata.endDate}
              </dd>
              <dt className="col-6">Minimum Qty:</dt>
              <dd
                className="col-6"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "0px",
                }}
              >
                {filterdata.minQtyLease}
              </dd>
            </div>
          </div>
          <div className="border rounded-4 mb-3 d-flex justify-content-center">
            <div className="row p-2">
              <h4 style={{ display: "flex", justifyContent: "center" }}>
                About Jeweller
              </h4>
              <dt className="col-6">Name:</dt>
              <dd
                className="col-6"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "0px",
                }}
              >
                {filterdata.name}
              </dd>
              <dt className="col-6">Since:</dt>
              <dd
                className="col-6"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "0px",
                }}
              >
                {filterdata.profile?.yearOfOperation}
              </dd>
              <dt className="col-6">Turn Over:</dt>
              <dd
                className="col-6"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "0px",
                }}
              >
                {filterdata.profile?.turnOver}
              </dd>
              <dt className="col-6">State:</dt>
              <dd
                className="col-6"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "0px",
                }}
              >
                {filterdata.profile?.userState}
              </dd>
              <dt className="col-6">City:</dt>
              <dd
                className="col-6"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "0px",
                }}
              >
                {filterdata.profile?.userCity}
              </dd>
              <dd className="col-12" style={{ textAlign: "justify" }}>
                {filterdata.profile?.description}
              </dd>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default GoldFdDetails;
