import { configureStore } from "@reduxjs/toolkit";
import clientReducer from "./clientDataSlice.js";


const store = configureStore({
  reducer: {
    clientReducer: clientReducer
  }
    
});


export default store;
