import React, { useEffect, useState } from "react";
import "../../assets/css/create_gold-fd.css";
import { Button } from "react-bootstrap";
import { baseUrl } from "../../utils/baseUrl";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import noImage from "../../assets/images/no_image.png";

const CreateGoldFd = () => {
  const token = sessionStorage.getItem("token");
  const { clientId } = useParams();
  const [fdlist, setFdList] = useState([]);

  const getFdList = async () => {
    try {
      const url = `${baseUrl}/partnerPortal/fd/fdSchemeList/${clientId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const fdData = response.data.data.result.data;

      setFdList(fdData);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  useEffect(() => {
    getFdList();
  }, []);

  return (
    <div
      className=""
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "1rem",
            marginTop: "6rem",
          }}
        >
          <h2 style={{ fontWeight: "bolder", color: " #4d16a4" }}>
            Gold FD Schemes
          </h2>
        </div>
        <div className="" style={{ width: "100%", display: "flex" }}>
          {fdlist.map((item, i) => (
            <div className="" key={i}>
              <Link
                className="card1"
                to={{
                  pathname: `/create-gold-fd/${clientId}/${item.id}`,
                }}
                style={{
                  color: " #4d16a4",
                  border: "1px solid",
                  borderRadius: "10px",
                  boxShadow: "3px 5px 9px black",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <img
                    src={item.profile.logo}
                    alt=""
                    srcset=""
                    style={{ width: "50px", borderRadius: "50%" }}
                  />
                  <h3>{item.jewellerUserAccountName}</h3>
                </div>
                <hr />
                <div className="row">
                  <dt className="col-6 table-data">Scheme Name :</dt>
                  <dd
                    className="col-6 table-data"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    {item.name}
                  </dd>

                  <dt className="col-6 table-data">Tenure :</dt>
                  <dd
                    className="col-6 table-data"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    {item.tenure}
                  </dd>

                  <dt className="col-6 table-data">Interest Rate :</dt>
                  <dd
                    className="col-6 table-data"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    {item.interestRate}
                  </dd>

                  <dt className="col-6 table-data">MinQty Lease :</dt>
                  <dd
                    className="col-6 table-data"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    {item.minQtyLease}
                  </dd>

                  <dt className="col-6 table-data">
                    Start Date : {item.startDate}
                  </dt>
                  <dd
                    className="col-6 table-data"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    End Date : {item.endDate}
                  </dd>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CreateGoldFd;
