import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../utils/baseUrl";
import kycDone from "../assets/images/kycDone.png";
import verifiedIcon from "../assets/Icons/Verified.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CircularProgress from "@mui/material/CircularProgress";

const KYCForm = () => {
  const token = sessionStorage.getItem("token");
  const location = useLocation();
  const clientStatus = location.state.status;
  const [isEditing, setIsEditing] = useState(false);

  const [stateData, setStateData] = useState([0]);
  const [citiesData, setCitiesData] = useState([]);
  const [stateId, setStateId] = useState("");
  const [singleStateId, setSingleStateId] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");
  const [panVerified, setPanVerified] = useState(false);
  const [bankVerified, setBankVerified] = useState(false);
  const [isPanLoading, setIsPanLoading] = useState(false);
  const [isBankLoading, setIsBankLoading] = useState(false);
  const [isFinalLoading, setIsFinalLoading] = useState(false);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    PanNumber: location.state.PanNumber,
    dob: location.state.dob,
    email: location.state.Email,
    name: location.state.name,
    mobileNumber: location.state.mobileNo,
    address_line_1: location.state.address_line_1,
    address_line_2: location.state.address_line_2,
    state: location.state.state,
    city: location.state.city,
    zip: location.state.zip,
    accountHolderName: location.state.name,
    bank_account_no: location.state.bank_account_no,
    confirmAccountNo: location.state.bank_account_no,
    ifsc_code: location.state.ifsc_code,
  });

  useEffect(() => {
    if (stateId) {
      fetchCity(stateId);
    }
  }, [stateId]);

  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
    if (e.target.name == "stateName") {
      setStateId(e.target.value);
    }
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dob: date ? date.toISOString().split("T")[0] : "",
    });
    if (errors.dob) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        dob: "",
      }));
    }
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.PanNumber) tempErrors.PanNumber = "PAN No. is required";
    if (!formData.dob) tempErrors.dob = "DOB is required";
    if (!formData.email) tempErrors.email = "Email is required";
    if (!formData.name) tempErrors.name = "Name is required";
    if (!formData.mobileNumber)
      tempErrors.mobileNumber = "Mobile No. is required";
    if (!formData.address_line_1)
      tempErrors.address_line_1 = "Address Line 1 is required";
    if (!formData.address_line_2)
      tempErrors.address_line_2 = "Landmark is required";
    if (!formData.stateName) tempErrors.stateName = "State is required";
    if (!formData.cityName) tempErrors.cityName = "City is required";
    if (!formData.zip) tempErrors.zip = "PIN Code is required";
    if (!formData.accountHolderName)
      tempErrors.accountHolderName = "Account Holder's Name is required";
    if (!formData.bank_account_no) {
      tempErrors.bank_account_no = "Bank account number is required";
    }
    if (!formData.confirmAccountNo) {
      tempErrors.confirmAccountNo = "Confirm account number is required";
    } else if (formData.confirmAccountNo !== formData.bank_account_no) {
      tempErrors.confirmAccountNo = "Account numbers do not match";
    }
    if (!formData.ifsc_code) tempErrors.ifsc_code = "IFSC Code is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate() === true) {
      if (!panVerified || !bankVerified) {
        toast.error("Please verify PAN and Bank details before submitting.");
        return;
      } else {
        kycApi();
      }
    }
  };

  const kycApi = async () => {
    setIsFinalLoading(false);
    try {
      const url = `${baseUrl}/client_kyc/${location.state._id}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const payload = {
        PanNumber: formData.PanNumber,
        dob: formData.dob,
        address_line_1: formData.address_line_1,
        address_line_2: formData.address_line_2,
        state: selectedStateName || formData.state,
        mobileNumber: formData.mobileNumber,
        city: formData.cityName || formData.city,
        zip: formData.zip,
        bank_account_no: formData.bank_account_no,
        bank_account_name: formData.accountHolderName,
        ifsc_code: formData.ifsc_code,
      };
      const response = await axios.post(url, payload, { headers });
      if (response.data.error == "User not created") {
        toast.error(response.data.err.pincode[0].message);
      } else if (response.data.error == true) {
        toast.error(response.data.message);
      } else {
        toast.success("KYC successful");
        navigate("/dashboard/client_table");
      }

      setFormData({
        PanNumber: location.state.PanNumber,
        dob: location.state.dob,
        email: location.state.Email,
        name: location.state.name,
        mobileNumber: location.state.mobileNo,
        address_line_1: location.state.address_line_1,
        address_line_2: location.state.address_line_2,
        state: location.state.state,
        city: location.state.city,
        zip: location.state.zip,
        accountHolderName: location.state.name,
        bank_account_no: location.state.bank_account_no,
        confirmAccountNo: location.state.bank_account_no,
        ifsc_code: location.state.ifsc_code,
      });
    } catch (error) {
      toast.error(error.response.data.message || error.message);
      console.error("Error connecting to API", error);
    } finally {
      setIsFinalLoading(false);
    }
  };

  useEffect(() => {
    const fetchStates = async (stateId) => {
      try {
        const url = `${baseUrl}/augmontGold/states`;
        const response = await axios.get(url);
        const statesData = response.data.data;
        setStateData(statesData);
        const filteredState = statesData?.filter(
          (state) => state?.id == stateId
        );

        if (filteredState) {
          setSelectedStateName(filteredState[0]?.name);
        }
      } catch (error) {
        console.error("Error connecting to API", error);
      }
    };

    fetchStates(stateId);
  }, [stateId]);

  const fetchCity = async (id) => {
    try {
      const url = `${baseUrl}/augmontGold/city/${id}`;
      const response = await axios.get(url);
      const cityData = response.data.data;

      setCitiesData(cityData);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const verifyPan = async (e) => {
    e.preventDefault();
    if (!formData.PanNumber) {
      setErrors((prev) => ({
        ...prev,
        PanNumber: "PAN No. is required",
      }));
      return;
    }

    if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formData.PanNumber)) {
      setErrors((prev) => ({
        ...prev,
        PanNumber: "Invalid PAN format",
      }));
      return;
    }

    const data = { pannumber: formData.PanNumber };
    const config = {
      method: "post",
      url: `${baseUrl}/partnerPortal/panVerify`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    setIsPanLoading(true); // Start loading

    try {
      const result = await axios.request(config);
      const parsedData = JSON.parse(result.data.data[0]);
      if (parsedData?.msg?.STATUS === "Active") {
        toast.success("PAN verified successfully!");
        setPanVerified(true);
      } else {
        toast.warn(parsedData.msg);
      }
    } catch (error) {
      console.error("Error verifying PAN:", error);
      toast.error("Failed to verify PAN. Please try again.");
    } finally {
      setIsPanLoading(false);
    }
  };

  const verifyBank = async (e) => {
    e.preventDefault();
    const { bank_account_no, confirmAccountNo, ifsc_code } = formData;
    if (!bank_account_no || !confirmAccountNo || !ifsc_code) {
      setErrors((prev) => ({
        ...prev,
        bank_account_no: !bank_account_no ? "Bank Account No. is required" : "",
        confirmAccountNo: !confirmAccountNo
          ? "Confirm Account No. is required"
          : "",
        ifsc_code: !ifsc_code ? "IFSC Code is required" : "",
      }));
      return;
    }

    if (bank_account_no !== confirmAccountNo) {
      setErrors((prev) => ({
        ...prev,
        bank_account_no: "Bank Account No not matched",
        confirmAccountNo: "Confirm Account No not matched",
      }));
      return;
    }

    const data = {
      BankAccount: bank_account_no,
      ifsc: ifsc_code,
    };

    const config = {
      method: "post",
      url: `${baseUrl}/partnerPortal/bankVerify`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    setIsBankLoading(true);
    try {
      const result = await axios.request(config);
      const parsedData = JSON.parse(result.data.data[0]);

      if (parsedData.msg.description === "Failed") {
        toast.error(parsedData.msg.status);
      } else {
        toast.success("Bank verified successfully!");
        setBankVerified(true);
      }
    } catch (error) {
      console.error("Error verifying bank details:", error);
      toast.error("Failed to verify bank details. Please try again.");
    } finally {
      setIsBankLoading(false);
    }
  };

  const [marginBottom, setMarginBottom] = useState("58px");

  useEffect(() => {
    const updateMargin = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 768) {
        setMarginBottom("58px");
      } else if (screenWidth <= 1272) {
        setMarginBottom("26px");
      } else {
        setMarginBottom("58px");
      }
    };
    updateMargin();
    window.addEventListener("resize", updateMargin);
    return () => {
      window.removeEventListener("resize", updateMargin);
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="registration-form" style={{ padding: "0rem" }}>
        <div className="col-lg-8 profile-bottom" style={{ width: "98%" }}>
          {!isEditing ? (
            <div
              className="profile-show"
              style={{
                padding: "1rem",
                border: "1px solid",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h4 className="mb-4 center-text text-color">Complete KYC</h4>

                {clientStatus === 3 ? (
                  <div
                    style={{
                      width: "8rem",
                      height: "2.7rem",
                    }}
                  >
                    <img
                      src={kycDone}
                      alt=""
                      srcSet=""
                      style={{ marginTop: "-33px" }}
                    />
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    className="btn btn-primary"
                    onClick={() => setIsEditing(true)}
                    style={{
                      textTransform: "capitalize",
                      height: "2.5rem",
                      marginBottom: "1rem",
                      width: "8rem",
                      height: "2.7rem",
                      backgroundColor: "#4a2a7d",
                      border: "none",
                    }}
                  >
                    Update KYC
                  </Button>
                )}
              </div>
              <h4
                className="mb-4 text-color"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#dcdaff",
                }}
              >
                PAN Details
              </h4>
              <div className="profile-text">
                <Row className="row-bottom">
                  <Col md={4}>
                    <Form.Group controlId="formPanNumber">
                      <Form.Label>PAN No.</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>{formData.PanNumber}</h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formDob">
                      <Form.Label>DOB</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>
                        {(() => {
                          if (formData.dob) {
                            const formatDate = (dateString) => {
                              const dateParts = dateString.includes("-")
                                ? dateString.split("-")
                                : dateString.split("/");

                              if (dateParts.length === 3) {
                                const [year, month, day] =
                                  dateParts[0].length === 4
                                    ? [dateParts[0], dateParts[1], dateParts[2]]
                                    : [
                                        dateParts[2],
                                        dateParts[1],
                                        dateParts[0],
                                      ];
                                return `${day}/${month}/${year}`;
                              }
                              return "";
                            };

                            return formatDate(formData.dob);
                          }
                          return "";
                        })()}
                      </h5>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>{formData.email}</h5>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <h4
                className="mb-4 text-color"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#dcdaff",
                }}
              >
                Personal Details
              </h4>
              <div className="profile-text">
                <Row className="row-bottom">
                  <Col md={4}>
                    <Form.Group controlId="formName">
                      <Form.Label>Name</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>{formData.name}</h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formMobileNumber">
                      <Form.Label>Mobile No.</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>
                        {formData.mobileNumber}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formAddressLine1">
                      <Form.Label>Address Line 1</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>
                        {formData.address_line_1}
                      </h5>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="row-bottom">
                  <Col md={4}>
                    <Form.Group controlId="formAddressLine2">
                      <Form.Label>Address Line 2</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>
                        {formData.address_line_2}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formState">
                      <Form.Label>State</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>{formData.state}</h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formCity">
                      <Form.Label>City</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>{formData.city}</h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formZip">
                      <Form.Label>PIN Code</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>{formData.zip}</h5>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <h4
                className="mb-4 text-color"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#dcdaff",
                }}
              >
                Bank Details
              </h4>
              <div className="profile-text">
                <Row className="row-bottom">
                  <Col md={4}>
                    <Form.Group controlId="formAccountHolderName">
                      <Form.Label>Account Holder Name</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>
                        {formData.accountHolderName}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBankAccountNo">
                      <Form.Label>Bank Account No.</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>
                        {formData.bank_account_no}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formConfirmAccountNo">
                      <Form.Label>Confirm Account No.</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>
                        {formData.confirmAccountNo}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formIfscCode">
                      <Form.Label>IFSC Code</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>{formData.ifsc_code}</h5>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            <div
              className="profile-edit"
              style={{
                padding: "1rem",
                border: "1px solid",
                borderRadius: "8px",
              }}
            >
              <Form>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h4 className="mb-4 center-text text-color">Complete KYC</h4>
                  <Button
                    variant="contained"
                    className="btn btn-primary"
                    onClick={() => setIsEditing(false)}
                    style={{
                      textTransform: "capitalize",
                      height: "2.5rem",
                      marginBottom: "1rem",
                      width: "8rem",
                      height: "2.7rem",
                      backgroundColor: "#4a2a7d",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <h4
                  className="mb-4 text-color"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#dcdaff",
                  }}
                >
                  PAN Details
                </h4>
                <Row className="row-bottom">
                  <Col md={4} style={{ display: "flex" }}>
                    <Col md={9}>
                      <Form.Group controlId="formPanNumber">
                        <Form.Label>PAN No.</Form.Label>
                        <Form.Control
                          type="text"
                          name="PanNumber"
                          value={formData.PanNumber}
                          onChange={handleChange}
                          isInvalid={!!errors.PanNumber}
                          maxLength={10}
                          pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                          disabled={panVerified}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.PanNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "24px",
                      }}
                    >
                      <Form.Label style={{ marginBottom }}></Form.Label>
                      {panVerified ? (
                        <img
                          src={verifiedIcon}
                          alt="verified-icon"
                          srcset=""
                          style={{ width: "40px", marginLeft: "5px" }}
                        />
                      ) : (
                        <button
                          onClick={verifyPan}
                          style={{
                            padding: "7px 15px",
                            backgroundColor: "#4a2a7d",
                            color: "white",
                            border: "none",
                            cursor: "pointer",
                            borderRadius: "8px",
                            marginLeft: "2px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          disabled={isPanLoading}
                        >
                          {isPanLoading ? (
                            <CircularProgress
                              size={20}
                              sx={{ color: "#fff" }}
                            />
                          ) : (
                            "Verify"
                          )}
                        </button>
                      )}
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Form.Group
                      controlId="formDob"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Form.Label>DOB</Form.Label>
                      <DatePicker
                        selected={formData.dob ? new Date(formData.dob) : null}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        className={`form-control ${
                          errors.dob ? "is-invalid" : ""
                        }`}
                        placeholderText="dd/mm/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      {errors.dob && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.dob}
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                        disabled={!!formData.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <h4
                  className="mb-4 text-color"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#dcdaff",
                  }}
                >
                  Personal Details
                </h4>
                <Row className="row-bottom">
                  <Col md={4}>
                    <Form.Group controlId="formName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        isInvalid={!!errors.name}
                        disabled={!!formData.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formMobileNumber">
                      <Form.Label>Mobile No.</Form.Label>
                      <Form.Control
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        isInvalid={!!errors.mobileNumber}
                        disabled={!!formData.name}
                        maxLength={10}
                        pattern="\d{10}"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.mobileNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formAddressLine1">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        name="address_line_1"
                        value={formData.address_line_1}
                        onChange={handleChange}
                        isInvalid={!!errors.address_line_1}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address_line_1}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="row-bottom">
                  <Col md={4}>
                    <Form.Group controlId="formAddressLine2">
                      <Form.Label>Landmark</Form.Label>
                      <Form.Control
                        type="text"
                        name="address_line_2"
                        value={formData.address_line_2}
                        onChange={handleChange}
                        isInvalid={!!errors.address_line_2}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address_line_2}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId="stateName">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        as="select"
                        name="stateName"
                        onChange={handleChange}
                        defaultValue={singleStateId}
                        isInvalid={!!errors.stateName}
                      >
                        <option value="">Select State</option>
                        {stateData.map((val, i) => (
                          <option key={i} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.stateName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId="cityName">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        as="select"
                        name="cityName"
                        onChange={handleChange}
                        defaultValue={formData.city}
                        className="form-control"
                        isInvalid={!!errors.cityName}
                      >
                        <option value="">Select City</option>
                        {citiesData.map((val) => (
                          <option key={val.id} value={val.name}>
                            {val.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.cityName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId="formZip">
                      <Form.Label>PIN Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="zip"
                        value={formData.zip}
                        maxLength={6}
                        pattern="\d{6}"
                        onChange={handleChange}
                        isInvalid={!!errors.zip}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.zip}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <h4
                  className="mb-4 text-color"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#dcdaff",
                  }}
                >
                  Bank Details
                </h4>
                <Row className="row-bottom">
                  <Col md={4}>
                    <Form.Group controlId="formAccountHolderName">
                      <Form.Label>Account Holder Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="accountHolderName"
                        value={formData.accountHolderName}
                        onChange={handleChange}
                        disabled={bankVerified}
                        isInvalid={!!errors.accountHolderName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.accountHolderName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBankAccountNo">
                      <Form.Label>Bank Account No.</Form.Label>
                      <Form.Control
                        type="text"
                        name="bank_account_no"
                        value={formData.bank_account_no}
                        onChange={handleChange}
                        disabled={bankVerified}
                        isInvalid={!!errors.bank_account_no}
                        pattern="\d{9,18}"
                        maxLength={18}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.bank_account_no}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formConfirmAccountNo">
                      <Form.Label>Confirm Account No.</Form.Label>
                      <Form.Control
                        type="text"
                        name="confirmAccountNo"
                        value={formData.confirmAccountNo}
                        onChange={handleChange}
                        disabled={bankVerified}
                        isInvalid={!!errors.confirmAccountNo}
                        pattern="\d{9,18}"
                        maxLength={18}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmAccountNo}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={4} style={{ display: "flex" }}>
                    <Col md={9}>
                      <Form.Group controlId="formIfscCode">
                        <Form.Label>IFSC Code</Form.Label>
                        <Form.Control
                          type="text"
                          name="ifsc_code"
                          value={formData.ifsc_code}
                          onChange={handleChange}
                          disabled={bankVerified}
                          isInvalid={!!errors.ifsc_code}
                          maxLength={11}
                          pattern="^[A-Za-z]{4}[a-zA-Z0-9]{7}$"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.ifsc_code}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "24px",
                      }}
                    >
                      <Form.Label style={{ marginBottom }}></Form.Label>
                      {bankVerified ? (
                        <img
                          src={verifiedIcon}
                          alt="verified-icon"
                          style={{ width: "40px", marginLeft: "5px" }}
                        />
                      ) : (
                        <button
                          onClick={verifyBank}
                          style={{
                            padding: "7px 15px",
                            backgroundColor: "#4a2a7d",
                            color: "white",
                            border: "none",
                            cursor: "pointer",
                            borderRadius: "8px",
                            marginLeft: "2px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          disabled={isBankLoading}
                        >
                          {isBankLoading ? (
                            <CircularProgress
                              size={20}
                              sx={{ color: "#fff" }}
                            />
                          ) : (
                            "Verify" // Button text
                          )}
                        </button>
                      )}
                    </Col>
                  </Col>
                </Row>
                <div
                  className="text-end"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    variant={
                      isFinalLoading || !panVerified || !bankVerified
                        ? "contained"
                        : ""
                    }
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    style={{
                      textTransform: "capitalize",
                      height: "2.5rem",
                      marginBottom: "1rem",
                      width: "8rem",
                      height: "2.7rem",
                      backgroundColor:
                        isFinalLoading || !panVerified || !bankVerified
                          ? "gray"
                          : "#4a2a7d",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    disabled={isFinalLoading || !panVerified || !bankVerified}
                  >
                    {isFinalLoading ? (
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
                {/* <div className="text-end">
                  <Button
                    variant="contained"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    style={{
                      textTransform: "capitalize",
                      height: "2.5rem",
                      marginBottom: "1rem",
                      width: "8rem",
                      height: "2.7rem",
                      backgroundColor: "#4a2a7d",
                    }}
                  >
                    Update
                  </Button>
                </div> */}
              </Form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default KYCForm;
