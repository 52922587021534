import React from "react";
import Navbar1 from "../components/Navbar";
import Footer from "../landing_page/Footer";

export default function PrivacyPolicy() {
  return (
    <>
      <Navbar1 />
      <div
        style={{
          maxWidth: "1240px",
          margin: "auto",
          padding: "0.5rem",
        }}
      >
        <h5>Privacy Policy | Batuk</h5>
        <p>
          Hello! <br /> We at Batuk, Bharat Batuk Private Limited (referred to
          as “we,” “us,” “our,” or the “Company”), take your privacy seriously.
          We are committed to safeguarding your interests and aim to keep you
          informed at all times about how we collect and utilize your data.
        </p>
        <h5>Definition:</h5>
        <p>
          “Batuk” – means Bharat Batuk Private Limited, Batuk App, Batuk Web
          Portal, any duly authorised representative/officer By registering or
          logging into the relevant web portal or application, users
          automatically acknowledge and agree to the terms and conditions
          outlined in this document. It is emphasized that these terms and
          conditions are subject to periodic amendment or revision, as
          necessitated by changes in laws, statutory requirements, or company
          policies. By continuing to use the app or web portal, users are deemed
          to have acknowledged, accepted, and agreed to any such modifications
          or amendments made to the policy over time. “We,” “us,” “our” – refers
          to Bharat Batuk Private Limited/Batuk App/Batuk Web Portal “You,”
          “yours,” “Yourself,” “User”,”Customer” – refers to any
          individual/person, non-registered individual or corporate body,
          registered user of the app, including but not limited to users of
          services provided by Batuk.
        </p>
        <h5>What is this document?</h5>
        <p>
          This document constitutes our privacy policy. It outlines how we
          collect, process, store, and share your data when you access or use
          the Batuk Platform.
        </p>
        <h5>Why is it important?</h5>
        <p>
          This Privacy Policy provides transparency regarding your data. It
          elucidates what we can do with it and how. We pledge not to use or
          share any of your information except as described herein.
        </p>
        <h5>Is this part of the Terms?</h5>
        <p>
          Yes. This Privacy Policy is an integral component of the Terms to
          which you have agreed with us. The terminology used in this policy
          carries the same meanings as defined in the Terms of Use. You are
          expected to strictly abide by the said policy.
        </p>
        <h5>Can this Privacy Policy change?</h5>
        <p>
          Yes. We reserve the right to revise this policy, update the Batuk
          Platform, or modify our services at any time. Therefore, we encourage
          you to review this policy periodically, as it is legally binding on
          you. Your continuous usage of our platform shall be deemed to be
          consent/acceptance of the policy at your end.
        </p>
        <h5>THE INFORMATION WE COLLECT AND HOW WE USE IT</h5>
        <p>
          By accessing or using the Batuk Platform, you consent to providing us
          with your data and agree to our data collection and handling
          practices. The following delineates the information and documents we
          collect from you and the purposes for which we utilize them, we hereby
          shall endeavour that no breach of your data happens, and therefore we
          are taking due care, precautions and measures in form of safety
          firewalls, SSL and other necessary useful tools :
        </p>
        <p>What data do we collect:</p>
        <ul>
          <li>
            <strong>Registration and login data:</strong> Your name, mobile
            number, profile picture (optional), email, bank account details, KYC
            information, or any information requested on the Batuk Platform.
          </li>
          <li>
            <strong>Log Data:</strong> Information automatically collected when
            you use the Batuk Platform, whether through the use of cookies, web
            beacons, log files, scripts, or any other similar information.
          </li>
          <li>
            <strong> Surveys:</strong> Occasional surveys or questionnaires may
            be presented to you, some of which may require personally
            identifiable information. These surveys or questionnaires may also
            be conducted by third-party service providers.
          </li>
          <li>
            <strong> Device Data:</strong> Information collected from the
            devices you utilize to access or operate the Batuk Platform,
            including device attributes and identifiers, SMS, contact
            list(optional if referral program is enrolled for), or any other
            available information on such devices.
          </li>
          <li>
            <strong> Financial Information:</strong> This encompasses your bank
            account details, UPI ID details regarding your payment wallets, or
            other payment instruments.
          </li>
          <li>
            <strong> Customer Support Data:</strong> Any information provided to
            our customer support team or grievance redressal officer to
            investigate support issues, improve user support systems, or relay
            relevant queries to our partners.
          </li>
          <li>
            <strong> Location Data:</strong> Information derived from your
            device or provided address details, utilized for security, fraud
            detection, account management, location customization, and detection
            of prohibited usage states.
          </li>
          <li>
            <strong> KYC Data:</strong> Documents and information requested to
            facilitate KYC verification, such as PAN card, Aadhar card, bank
            account details, or any other information necessary for KYC
            verification as the rules and regulations of KYC may require from
            time to time.
          </li>
          <li>
            <strong> Transactional Data:</strong> Information related to your
            transactions on the Batuk Platform and our services, including
            subscribed financial products, subscription date and time, savings
            and investment amounts, interest rates, and any other pertinent
            information.
          </li>
        </ul>
        <p>Why we collect it</p>
        <ul>
          <li>
            To establish your account on the Batuk Platform and facilitate login
          </li>
          <li>
            To ensure compliance with all regulatory frameworks as per Indian
            law and our financial partners
          </li>
          <li>
            {" "}
            To notify you about new products, discounts, and promotional offers
          </li>
          <li>
            To facilitate communication with you and provide customer support
          </li>
          <li>
            {" "}
            To enforce our Terms, our rights, or the rights of our partners
          </li>
          <li>
            To facilitate smooth financial transactions of our investment
            products and services
          </li>
          <li>
            To develop new services, enhance existing services on the Batuk
            Platform, and integrate user preferences, feedback, and requests
          </li>
          <li>
            To administer the Batuk Platform and facilitate internal operations,
            including but not limited to troubleshooting, data analysis,
            testing, research, security, and fraud detectiont
          </li>
          <li>
            To better understand your usage of and access to the Batuk Platform
            and improve your user experience
          </li>
          <li>
            To assess the effectiveness of and improve advertising and other
            marketing and promotional activities
          </li>
          <li>
            {" "}
            To assess the effectiveness of and improve advertising and other
            marketing and promotional activities
          </li>
          <li>
            To customize and optimize your user experience on the Batuk Platform
          </li>
          <li>To enable you to pay and process funds to our partners</li>
          <li>
            {" "}
            To facilitate payment from our partners when you redeem or sell any
            financial product through the Batuk Platform
          </li>
          <li>To enable the Batuk Platform and facilitate our services</li>
          <li>To curate improved financial products</li>
          <li>
            {" "}
            To adhere to agreements with partners and comply with applicable
            laws
          </li>
          <li>
            {" "}
            To analyze your creditworthiness, risk appetite, and curate
            financial products
          </li>
          <li>
            To assist our partners in verifying your KYC with the use of
            third-party service providers
          </li>
        </ul>
        <h5>DISCLOSURE OF YOUR INFORMATION</h5>
        <p>
          <strong> Sharing with Service Providers:</strong> We collaborate with
          third-party service providers to execute various functions of the
          Batuk Platform, and may share your information with them to enable our
          services.
        </p>
        <p>
          <strong> Sharing with Partners:</strong> We share KYC data and
          financial information with our partners and their service providers to
          ensure KYC compliance, facilitate the delivery and servicing of
          financial products, and enable fund disbursement upon redemption.
        </p>
        <p>
          <strong>Sharing with B2B Partners:</strong> Information may be shared
          with businesses partnering with us to sponsor Rewards and advertise
          relevant products, aiding in Reward provision and personalized
          advertising.
        </p>
        <p>
          <strong> Sharing with Group Companies:</strong> Subject to applicable
          law, we may share collected data with our group companies for product
          research and development, service improvement, and product
          customization.
        </p>
        <p>
          <strong>Sharing with Law Enforcement:</strong> Information may be
          shared, without prior notice, upon request from governmental
          authorities or law enforcement officers, as necessary to comply with
          applicable law.
        </p>
        <h5>OUR DATA SECURITY PRACTICES</h5>
        <p>
          <strong>Security Measures:</strong> Appropriate technical and security
          measures are implemented to secure collected information, utilizing
          third-party service provider servers.
          <br />
          <br />
          <strong>User Responsibility:</strong> Users are responsible for
          keeping their Batuk Platform access credentials personal and
          confidential, refraining from sharing passwords or login details or
          OTP. The Company disclaims liability for any consequences arising from
          user account sharing.
          <br />
          <br />
          <strong>Disclaimer:</strong> While we endeavor to protect user data,
          transmission of information over the internet is not completely
          secure. The Company employs strict procedures and security features
          upon receiving user information to mitigate unauthorized access.
        </p>
        <h5>OUR DATA RETENTION PRACTICES</h5>
        <p>
          Information may be retained to facilitate seamless Batuk Platform use,
          service partner servicing, or compliance with applicable laws or
          partner agreements mandating data retention.
          <br />
          <br />
          <strong> Retention Duration:</strong> Data is retained only for
          necessary durations. Deletion requests for non-essential information
          are accommodated, though this may impact service use.
        </p>
        <h5>YOUR RIGHTS</h5>
        <p>
          Users have the freedom to delete content from their Batuk accounts at
          any time, subject to Terms of Use and this Privacy Policy.
          <br />
          <br />
          <strong>User Control:</strong> Information amendment, addition, or
          deletion is permissible through the Batuk account profile page. Opting
          out of marketing emails is feasible, while transactional emails
          persist until account deletion. <br />
          <br />
          <strong>Rights to Review and Amend:</strong> Users possess rights to
          review, correct, and amend collected information and profile details,
          but limited to the KYC rules, regulations and compliance <br />
          <br />
          <strong>Request Processing:</strong> Requests can be submitted via
          contact to support@batuk.in, with a reasonable period required for
          compliance.
        </p>
        <h5>THIRD-PARTY LINKS</h5>
        <p>
          The Batuk Platform may contain links to third-party websites and
          products. However, the Company holds no liability for content on such
          third-party websites or the users interaction with the third-party. If
          you follow a link to any of these websites, please note that these
          websites have their own privacy policies, terms of use and cookie
          policies that we do not accept any responsibility or liability for.
          Please check these documents before you submit any information to
          these websites, mobile applications or their own affiliates.
        </p>
        <h5>COMMUNICATIONS FROM US</h5>
        <p>
          We may from time to time send you service and product-related
          announcements when we consider it necessary to do so. We will send
          these to you via SMS or/and emails or pop-ups or in-app notification
          or whatsapp or message.
          <br />
          <br />
          You may not opt-out of these service-related announcements, which are
          not promotional in nature and used solely to protect your account and
          keep you informed of important changes to the Batuk Platform.
        </p>
        <h5>GRIEVANCE OFFICER</h5>
        <p>
          We have a Grievance Officer to address your concerns regarding data
          safety, privacy, and your usage of the Batuk Platform. We will resolve
          the issues raised by you within reasonable time from receiving them.
          You may contact our Grievance Officer using these coordinates:
          <br />
          <br />
          <strong>Name:</strong> Nitish Kumar <br />
          <br />
          <strong>Corporate Address:</strong> C62 First Floor, Nizamuddin(E) New
          Delhi-110013 <br />
          <br />
          <strong>Office hours:</strong> 10 am – 5pm (Monday to Friday)(Indian
          Standard Time) <br />
          <br />
          <strong>Email:</strong> escalate@batuk.in
        </p>
        <p>
          <h5>Lending License</h5>
          Bharat Batuk Private Limited is only a facilitator and an
          intermediary/fintech platform with the objective of connecting
          business to customers. The customers through the Bharat Batuk App
          directly interact with the lenders for availing the loan services as
          per the policy, rules and regulations as laid upon by the lenders in
          this case Abhi Loans and Finzy. <br />
          <br />
          The loan services through the Bharat Batuk app are provided by Abhi
          Loans and Finzy as they are regulated and governed by the license
          issued by the Reserve Bank of India. Find attached herewith the link
          to privacy policy and the lending license of the two entities namely
          Abhi Loans and Finzy.
        </p>
      </div>
      <Footer />
    </>
  );
}
