import React from "react";
import "../../assets/css/about.css";
// import goldImage from "../../assets/landing-page/gold-Img.png";
import Footer from "../../landing_page/Footer";
import Navbar1 from "../../components/Navbar";
import IndianMap from "../../assets/landing-page/india_map_gold.png";
import business from "../../assets/landing-page/business.png";
import { GrTechnology } from "react-icons/gr";
import { FaRegHandshake } from "react-icons/fa";

import { IoPersonOutline } from "react-icons/io5";
import { GoPeople } from "react-icons/go";
import { PiLeafLight } from "react-icons/pi";
import { MdOutlineMenuBook } from "react-icons/md";
import Testimonials from "../../landing_page/Testimonials";
// import Slider from "react-slick";

const About = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <>
      <Navbar1 />
      <section className="about" id="about">
        <div className="about-section">
          <div className="row align-items-center mb-5">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="control-container">
                <div>
                  <figure className="img-container">
                    <img
                      src={business}
                      alt="business"
                      style={{ height: "100%", width: "100%" }}
                    />
                  </figure>
                </div>
                {/* <Slider {...settings}>
                  <div>
                    <figure className="img-container">
                      <img
                        src={business}
                        alt="business"
                        style={{ height: "100%", width: "100%" }}
                      />
                      <figcaption className="img-caption">Business</figcaption>
                    </figure>
                  </div>
                  <div>
                    <figure className="img-container">
                      <img
                        src={business}
                        alt="business"
                        style={{ height: "100%", width: "100%" }}
                      />
                      <figcaption className="img-caption">
                        Kingfisher
                      </figcaption>
                    </figure>
                  </div>
                  <div>
                    <figure className="img-container">
                      <img
                        src={business}
                        alt="business"
                        style={{ height: "100%", width: "100%" }}
                      />
                      <figcaption className="img-caption">
                        Keel-billed-toucan
                      </figcaption>
                    </figure>
                  </div>
                  <div>
                    <figure className="img-container">
                      <img
                        src={business}
                        alt="business"
                        style={{ height: "100%", width: "100%" }}
                      />
                      <figcaption className="img-caption">
                        Yellow-warbler
                      </figcaption>
                    </figure>
                  </div>
                  <div>
                    <figure className="img-container">
                      <img
                        src={business}
                        alt="business"
                        style={{ height: "100%", width: "100%" }}
                      />
                      <figcaption className="img-caption">Bee-eater</figcaption>
                    </figure>
                  </div>
                </Slider> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12 mb-4 mb-lg-0">
              <h3 className="sub-heading">Who We Are?</h3>
              <p className="about-description">
                Batuk is your trusted platform for purchasing Gold and Silver,
                offering convenience and accessibility like never before.
                Starting with as little as Rs. 10, our platform allows retail
                buyers to save in Gold, with every purchase securely stored in
                vaults monitored by Independent trustees, ensuring the highest
                level of security and peace of mind.
              </p>
              <p>
                In a nation where Gold is a symbol of prosperity, security, and
                celebration, Batuk caters to everyone from middle class families
                to daily wage earners and the affluent. We understand the deep
                cultural significance of Gold in India, and our mission is to
                make it easier for you to embrace this timeless tradition and
                build your wealth with confidence.
              </p>
            </div>
          </div>

          {/* Principles Section */}
          <div className="principles-section">
            <h2 className="about-title">Principles That Define Us</h2>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="principle-card">
                  <GrTechnology style={{ height: "50px", width: "50px" }} />

                  <h3>Customer-Focused Approach</h3>
                  <p>
                    At Batuk, our customers are our top priority. We are
                    dedicated to understanding and meeting their needs to
                    deliver a smooth and satisfying experience.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="principle-card">
                  <FaRegHandshake style={{ height: "50px", width: "50px" }} />
                  <h3>Innovation & Technology</h3>
                  <p>
                    We are transforming the gold industry by making transactions
                    seamless, secure, and accessible to everyone.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="principle-card">
                  <IoPersonOutline style={{ height: "50px", width: "50px" }} />
                  <h3>Transparency & Trust</h3>
                  <p>
                    We uphold high standards of trust in every interaction,
                    offering clear and honest information to build lasting
                    relationships founded on reliability and accountability.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="principle-card">
                  <GoPeople style={{ height: "50px", width: "50px" }} />
                  <h3>Accessibility and Inclusivity</h3>
                  <p>
                    Batuk is committed to making Digital gold and silver
                    accessible to everyone with services like Gold on EMI and
                    F.D.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="principle-card">
                  <PiLeafLight style={{ height: "50px", width: "50px" }} />
                  <h3>Sustainability and Responsibility</h3>
                  <p>
                    Batuk is dedicated to sustainable practices, ethical gold
                    sourcing, and supporting community initiatives, all while
                    striving for a positive impact.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="principle-card">
                  <MdOutlineMenuBook
                    style={{ height: "50px", width: "50px" }}
                  />
                  <h3>Learning and Improvement</h3>
                  <p>
                    Driving constant growth and innovation, we stay ahead in the
                    gold industry by embracing fresh ideas and continuously
                    evolving.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>{/* <Testimonials /> */}</div>
          <div className="principles-section">
            <h2
              className="about-title"
              style={{
                display: "flex",
                justifyContent: "start",
                marginLeft: "60px",
              }}
            >
              Map
            </h2>
            <div
              className="row"
              style={{
                margin: "0 3rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={IndianMap}
                alt=""
                srcset=""
                style={{ width: "55rem" }}
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default About;

/////////////////SLIDER///////////////////////////

//  <Slider {...settings}>
//    <div>
//      <figure className="img-container">
//        <img
//          src={business}
//          alt="business"
//          style={{ height: "100%", width: "100%" }}
//        />
//        <figcaption className="img-caption">Business</figcaption>
//      </figure>
//    </div>
//    <div>
//      <figure className="img-container">
//        <img
//          src={business}
//          alt="business"
//          style={{ height: "100%", width: "100%" }}
//        />
//        <figcaption className="img-caption">
//                         Kingfisher
//                       </figcaption>
//      </figure>
//    </div>
//    <div>
//      <figure className="img-container">
//        <img
//          src={business}
//          alt="business"
//          style={{ height: "100%", width: "100%" }}
//        />
//        <figcaption className="img-caption">
//                         Keel-billed-toucan
//                       </figcaption>
//      </figure>
//    </div>
//    <div>
//      <figure className="img-container">
//        <img
//          src={business}
//          alt="business"
//          style={{ height: "100%", width: "100%" }}
//        />
//        <figcaption className="img-caption">
//                         Yellow-warbler
//                       </figcaption>
//      </figure>
//    </div>
//    <div>
//      <figure className="img-container">
//        <img
//          src={business}
//          alt="business"
//          style={{ height: "100%", width: "100%" }}
//        />
//        <figcaption className="img-caption">Bee-eater</figcaption>
//      </figure>
//    </div>
//  </Slider>;

/////////////////////////////////////////

// import React from "react";
// import { FaArrowRightLong } from "react-icons/fa6";
// import { CiUser } from "react-icons/ci";
// import NavBar1 from "../../components/Navbar";
// import Footer from "../../landing_page/Footer";
// import goldImg from "../../assets/landing-page/gold-Img.png";

// const About = () => {
//   return (
//     <>
//       <NavBar1 />
//       <div>
//         <div>
//           <div>
//             <h1>Who We Are?</h1>
//             <p>
//               India's leading gold platform, delivering a wide range of services
//               with a reputation for quality, commitment, and outstanding
//               performance.
//             </p>
//           </div>
//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: "1fr 1fr",
//               gap: "3rem",
//             }}
//           >
//             <div>
//               <div>
//                 <h4>State-of-the-art Gold and Silver Refinery</h4>
//                 <p>
//                   Augmont stands as India’s premier gold and silver refinery,
//                   offering the SPOT platform for online trading of precious
//                   metals.
//                 </p>
//               </div>
//               <div>
//                 <h4>Own Gold with Our Gold for All Platform</h4>
//                 <p>
//                   Our Gold For All platform integrates the entire lifecycle of
//                   gold and tends to be a one-stop solution for retail customers.
//                   It facilitates gold deposits, financing against gold, and
//                   purchasing gold and silver on an EMI basis.
//                 </p>
//               </div>
//             </div>
//             <div>
//               <img src={goldImg} alt="goldcoin" width={200} />
//             </div>
//           </div>
//         </div>

//         <div
//           style={{
//             backgroundColor: "#DCDAFF",
//             padding: "50px 70px",
//             display: "flex",
//             //   gridTemplateColumns: "2fr 1fr",
//             gap: "40px",
//             marginBottom: "50px",
//             justifyContent: "space-between",
//           }}
//         >
//           <div style={{ width: "70%" }}>
//             <div style={{ fontSize: "3.5rem", fontweight: "bold" }}>
//               Be a part of our Gold Revolution!
//             </div>
//             <p style={{ fontSize: "1.5rem", fontweight: "bold" }}>
//               We would like to partner with businesses and individuals who can
//               share our mission of making pure gold accessible to everyone.
//             </p>
//             <button
//               style={{
//                 border: "none",
//                 borderRadius: "10px",
//                 backgroundColor: "#442673",
//                 padding: "12px",
//                 color: "white",
//                 fontSize: "20px",
//                 transition:
//                   "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
//               }}
//               onMouseEnter={(e) => {
//                 e.currentTarget.style.transform = "translateY(-10px)";
//                 e.currentTarget.style.boxShadow =
//                   "0 10px 20px rgba(0, 0, 0, 0.3)";
//               }}
//               onMouseLeave={(e) => {
//                 e.currentTarget.style.transform = "translateY(0)";
//                 e.currentTarget.style.boxShadow = "none";
//               }}
//             >
//               Connect Now <FaArrowRightLong />
//             </button>
//           </div>
//           <img src="./goldcoin.png" alt="gold" width={200} />
//         </div>

//         <div>
//           <h1>Principles That Define Us</h1>
//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: "1fr 1fr 1fr",
//               gap: "2rem",
//             }}
//           >
//             {Array.from({ length: 6 }).map((_, index) => (
//               <div
//                 key={index}
//                 style={{
//                   padding: "20px",
//                   boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
//                   borderRadius: "10px",
//                 }}
//               >
//                 <CiUser size={40} />
//                 <h5>Customer-Centric Approach</h5>
//                 <p>
//                   At Augmont, our customers are at the heart of everything we
//                   do. We prioritize understanding and meeting their needs to
//                   ensure a seamless and satisfying experience.
//                 </p>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div>
//           <h1>We Are Accredited with</h1>
//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: "1fr 1fr 1fr",
//               gap: "2rem",
//             }}
//           >
//             {Array.from({ length: 6 }).map((_, index) => (
//               <div
//                 key={index}
//                 style={{
//                   padding: "20px",
//                   boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
//                   borderRadius: "10px",
//                 }}
//               >
//                 <CiUser size={40} />
//                 <h5>Customer-Centric Approach</h5>
//                 <p>
//                   At Augmont, our customers are at the heart of everything we
//                   do. We prioritize understanding and meeting their needs to
//                   ensure a seamless and satisfying experience.
//                 </p>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div>
//           <h1>Map</h1>
//           <div
//             style={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <img src="./map.png" alt="map" width="50%" />
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default About;
