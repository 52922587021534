import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
// import 'rsuite/DateRangePicker/styles/index.css';
import "./comman.scss";
import Overview from "./pages/Overview";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
// import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import Client from "./pages/Client";
import Employee from "./pages/Employee";
import Purposal from "./pages/Purposal";
import CreateNewPurposal from "./pages/createNewPurposal";
import FinalCreate from "./pages/FinalCreate";
import AboutBatukPartener from "./pages/AboutBatukPartener";
import PersonalDetails from "./pages/PersonalDetails";
import ResetPassword from "./pages/ResetPassword";
import "sweetalert2/src/sweetalert2.scss";
import Header from "./components/Header";
import Earning from "./pages/Earnings";
import Report from "./pages/Report";
import DashBoard from "./pages/DashBoard";
import KycForm from "./pages/KYCForm";
import About from "./pages/PublicRoutes/About";
import Blogs from "./pages/PublicRoutes/blogs/Blogs";
import Faq from "./pages/PublicRoutes/Faq";
import Contact from "./pages/PublicRoutes/Contact";
import IndiRegister from "./pages/IndiRegister";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import UploadFile from "./pages/UploadFile";
// import Spotgold from "./pages/Spotgold";
import ProductDetails from "./pages/ProductDetails";
import AddressDetails from "./pages/AddressDetails";
import SpotGold from "./pages/spotGold/SpotGold";
import CreateNewOrder from "./pages/spotGold/CreateNewOrder";
import Product from "./pages/spotGold/Product";
import Sip from "./pages/Sip/Sip";
import CreateNewSIP from "./pages/Sip/CreateNewSIP";
import FinalCreateSip from "./pages/Sip/FinalCreateSip";
import CompleteKYC from "./pages/CompleteKYC";
import StartFd from "./pages/fixed_deposit/StartFd";
import GoldFd from "./pages/fixed_deposit/GoldFd";
import CreateGoldFd from "./pages/fixed_deposit/CreateGoldFd";
import GoldFdDetails from "./pages/fixed_deposit/GoldFdDetails";
import Home from "./pages/home/Home";
import ViewBlog from "./pages/PublicRoutes/blogs/ViewBlog";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Sell from "./pages/sell/Sell";
import CreateNewSell from "./pages/sell/CreateNewSell";
import FinalCreateSell from "./pages/sell/FinalCreateSell";

function App() {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const publicRoutes = [
      "/login",
      "/register",
      "/indRegister",
      "/about",
      "/blog",
      "/faq",
      "/contact",
      "/forgotpassword",
      "/",
      "/view-blog",
      "/terms-conditions",
      "/privacy-policy",
    ];

    const currentPath = location.pathname;
    const isPublicRoute = publicRoutes.some((route) =>
      currentPath.startsWith(route)
    );

    if (!token && !isPublicRoute) {
      navigate("/");
    }
  }, [token, location, navigate]);

  return (
    <>
      <Routes>
        {/* Map "/" to the Home component */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Header />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog/:title_url" element={<ViewBlog />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/terms-conditions" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/IndRegister" element={<IndiRegister />} />

        {/* Map "/dashboard" to the DashBoard component */}
        <Route path="/dashboard" element={<DashBoard />}>
          <Route index element={<Overview />} />
          <Route path="/dashboard/client_table" element={<Client />} />
          <Route
            path="/dashboard/complete-kyc/:clientId/:productId"
            element={<CompleteKYC />}
          />
          <Route
            path="/dashboard/complete-kyc/:clientId"
            element={<CompleteKYC />}
          />
          <Route path="/dashboard/client_kyc" element={<KycForm />} />
          <Route path="/dashboard/employee_table" element={<Employee />} />
          <Route path="/dashboard/sip" element={<Sip />} />
          <Route path="/dashboard/gold-fd" element={<GoldFd />} />
          <Route path="/dashboard/start-gold-fd" element={<StartFd />} />
          <Route
            path="/dashboard/create-gold-fd/:clientId"
            element={<CreateGoldFd />}
          />
          <Route
            path="/dashboard/create-gold-fd/:clientId/:id"
            element={<GoldFdDetails />}
          />
          <Route path="/dashboard/proposal" element={<Purposal />} />
          <Route
            path="/dashboard/create_proposal"
            element={<CreateNewPurposal />}
          />
          <Route path="/dashboard/create_order" element={<CreateNewOrder />} />
          <Route path="/dashboard/create-sip" element={<CreateNewSIP />} />
          <Route path="/dashboard/final_create" element={<FinalCreate />} />
          <Route path="/dashboard/sell" element={<Sell />} />
          <Route path="/dashboard/create_sell" element={<CreateNewSell />} />
          <Route
            path="/dashboard/final_create_sell"
            element={<FinalCreateSell />}
          />
          <Route
            path="/dashboard/final-create-sip/:clientId"
            element={<FinalCreateSip />}
          />
          <Route path="/dashboard/profile" element={<PersonalDetails />} />
          <Route
            path="/dashboard/aboutbatukpartener"
            element={<AboutBatukPartener />}
          />
          <Route
            path="/dashboard/personaldetails"
            element={<PersonalDetails />}
          />
          <Route path="/dashboard/earning" element={<Earning />} />
          <Route path="/dashboard/reports" element={<Report />} />
          <Route path="/dashboard/upload-file" element={<UploadFile />} />
          <Route path="/dashboard/spot-gold" element={<SpotGold />} />
          <Route path="/dashboard/product/:clientId" element={<Product />} />
          <Route
            path="/dashboard/product/:clientId/:id"
            element={<ProductDetails />}
          />
          <Route
            path="/dashboard/product/:clientId/:id/address-details"
            element={<AddressDetails />}
          />
          <Route path="/dashboard/reset-password" element={<ResetPassword />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;

// import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle";
// // import 'rsuite/DateRangePicker/styles/index.css';
// import "./comman.scss";
// import Overview from "./pages/Overview";
// import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
// // import { BrowserRouter } from "react-router-dom";
// import { useEffect } from "react";
// import Client from "./pages/Client";
// import Employee from "./pages/Employee";
// import Purposal from "./pages/Purposal";
// import CreateNewPurposal from "./pages/createNewPurposal";
// import FinalCreate from "./pages/FinalCreate";
// import AboutBatukPartener from "./pages/AboutBatukPartener";
// import PersonalDetails from "./pages/PersonalDetails";
// import ResetPassword from "./pages/ResetPassword";
// import "sweetalert2/src/sweetalert2.scss";
// import Header from "./components/Header";
// import Earning from "./pages/Earnings";
// import Report from "./pages/Report";
// import DashBoard from "./pages/DashBoard";
// import KycForm from "./pages/KYCForm";
// import About from "./pages/PublicRoutes/About";
// import Blogs from "./pages/PublicRoutes/blogs/Blogs";
// import Faq from "./pages/PublicRoutes/Faq";
// import Contact from "./pages/PublicRoutes/Contact";
// import IndiRegister from "./pages/IndiRegister";
// import Register from "./pages/Register";
// import ForgotPassword from "./pages/ForgotPassword";
// import UploadFile from "./pages/UploadFile";
// // import Spotgold from "./pages/Spotgold";
// import ProductDetails from "./pages/ProductDetails";
// import AddressDetails from "./pages/AddressDetails";
// import SpotGold from "./pages/spotGold/SpotGold";
// import CreateNewOrder from "./pages/spotGold/CreateNewOrder";
// import Product from "./pages/spotGold/Product";
// import Sip from "./pages/Sip/Sip";
// import CreateNewSIP from "./pages/Sip/CreateNewSIP";
// import FinalCreateSip from "./pages/Sip/FinalCreateSip";
// import CompleteKYC from "./pages/CompleteKYC";
// import StartFd from "./pages/fixed_deposit/StartFd";
// import GoldFd from "./pages/fixed_deposit/GoldFd";
// import CreateGoldFd from "./pages/fixed_deposit/CreateGoldFd";
// import GoldFdDetails from "./pages/fixed_deposit/GoldFdDetails";
// import Home from "./pages/home/Home";
// import ViewBlog from "./pages/PublicRoutes/blogs/ViewBlog";
// import Terms from "./pages/Terms";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
// import Sell from "./pages/sell/Sell";
// import CreateNewSell from "./pages/sell/CreateNewSell";
// import FinalCreateSell from "./pages/sell/FinalCreateSell";

// function App() {
//   const token = sessionStorage.getItem("token");
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     const publicRoutes = [
//       "/login",
//       "/register",
//       "/indRegister",
//       "/about",
//       "/blog",
//       "/faq",
//       "/contact",
//       "/forgotpassword",
//       "/home",
//       "/view-blog",
//       "/terms-conditions",
//       "/privacy-policy",
//     ];

//     const currentPath = location.pathname;
//     const isPublicRoute = publicRoutes.some((route) =>
//       currentPath.startsWith(route)
//     );

//     if (!token && !isPublicRoute) {
//       navigate("/home");
//       // navigate("/login");
//     }
//   }, [token, location, navigate]);

//   return (
//     <>
//       <Routes>
//         <Route path="/home" element={<Home />} />
//         <Route path="/login" element={<Header />} />
//         <Route path="/about" element={<About />} />
//         <Route path="/blog" element={<Blogs />} />
//         <Route path="/blog/:title_url" element={<ViewBlog />} />
//         <Route path="/faq" element={<Faq />} />
//         <Route path="/terms-conditions" element={<Terms />} />
//         <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//         <Route path="/contact" element={<Contact />} />
//         <Route path="/register" element={<Register />} />
//         <Route path="/forgotpassword" element={<ForgotPassword />} />
//         <Route path="/IndRegister" element={<IndiRegister />} />
//         <Route path="/" element={<DashBoard />}>
//           <Route index element={<Overview />} />
//           <Route path="/client_table" element={<Client />} />
//           <Route
//             path="/complete-kyc/:clientId/:productId"
//             element={<CompleteKYC />}
//           />
//           <Route path="/complete-kyc/:clientId" element={<CompleteKYC />} />
//           <Route path="/client_kyc" element={<KycForm />} />
//           <Route path="employee_table" element={<Employee />} />
//           <Route path="/sip" element={<Sip />} />
//           <Route path="/gold-fd" element={<GoldFd />} />
//           <Route path="/start-gold-fd" element={<StartFd />} />
//           <Route path="/create-gold-fd/:clientId" element={<CreateGoldFd />} />
//           <Route
//             path="/create-gold-fd/:clientId/:id"
//             element={<GoldFdDetails />}
//           />

//           <Route path="/proposal" element={<Purposal />} />
//           <Route path="/create_proposal" element={<CreateNewPurposal />} />
//           <Route path="/create_order" element={<CreateNewOrder />} />
//           <Route path="/create-sip" element={<CreateNewSIP />} />
//           <Route path="/final_create" element={<FinalCreate />} />
//           <Route path="/sell" element={<Sell />} />
//           <Route path="/create_sell" element={<CreateNewSell />} />
//           <Route path="/final_create_sell" element={<FinalCreateSell />} />
//           <Route
//             path="/final-create-sip/:clientId"
//             element={<FinalCreateSip />}
//           />
//           <Route path="/profile" element={<PersonalDetails />} />
//           <Route path="aboutbatukpartener" element={<AboutBatukPartener />} />
//           <Route path="/personaldetails" element={<PersonalDetails />} />
//           <Route path="/earning" element={<Earning />} />
//           <Route path="/reports" element={<Report />} />
//           <Route path="/upload-file" element={<UploadFile />} />
//           <Route path="/spot-gold" element={<SpotGold />} />

//           <Route path="/product/:clientId" element={<Product />} />

//           <Route path="/product/:clientId/:id" element={<ProductDetails />} />
//           <Route
//             path="/product/:clientId/:id/address-details"
//             element={<AddressDetails />}
//           />
//           <Route path="/reset-password" element={<ResetPassword />} />
//         </Route>
//       </Routes>
//     </>
//   );
// }

// export default App;

// import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle";
// // import 'rsuite/DateRangePicker/styles/index.css';
// import "./comman.scss";
// import Overview from "./pages/Overview";
// import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
// // import { BrowserRouter } from "react-router-dom";
// import { useEffect } from "react";
// import Client from "./pages/Client";
// import Employee from "./pages/Employee";
// import Purposal from "./pages/Purposal";
// import CreateNewPurposal from "./pages/createNewPurposal";
// import FinalCreate from "./pages/FinalCreate";
// import AboutBatukPartener from "./pages/AboutBatukPartener";
// import PersonalDetails from "./pages/PersonalDetails";
// import ResetPassword from "./pages/ResetPassword";
// import "sweetalert2/src/sweetalert2.scss";
// import Header from "./components/Header";
// import Earning from "./pages/Earnings";
// import Report from "./pages/Report";
// import DashBoard from "./pages/DashBoard";
// import KycForm from "./pages/KYCForm";
// import About from "./pages/PublicRoutes/About";
// import Blogs from "./pages/PublicRoutes/Blogs";
// import Faq from "./pages/PublicRoutes/Faq";
// import Insights from "./pages/PublicRoutes/Insights";
// import Contact from "./pages/PublicRoutes/Contact";
// import IndiRegister from "./pages/IndiRegister";
// import Register from "./pages/Register";
// import ForgotPassword from "./pages/ForgotPassword";
// import UploadFile from "./pages/UploadFile";
// // import Spotgold from "./pages/Spotgold";
// import ProductDetails from "./pages/ProductDetails";
// import AddressDetails from "./pages/AddressDetails";
// import SpotGold from "./pages/spotGold/SpotGold";
// import CreateNewOrder from "./pages/spotGold/CreateNewOrder";
// import Product from "./pages/spotGold/Product";
// import Sip from "./pages/Sip/Sip";
// import CreateNewSIP from "./pages/Sip/CreateNewSIP";
// import FinalCreateSip from "./pages/Sip/FinalCreateSip";
// import CompleteKYC from "./pages/CompleteKYC";
// import StartFd from "./pages/fixed_deposit/StartFd";
// import GoldFd from "./pages/fixed_deposit/GoldFd";
// import CreateGoldFd from "./pages/fixed_deposit/CreateGoldFd";
// import GoldFdDetails from "./pages/fixed_deposit/GoldFdDetails";

// function App() {
//   const token = sessionStorage.getItem("token");
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     const publicRoutes = [
//       "/login",
//       "/register",
//       "/indRegister",
//       "/about",
//       "/blogs",
//       "/faq",
//       "/insights",
//       "/contact",
//       "/forgotpassword",
//     ];
//     const currentPath = location.pathname;

//     if (!token && !publicRoutes.includes(currentPath)) {
//       navigate("/login");
//     }
//   }, [token, location, navigate]);

//   return (
//     <>
//       <Routes>
//         <Route path="/login" element={<Header />} />
//         <Route path="/about" element={<About />} />
//         <Route path="/blogs" element={<Blogs />} />
//         <Route path="/faq" element={<Faq />} />
//         <Route path="/insights" element={<Insights />} />
//         <Route path="/contact" element={<Contact />} />
//         <Route path="/register" element={<Register />} />
//         <Route path="/forgotpassword" element={<ForgotPassword />} />
//         <Route path="/IndRegister" element={<IndiRegister />} />
//         <Route path="/" element={<DashBoard />}>
//           <Route index element={<Overview />} />
//           <Route path="client_table" element={<Client />} />
//           <Route
//             path="/complete-kyc/:clientId/:productId"
//             element={<CompleteKYC />}
//           />
//           <Route path="/complete-kyc/:clientId" element={<CompleteKYC />} />
//           <Route path="/client_kyc" element={<KycForm />} />
//           <Route path="employee_table" element={<Employee />} />
//           <Route path="/sip" element={<Sip />} />
//           <Route path="/gold-fd" element={<GoldFd />} />
//           <Route path="/start-gold-fd" element={<StartFd />} />
//           <Route path="/create-gold-fd/:clientId" element={<CreateGoldFd />} />
//           <Route
//             path="/create-gold-fd/:clientId/:id"
//             element={<GoldFdDetails />}
//           />

//           <Route path="/proposal" element={<Purposal />} />
//           <Route path="/create_proposal" element={<CreateNewPurposal />} />
//           <Route path="/create_order" element={<CreateNewOrder />} />
//           <Route path="/create-sip" element={<CreateNewSIP />} />
//           <Route path="/final_create" element={<FinalCreate />} />
//           <Route
//             path="/final-create-sip/:clientId"
//             element={<FinalCreateSip />}
//           />
//           <Route path="/profile" element={<PersonalDetails />} />
//           <Route path="aboutbatukpartener" element={<AboutBatukPartener />} />
//           <Route path="/personaldetails" element={<PersonalDetails />} />
//           <Route path="/earning" element={<Earning />} />
//           <Route path="/reports" element={<Report />} />
//           <Route path="/upload-file" element={<UploadFile />} />
//           <Route path="/spot-gold" element={<SpotGold />} />

//           <Route path="/product/:clientId" element={<Product />} />

//           <Route path="/product/:clientId/:id" element={<ProductDetails />} />
//           <Route
//             path="/product/:clientId/:id/address-details"
//             element={<AddressDetails />}
//           />
//           <Route path="/reset-password" element={<ResetPassword />} />
//         </Route>
//       </Routes>
//     </>
//   );
// }

// export default App;
