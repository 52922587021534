import React, { useEffect, useState } from "react";
import "../../assets/css/product.css";
import { Button } from "react-bootstrap";
import { baseUrl } from "../../utils/baseUrl";
import axios from "axios";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Link, useParams } from "react-router-dom";
import noImage from "../../assets/images/no_image.png";
import ProductCard from "../../utils/ProductCard";
const Product = () => {
  const token = sessionStorage.getItem("token");
  const { clientId } = useParams();
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useState("");
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(" ") + "...";
  };

  const getProduct = async () => {
    try {
      const url = `${baseUrl}/emi/partner_portal/productSubCategory`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const productData = response.data.data.data;
      setProduct(productData);
      setLoading(false);
    } catch (error) {
      console.error("Error connecting to API", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const handlePriceRangeChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const applyFilters = () => {
    setIsDropdownVisible(false);
  };

  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90%",
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="filter-dropdown">
                <div style={{ marginBottom: "1rem" }}>
                  <label>
                    Sort by price:
                    <select value={sortOption} onChange={handleSortChange}>
                      <option value="">None</option>
                      <option value="lowToHigh">Low to High</option>
                      <option value="highToLow">High to Low</option>
                    </select>
                  </label>
                </div>
                <div>
                  <Box sx={{ width: 300 }}>
                    <label>Price Range</label>
                    <Slider
                      getAriaLabel={() => "Price range"}
                      value={priceRange}
                      onChange={handlePriceRangeChange}
                      valueLabelDisplay="auto"
                      min={0}
                      max={100000}
                      aria-labelledby="range-slider"
                      getAriaValueText={(value) => `${value}`}
                    />
                  </Box>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={applyFilters}
              >
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <h4 style={{ color: "#4a2a7d" }}>Products</h4>
        <button
          type="button"
          className="btn"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          style={{ backgroundColor: "#4a2a7d", width: "8rem", color: "white" }}
        >
          Filter
        </button>
      </div>
      <hr />
      <div className="grid">
        {loading ? (
          Array.from({ length: 10 }).map((_, i) => <ProductCard key={i} />)
        ) : product.length > 0 ? (
          product
            .filter((item) => {
              const price = item.productPrice[0].finalProductPrice;
              return price >= priceRange[0] && price <= priceRange[1];
            })
            .sort((a, b) => {
              const priceA = a.productPrice[0].finalProductPrice;
              const priceB = b.productPrice[0].finalProductPrice;
              if (sortOption === "lowToHigh") {
                return priceA - priceB;
              } else if (sortOption === "highToLow") {
                return priceB - priceA;
              } else {
                return 0;
              }
            })
            .map((item, i) => (
              <div className="spot-card" key={i}>
                <figure
                  className="card-image"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "212px",
                    height: "128px",
                  }}
                >
                  <img
                    src={
                      item.productImage === "0" ? noImage : item.productImage
                    }
                    alt={item.productName}
                    style={item.productImage === "0" ? {} : { width: "8rem" }}
                  />
                </figure>

                <div className="card-header">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <h6
                      style={{
                        width: "200px",
                        height: "38px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {truncateText(item.productName, 7)}
                    </h6>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h6
                      style={{
                        whiteSpace: "nowrap",
                        width: "160px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      ₹ {item.productPrice[0].finalProductPrice}
                    </h6>
                  </div>
                </div>
                <Link
                  to={{
                    pathname: `/dashboard/product/${clientId}/${item.id}`,
                  }}
                  className="spot-btn"
                >
                  <Button
                    style={{
                      width: "100%",
                      backgroundColor: "#4a2a7d",
                      color: "white",
                      border: "none",
                    }}
                  >
                    Buy Now
                  </Button>
                </Link>
              </div>
            ))
        ) : (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            No products found.
          </p>
        )}
      </div>
    </>
  );
};

export default Product;
