import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";

const FormPart1 = ({ formData, handleChange, nextStep }) => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else if (
      !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(formData.Entityemail)
    ) {
      e.stopPropagation();
      alert("Please provide a valid email address.");
    } else if (formData.password !== formData.confirmPassword) {
      e.stopPropagation();
      alert("Passwords do not match.");
    } else {
      nextStep();
    }
    setValidated(true);
  };

  return (
    <div className="registration-form">
      <h2 className="mb-4 center-text">Register as Entity Partner</h2>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="row-bottom">
          <Col md={6}>
            <Form.Group controlId="formName">
              <Form.Label>Entity Type</Form.Label>
              <Form.Control
                as="select"
                name="EntityType"
                value={formData.EntityType}
                onChange={handleChange}
                required
              >
                <option>Select</option>
                <option value="Individual Company">Individual Company</option>
                <option value="Sole Proprietorship">Sole Proprietorship</option>
                <option value="Partnership">Partnership</option>
                <option value="Private Limited">Private Limited</option>
                <option value="Public Limited">Public Limited</option>
                <option value="LLP">LLP (Limited Liability Partnership)</option>
                <option value="Trust">Trust</option>
                <option value="Society">Society</option>
                <option value="Government">Government</option>
                <option value="Hindu Undivided Famliy">
                  Hindu Undivided Famliy
                </option>
                <option value="Local Authority">Local Authority</option>
                <option value="NGO">NGO</option>
                <option value="One Person Comppany">One Person Comppany</option>
                <option value="Artificial Juridical Person">
                  Artificial Juridical Person
                </option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select the entity type.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="companyEntityName">
              <Form.Label>Company / Entity Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Company / Entity Name"
                name="Entityname"
                value={formData.Entityname}
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide the company or entity name.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="row-bottom">
          <Col md={6}>
            <Form.Group controlId="formDob">
              <Form.Label>Company Type Incorporation No.</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Incorporation No."
                name="EntityidentificationNo"
                value={formData.EntityidentificationNo}
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide the company type incorporation number.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                name="Entityemail"
                value={formData.Entityemail}
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email address.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="row-bottom">
          <Col md={6}>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                minLength="6"
              />
              <Form.Control.Feedback type="invalid">
                Password must be at least 6 characters long.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formConfirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
                minLength="6"
              />
              <Form.Control.Feedback type="invalid">
                {formData.password !== formData.confirmPassword
                  ? "Passwords do not match."
                  : "Please confirm your password."}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-4 text-center" style={{ marginTop: "3rem" }}>
          <p style={{ marginBottom: "3rem" }}>
            Already have an account? <a href="/login">Login</a>
          </p>
          <Button
            className="w-50"
            style={{ backgroundColor: "#4a2a7d", borderRadius: "30px" }}
            type="submit"
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormPart1;

// import React from "react";
// import { Form, Button, Row, Col } from "react-bootstrap";

// const FormPart1 = ({ formData, handleChange, nextStep }) => {
//   return (
//     <div className="registration-form">
//       <h2 className="mb-4 center-text">Register as Entity Partner</h2>
//       <Form>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="formName">
//               <Form.Label>Entity Type</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Full Name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="companyEntityName">
//               <Form.Label>Company / Entity Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Company / Entity Name"
//                 name="companyOrEntity"
//                 value={formData.companyOrEntity}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="formDob">
//               <Form.Label>Company Type Incorporation No.</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Company Type Incorporation No."
//                 name="incorporationNo"
//                 value={formData.incorporationNo}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formEmail">
//               <Form.Label>Email</Form.Label>
//               <Form.Control
//                 type="email"
//                 placeholder="Enter Email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="formPassword">
//               <Form.Label>Password</Form.Label>
//               <Form.Control
//                 type="password"
//                 placeholder="Enter password"
//                 name="password"
//                 value={formData.password}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formConfirmPassword">
//               <Form.Label>Confirm Password</Form.Label>
//               <Form.Control
//                 type="password"
//                 placeholder="Confirm password"
//                 name="confirmPassword"
//                 value={formData.confirmPassword}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <div className="mt-4 text-center">
//           <p>
//             Already have an account? <a href="/login">Login</a>
//           </p>
//           <Button
//             className="w-100"
//             style={{ backgroundColor: "#4a2a7d", borderRadius: "30px" }}
//             onClick={nextStep}
//           >
//             Next
//           </Button>
//         </div>
//       </Form>
//     </div>
//   );
// };

// export default FormPart1;
