import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import { IconButton } from "@mui/material";
import {
  Delete as DeleteIcon,
  GroupAdd as GroupAddIcon,
  RemoveRedEye,
} from "@mui/icons-material";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { baseUrl } from "../../utils/baseUrl";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("OrderNo", {
    header: "Order Number",
    size: 40,
  }),
  columnHelper.accessor("clientName", {
    header: "Client",
    size: 40,
  }),
  columnHelper.accessor("metalType", {
    header: "Metal Type",
    size: 40,
  }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 40,
    Cell: (cell) => (
      <span
        style={{
          backgroundColor: "rgb(74 42 125)",
          padding: " 7px 14px",
          borderRadius: "12px",
          fontSize: "13px",
          color: "white",
        }}
      >
        {cell.row.original.status}
      </span>
    ),
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    size: 40,
    Cell: (cell) => "₹ " + cell.row.original.amount,
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});
const SellExpired = () => {
  const Token = sessionStorage.getItem("token");
  const [ExpiredProposals, setExpiredPurposal] = useState();
  const [employees, setEmployees] = useState([]);
  useEffect(() => {
    GetExpiredAPI();
  }, []);
  const [isExpiredTable, setisExpiredTable] = useState(false);
  const GetExpiredAPI = async () => {
    try {
      const url = `${baseUrl}/partnerPortal/sellOrderList`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      // const employees = response.data.data;

      if (response.data.data.expiredOrders) {
        setisExpiredTable(true);
      }

      setExpiredPurposal(response.data.data.expiredOrders);
      const employees = response.data.data.expiredOrders;
      // setprogressData(response.data.data.PendingProposals);
      employees?.map((index, item) => {
        // console.log("Employee list=", employees.index.id);
        //  setclientCreatedDate(item.createdAt);

        // employees.set("slno",++item)
        index.slno = ++item;
      });

      setEmployees(employees);
      // console.log("employee")
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
  };
  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      OrderNo: row.original.OrderNo,
      clientName: row.original.clientName,
      metalType: row.original.metalType,
      amount: row.original.amount,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const rowData = employees.map((row) => ({
      OrderNo: row.original.OrderNo,
      clientName: row.original.clientName,
      metalType: row.original.metalType,
      amount: row.original.amount,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };
  const table = useMaterialReactTable({
    columns,
    data: employees,
    enableRowSelection: true, //enable some features
    // enableColumnOrdering: true, //enable a feature for all columns
    enableGlobalFilter: true, //turn off a feature
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: false,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example

      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });
  const deletePurposal = (row) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${baseUrl}/proposalDelete/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Your Purposal has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          GetExpiredAPI();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your Purposal is safe",
          icon: "error",
        });
      }
    });
  };

  return <MaterialReactTable table={table} />;
};

export default SellExpired;
