import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";

const FormPart3 = ({ formData, handleChange, prevStep, handleSubmit }) => {
  const [validated, setValidated] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [accountNoMismatch, setAccountNoMismatch] = useState(false);

  const validateForm = () => {
    const isAccountNoValid = formData.accNo === formData.confirmaccNo;
    setAccountNoMismatch(!isAccountNoValid);
    return (
      formData.accHoldername &&
      formData.accNo &&
      formData.confirmaccNo &&
      formData.Ifsccode &&
      isAccountNoValid &&
      termsAccepted
    );
  };

  const handleSubmitInternal = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false || !validateForm()) {
      e.stopPropagation();
    } else {
      handleSubmit(e);
    }
    setValidated(true);
  };

  const handleTermsCheckboxChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  return (
    <div className="registration-form">
      <h2 className="mb-4 center-text">Bank Details</h2>
      <Form noValidate validated={validated} onSubmit={handleSubmitInternal}>
        <Row className="row-bottom">
          <Col md={6}>
            <Form.Group controlId="accountHolderName">
              <Form.Label>Account Holder's Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Account Holder's Name"
                name="accHoldername"
                value={formData.accHoldername}
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide the account holder's name.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="accountNo">
              <Form.Label>Account No.</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Account No."
                name="accNo"
                value={formData.accNo}
                onChange={handleChange}
                required
                maxLength={18}
                pattern="\d{9,18}"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid account number.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="row-bottom" style={{ marginBottom: "3rem" }}>
          <Col md={6}>
            <Form.Group controlId="confirmAccountNo">
              <Form.Label>Confirm Account No.</Form.Label>
              <Form.Control
                type="text"
                placeholder="Confirm Account No."
                name="confirmaccNo"
                value={formData.confirmaccNo}
                onChange={handleChange}
                required
                maxLength={18}
                pattern="\d{9,18}"
                isInvalid={accountNoMismatch}
              />
              <Form.Control.Feedback type="invalid">
                {accountNoMismatch
                  ? "Account numbers do not match."
                  : "Please confirm the account number."}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="ifscCode">
              <Form.Label>IFSC Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter IFSC Code"
                name="Ifsccode"
                value={formData.Ifsccode}
                onChange={handleChange}
                required
                maxLength={11}
                pattern="^[A-Za-z]{4}[a-zA-Z0-9]{7}$"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid IFSC code.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="termsCheckbox">
          <Form.Check
            type="checkbox"
            label={
              <span style={{ color: "#4a2a7d" }}>
                Please accept our{" "}
                <a href="/tnc" style={{ color: "#4a2a7d" }}>
                  terms &amp; conditions
                </a>
              </span>
            }
            checked={termsAccepted}
            onChange={handleTermsCheckboxChange}
            isInvalid={!termsAccepted}
            feedback="You must accept the terms and conditions."
            required
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
              fontSize: "15px",
            }}
          />
          <Form.Control.Feedback type="invalid">
            You must accept the terms and conditions.
          </Form.Control.Feedback>
        </Form.Group>
        <div className="mt-4 text-center">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "2rem",
            }}
          >
            <Button
              variant="secondary"
              className="mr-2 prev-btn"
              onClick={prevStep}
            >
              Previous
            </Button>
            <Button
              className="submit-btn mr-2 prev-btn"
              style={{
                backgroundColor: "#4a2a7d",
                borderRadius: "30px",
              }}
              type="submit"
            >
              Submit
            </Button>
          </div>
          <p>
            Already have an account? <a href="/login">Login</a>
          </p>
        </div>
      </Form>
    </div>
  );
};

export default FormPart3;

// import React from "react";
// import { Form, Button, Row, Col } from "react-bootstrap";

// const FormPart3 = ({ formData, handleChange, prevStep, handleSubmit }) => {
//   return (
//     <div className="registration-form">
//       <h2 className="mb-4 center-text">Bank Details</h2>
//       <Form onSubmit={handleSubmit}>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="accountHolderName">
//               <Form.Label>Account Holder's Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Account Holder's Name"
//                 name="accountHolderName"
//                 value={formData.accountHolderName}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="accountNo">
//               <Form.Label>Account No.</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Account No."
//                 name="accountNo"
//                 value={formData.accountNo}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="row-bottom" style={{ marginBottom: "3rem" }}>
//           <Col md={6}>
//             <Form.Group controlId="confirmAccountNo">
//               <Form.Label>Confirm Account No.</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Confirm Account No."
//                 name="confirmAccountNo"
//                 value={formData.confirmAccountNo}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="ifscCode">
//               <Form.Label>IFSC Code</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter IFSC Code"
//                 name="ifscCode"
//                 value={formData.ifscCode}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>

//         <div className="mt-4 text-center">
//           <Button
//             variant="secondary"
//             className="mr-2 prev-btn"
//             onClick={prevStep}
//           >
//             Previous
//           </Button>
//           <Button
//             className="submit-btn"
//             type="submit"
//             style={{ backgroundColor: "#4a2a7d", borderRadius: "30px" }}
//           >
//             Submit
//           </Button>
//           <p>
//             Already have an account? <a href="/login">Login</a>
//           </p>
//         </div>
//       </Form>
//     </div>
//   );
// };

// export default FormPart3;
