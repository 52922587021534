import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import GOLD from "../../assets/Icons/gold_shield.png";
import SILVER from "../../assets/Icons/silver_shield.png";
import "../../assets/css/final_create_sip.css";
import { baseUrl } from "../../utils/baseUrl";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";

const FinalCreateSip = () => {
  const clientData = useSelector((state) => state);
  const [loading, setLoading] = useState(false);

  const Token = sessionStorage.getItem("token");
  const { clientId } = useParams();
  const navigate = useNavigate();

  const [selectedMetal, setSelectedMetal] = useState("gold");
  const [isGoldSelected, setIsGoldSelected] = useState(true);
  const [isRupeeSelected, setIsRupeeSelected] = useState(true);
  const [rupeeValue, setRupeeValue] = useState("");
  const [gramValue, setGramValue] = useState("");
  const [goldPrice, setGoldPrice] = useState("");
  const [silverPrice, setSilverPrice] = useState("");
  const [timer, setTimer] = useState(180);

  const [selectedDay, setSelectedDay] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setShowPicker(false);
  };

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  useEffect(() => {
    if (isGoldSelected) {
      getGoldLive();
    } else {
      getSilverLive();
    }
  }, [isGoldSelected]);

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer]);

  const getGoldLive = async () => {
    try {
      const url = `${baseUrl}/liveprice/gold`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const liveValue = response.data.data;
      setGoldPrice(liveValue);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const getSilverLive = async () => {
    try {
      const url = `${baseUrl}/liveprice/silver`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const liveValue = response.data.data;
      setSilverPrice(liveValue);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const createSIP = async () => {
    setLoading(true);
    const weight = isRupeeSelected
      ? (rupeeValue / (isGoldSelected ? goldPrice : silverPrice)).toFixed(4)
      : gramValue;
    const amount = isRupeeSelected
      ? rupeeValue
      : (gramValue * (isGoldSelected ? goldPrice : silverPrice)).toFixed(2);

    const data = {
      day_of_month: selectedDay,
      amount: amount,
      frequency: "monthly",
      metal_type: selectedMetal,
      client_id: clientId,
    };
    try {
      const response = await axios.post(
        `${baseUrl}/partnerPortal/sip/proposal/create/${clientId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        }
      );

      if (response.data.message == "the proposal created successfully") {
        setLoading(false);
        Swal.fire({
          title: "Thank You!",
          text: "Emandate is creating Succussfully!",
          icon: "success",
        });
        navigate("/dashboard/sip");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.message,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to create SIP. Please try again.",
      });
    }
  };

  const handleMetalButtonClick = (isGold) => {
    setIsGoldSelected(isGold);
    setSelectedMetal(isGold ? "gold" : "silver");
    setTimer(180); // Reset the timer to 3 minutes
    if (isGold) {
      getGoldLive();
    } else {
      getSilverLive();
    }
  };

  const handleUnitButtonClick = (isRupee) => {
    setIsRupeeSelected(isRupee);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const gstRate = 1.03;

    if (isRupeeSelected) {
      setRupeeValue(value);
      setGramValue(
        value
          ? (
              value /
              gstRate /
              (isGoldSelected ? goldPrice : silverPrice)
            ).toFixed(4)
          : ""
      );
    } else {
      setGramValue(value);
      setRupeeValue(
        value
          ? (
              value *
              (isGoldSelected ? goldPrice : silverPrice) *
              gstRate
            ).toFixed(2)
          : ""
      );
    }
  };

  const handleAmountButtonClick = (amount) => {
    const value = amount.replace("₹", "");
    const gstRate = 1.03;
    if (isRupeeSelected) {
      setRupeeValue(value);
      setGramValue(
        value
          ? (
              value /
              gstRate /
              (isGoldSelected ? goldPrice : silverPrice)
            ).toFixed(4)
          : ""
      );
    } else {
      setGramValue(value);
      setRupeeValue(
        value
          ? (
              value *
              (isGoldSelected ? goldPrice : silverPrice) *
              gstRate
            ).toFixed(2)
          : ""
      );
    }
  };

  const metal = isGoldSelected ? "GOLD" : "SILVER";
  const metalPrice = isGoldSelected ? `₹${goldPrice}/gm` : `₹${silverPrice}/gm`;
  const metalImage = isGoldSelected ? GOLD : SILVER;
  const unit = isRupeeSelected ? "₹" : "gm";
  const metalTag = isGoldSelected ? "24K 99.9% Pure Gold" : "999.9 Purity";

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <div className={loading ? "blurred" : ""}>
      {loading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <header className="header-container">
        <div className="button-group">
          <button
            className={`final-btn gold-btn gl ${
              isGoldSelected ? "active" : ""
            }`}
            onClick={() => handleMetalButtonClick(true)}
          >
            Gold
          </button>
          <button
            className={`final-btn silver-btn gl ${
              !isGoldSelected ? "active" : ""
            }`}
            onClick={() => handleMetalButtonClick(false)}
          >
            Silver
          </button>
        </div>
      </header>
      <div className="bordered-div">
        <div className="inner-container">
          <div className="inner-div">
            <button
              className="btn card-btn"
              style={{ backgroundColor: "#4a2a7d", color: "white" }}
            >
              {metal}
            </button>
            <h3>{`${metal} PRICE`}</h3>
            <div className="paragst">
              <p className="medium-text">{metalPrice}</p>
              <p className="gst">+3% GST</p>
            </div>
            <p className="mini-text">{metalTag}</p>
          </div>
          <div className="inner-div hide-text">Div 2</div>
          <div className="inner-div">
            <div
              className=""
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={metalImage}
                alt={`${metal} image`}
                style={{ width: "110px", marginBottom: "1rem" }}
              />
            </div>
            <div className="timer">
              <p className="mini-text">{`${metal} rate expires in :`}</p>
              <p className="time">{formatTime(timer)}</p>
            </div>
          </div>
        </div>
        <div className="button-row-bottom">
          <div className="sip-irg">
            <button
              className={`final-btn ir-btn ${isRupeeSelected ? "active" : ""}`}
              onClick={() => handleUnitButtonClick(true)}
            >
              In Rupee
            </button>
          </div>
        </div>
        <div className="input-container">
          <div className="input-border">
            <div className="input-1">
              <span className="rupee">{unit}</span>
            </div>
            <input
              type="text"
              value={isRupeeSelected ? rupeeValue : gramValue}
              onChange={handleInputChange}
            />
            <div className="input-2">
              <span className="rupee">
                {isRupeeSelected ? `${gramValue}gm` : `₹ ${rupeeValue}`}
              </span>
            </div>
          </div>
        </div>
        <div className="button-container">
          {["100", "200", "300", "400", "500"].map((amount) => (
            <button
              className="btn card-btn"
              key={amount}
              onClick={() => handleAmountButtonClick(amount)}
              style={{
                backgroundColor: "#4a2a7d",
                color: "white",
              }}
            >
              {isRupeeSelected ? `₹ ${amount}` : `${amount}gm`}
            </button>
          ))}
        </div>

        <div className="selected-date">
          <div
            className="input-group"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              type="button"
              className="btn"
              onClick={togglePicker}
              style={{
                backgroundColor: "#4a2a7d",
                color: "white",
                zIndex: "0",
              }}
            >
              {selectedDay ? `Selected Day: ${selectedDay}` : "Select Day"}
            </button>
          </div>

          {showPicker && (
            <div
              className="mt-3 p-2"
              style={{
                width: "242px",
                borderRadius: "5px",
                border: "1px solid",
                borderColor: "#4a2a7d",
              }}
              ref={pickerRef}
            >
              <div className="d-flex flex-wrap">
                {Array.from({ length: 28 }, (_, i) => i + 1).map((day) => (
                  <button
                    key={day}
                    type="button"
                    className="btn btn-outline-primary "
                    style={{
                      width: "30px",
                      height: "30-px",
                      fontSize: "12px",
                      margin: "1px",
                      alignItems: "center",
                      padding: "6px 6px 6px 6px",
                      borderColor: "#4a2a7d",
                    }}
                    onClick={() => handleDayClick(day)}
                  >
                    {day}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="bottom-button-container">
          <button
            type="button"
            className="btn card-btn submit-btn"
            onClick={createSIP}
            style={{ backgroundColor: "#4a2a7d", color: "white" }}
          >
            Create SIP
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinalCreateSip;

// import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import GOLD from "../../assets/Icons/Gold.png";
// import SILVER from "../../assets/Icons/Silver (1).png";
// import "../../assets/css/final_create_sip.css";
// import { baseUrl } from "../../utils/baseUrl";
// import { useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import Swal from "sweetalert2";

// const FinalCreateSip = () => {
//   const clientData = useSelector((state) => state);

//   const Token = sessionStorage.getItem("token");
//   const { clientId } = useParams();
//   const navigate = useNavigate();

//   const [selectedMetal, setSelectedMetal] = useState("gold");
//   const [isGoldSelected, setIsGoldSelected] = useState(true);
//   const [isRupeeSelected, setIsRupeeSelected] = useState(true);
//   const [rupeeValue, setRupeeValue] = useState("");
//   const [gramValue, setGramValue] = useState("");
//   const [goldPrice, setGoldPrice] = useState("");
//   const [silverPrice, setSilverPrice] = useState("");
//   const [timer, setTimer] = useState(180);

//   const [selectedDay, setSelectedDay] = useState("");
//   const [showPicker, setShowPicker] = useState(false);
//   const pickerRef = useRef(null);

//   const handleDayClick = (day) => {
//     setSelectedDay(day);
//     setShowPicker(false);
//   };

//   const togglePicker = () => {
//     setShowPicker(!showPicker);
//   };

//   const handleClickOutside = (event) => {
//     if (pickerRef.current && !pickerRef.current.contains(event.target)) {
//       setShowPicker(false);
//     }
//   };

//   useEffect(() => {
//     if (showPicker) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [showPicker]);

//   useEffect(() => {
//     if (isGoldSelected) {
//       getGoldLive();
//     } else {
//       getSilverLive();
//     }
//   }, [isGoldSelected]);

//   useEffect(() => {
//     let countdown;
//     if (timer > 0) {
//       countdown = setInterval(() => {
//         setTimer((prevTimer) => prevTimer - 1);
//       }, 1000);
//     } else {
//       clearInterval(countdown);
//     }
//     return () => clearInterval(countdown);
//   }, [timer]);

//   const getGoldLive = async () => {
//     try {
//       const url = `${baseUrl}/liveprice/gold`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const liveValue = response.data.data;
//       setGoldPrice(liveValue);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const getSilverLive = async () => {
//     try {
//       const url = `${baseUrl}/liveprice/silver`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const liveValue = response.data.data;
//       setSilverPrice(liveValue);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const createSIP = async () => {
//     const weight = isRupeeSelected
//       ? (rupeeValue / (isGoldSelected ? goldPrice : silverPrice)).toFixed(4)
//       : gramValue;
//     const amount = isRupeeSelected
//       ? rupeeValue
//       : (gramValue * (isGoldSelected ? goldPrice : silverPrice)).toFixed(2);

//     const data = {
//       day_of_month: selectedDay,
//       amount: amount,
//       frequency: "monthly",
//       metal_type: selectedMetal,
//       client_id: clientId,
//     };
//     console.log(data);
//     try {
//       const response = await axios.post(
//         `${baseUrl}/partnerPortal/sip/proposal/create/${clientId}`,
//         data,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${Token}`,
//           },
//         }
//       );

//       if (response.data.message == "the proposal created successfully") {
//         Swal.fire({
//           title: "Thank You!",
//           text: "Emandate is creating Succussfully!",
//           icon: "success",
//         });
//         navigate("/sip");
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: response.data.message,
//         });
//       }
//     } catch (error) {
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Failed to create SIP. Please try again.",
//       });
//     }
//   };

//   const handleMetalButtonClick = (isGold) => {
//     setIsGoldSelected(isGold);
//     setSelectedMetal(isGold ? "gold" : "silver");
//     setTimer(180); // Reset the timer to 3 minutes
//     if (isGold) {
//       getGoldLive();
//     } else {
//       getSilverLive();
//     }
//   };

//   const handleUnitButtonClick = (isRupee) => {
//     setIsRupeeSelected(isRupee);
//   };

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     const gstRate = 1.03;

//     if (isRupeeSelected) {
//       setRupeeValue(value);
//       setGramValue(
//         value
//           ? (
//               value /
//               gstRate /
//               (isGoldSelected ? goldPrice : silverPrice)
//             ).toFixed(4)
//           : ""
//       );
//     } else {
//       setGramValue(value);
//       setRupeeValue(
//         value
//           ? (
//               value *
//               (isGoldSelected ? goldPrice : silverPrice) *
//               gstRate
//             ).toFixed(2)
//           : ""
//       );
//     }
//   };

//   const handleAmountButtonClick = (amount) => {
//     const value = amount.replace("₹", "");
//     const gstRate = 1.03;
//     if (isRupeeSelected) {
//       setRupeeValue(value);
//       setGramValue(
//         value
//           ? (
//               value /
//               gstRate /
//               (isGoldSelected ? goldPrice : silverPrice)
//             ).toFixed(4)
//           : ""
//       );
//     } else {
//       setGramValue(value);
//       setRupeeValue(
//         value
//           ? (
//               value *
//               (isGoldSelected ? goldPrice : silverPrice) *
//               gstRate
//             ).toFixed(2)
//           : ""
//       );
//     }
//   };

//   const metal = isGoldSelected ? "GOLD" : "SILVER";
//   const metalPrice = isGoldSelected ? `₹${goldPrice}/gm` : `₹${silverPrice}/gm`;
//   const metalImage = isGoldSelected ? GOLD : SILVER;
//   const unit = isRupeeSelected ? "₹" : "gm";
//   const metalTag = isGoldSelected ? "24K 99.9% Pure Gold" : "999.9 Purity";

//   const formatTime = (seconds) => {
//     const minutes = Math.floor(seconds / 60);
//     const secs = seconds % 60;
//     return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
//   };

//   return (
//     <>
//       <header className="header-container">
//         <div className="button-group">
//           <button
//             className={`final-btn gold-btn gl ${
//               isGoldSelected ? "active" : ""
//             }`}
//             onClick={() => handleMetalButtonClick(true)}
//           >
//             Gold
//           </button>
//           <button
//             className={`final-btn silver-btn gl ${
//               !isGoldSelected ? "active" : ""
//             }`}
//             onClick={() => handleMetalButtonClick(false)}
//           >
//             Silver
//           </button>
//         </div>
//       </header>
//       <div className="bordered-div">
//         <div className="inner-container">
//           <div className="inner-div">
//             <button
//               className="btn card-btn"
//               style={{ backgroundColor: "#4a2a7d", color: "white" }}
//             >
//               {metal}
//             </button>
//             <h3>{`${metal} PRICE`}</h3>
//             <div className="paragst">
//               <p className="medium-text">{metalPrice}</p>
//               <p className="gst">+3% GST</p>
//             </div>
//             <p className="mini-text">{metalTag}</p>
//           </div>
//           <div className="inner-div hide-text">Div 2</div>
//           <div className="inner-div">
//             <div
//               className=""
//               style={{
//                 width: "100%",
//                 display: "flex",
//                 justifyContent: "center",
//               }}
//             >
//               <img
//                 src={metalImage}
//                 alt={`${metal} image`}
//                 style={{ width: "110px", marginBottom: "1rem" }}
//               />
//             </div>
//             <div className="timer">
//               <p className="mini-text">{`${metal} rate expires in :`}</p>
//               <p className="time">{formatTime(timer)}</p>
//             </div>
//           </div>
//         </div>
//         <div className="button-row-bottom">
//           <div className="sip-irg">
//             <button
//               className={`final-btn ir-btn ${isRupeeSelected ? "active" : ""}`}
//               onClick={() => handleUnitButtonClick(true)}
//             >
//               In Rupee
//             </button>
//           </div>
//         </div>
//         <div className="input-container">
//           <div className="input-border">
//             <div className="input-1">
//               <span className="rupee">{unit}</span>
//             </div>
//             <input
//               type="text"
//               value={isRupeeSelected ? rupeeValue : gramValue}
//               onChange={handleInputChange}
//             />
//             <div className="input-2">
//               <span className="rupee">
//                 {isRupeeSelected ? `${gramValue}gm` : `₹ ${rupeeValue}`}
//               </span>
//             </div>
//           </div>
//         </div>
//         <div className="button-container">
//           {["100", "200", "300", "400", "500"].map((amount) => (
//             <button
//               className="btn card-btn"
//               key={amount}
//               onClick={() => handleAmountButtonClick(amount)}
//               style={{
//                 backgroundColor: "#4a2a7d",
//                 color: "white",
//               }}
//             >
//               {isRupeeSelected ? `₹ ${amount}` : `${amount}gm`}
//             </button>
//           ))}
//         </div>

//         <div className="selected-date">
//           <div
//             className="input-group"
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <button
//               type="button"
//               className="btn"
//               onClick={togglePicker}
//               style={{ backgroundColor: "#4a2a7d", color: "white" }}
//             >
//               {selectedDay ? `Selected Day: ${selectedDay}` : "Select Day"}
//             </button>
//           </div>

//           {showPicker && (
//             <div
//               className="mt-3 p-2"
//               style={{
//                 width: "242px",
//                 borderRadius: "5px",
//                 border: "1px solid",
//                 borderColor: "#4a2a7d",
//               }}
//               ref={pickerRef}
//             >
//               <div className="d-flex flex-wrap">
//                 {Array.from({ length: 28 }, (_, i) => i + 1).map((day) => (
//                   <button
//                     key={day}
//                     type="button"
//                     className="btn btn-outline-primary "
//                     style={{
//                       width: "30px",
//                       height: "30-px",
//                       fontSize: "12px",
//                       margin: "1px",
//                       alignItems: "center",
//                       padding: "6px 6px 6px 6px",
//                       borderColor: "#4a2a7d",
//                     }}
//                     onClick={() => handleDayClick(day)}
//                   >
//                     {day}
//                   </button>
//                 ))}
//               </div>
//             </div>
//           )}
//         </div>
//         <div className="bottom-button-container">
//           <button
//             type="button"
//             className="btn card-btn submit-btn"
//             onClick={createSIP}
//             style={{ backgroundColor: "#4a2a7d", color: "white" }}
//           >
//             Create SIP
//           </button>
//         </div>
//       </div>
//     </>
//   );
// };

// export default FinalCreateSip;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import GOLD from "../../assets/Icons/Gold.png";
// import SILVER from "../../assets/Icons/Silver (1).png";
// import "../../assets/css/final_create_sip.css";
// import { baseUrl } from "../../utils/baseUrl";
// import { useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import Swal from "sweetalert2";
// import { getMonthDays } from "../../utils/monthDays";
// import { Dropdown, DropdownButton } from "react-bootstrap";

// const FinalCreateSip = () => {
//   const clientData = useSelector((state) => state);

//   const Token = sessionStorage.getItem("token");
//   const { clientId } = useParams();
//   const navigate = useNavigate();

//   const [selectedMetal, setSelectedMetal] = useState("gold");
//   const [isGoldSelected, setIsGoldSelected] = useState(true);
//   const [isRupeeSelected, setIsRupeeSelected] = useState(true);
//   const [rupeeValue, setRupeeValue] = useState("");
//   const [gramValue, setGramValue] = useState("");
//   const [goldPrice, setGoldPrice] = useState("");
//   const [silverPrice, setSilverPrice] = useState("");
//   const [timer, setTimer] = useState(180);

//   const [selectedDay, setSelectedDay] = useState("");

//   const days = Array.from({ length: getMonthDays(2, 2023) }, (_, i) => i + 1); // Only 28 days for February

//   const groupedDays = [];
//   for (let i = 0; i < days.length; i += 7) {
//     groupedDays.push(days.slice(i, i + 7));
//   }

//   const handleDayClick = (day) => {
//     setSelectedDay(day.toString());
//   };

//   const handleInpChange = (event) => {
//     const { value } = event.target;
//     setSelectedDay(value.toString());
//   };

//   useEffect(() => {
//     if (isGoldSelected) {
//       getGoldLive();
//     } else {
//       getSilverLive();
//     }
//   }, [isGoldSelected]);

//   useEffect(() => {
//     let countdown;
//     if (timer > 0) {
//       countdown = setInterval(() => {
//         setTimer((prevTimer) => prevTimer - 1);
//       }, 1000);
//     } else {
//       clearInterval(countdown);
//     }
//     return () => clearInterval(countdown);
//   }, [timer]);

//   const getGoldLive = async () => {
//     try {
//       const url = `${baseUrl}/liveprice/gold`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const liveValue = response.data.data;
//       setGoldPrice(liveValue);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const getSilverLive = async () => {
//     try {
//       const url = `${baseUrl}/liveprice/silver`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const liveValue = response.data.data;
//       setSilverPrice(liveValue);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const createSIP = async () => {
//     const weight = isRupeeSelected
//       ? (rupeeValue / (isGoldSelected ? goldPrice : silverPrice)).toFixed(4)
//       : gramValue;
//     const amount = isRupeeSelected
//       ? rupeeValue
//       : (gramValue * (isGoldSelected ? goldPrice : silverPrice)).toFixed(2);

//     const data = {
//       day_of_month: selectedDay,
//       amount: amount,
//       frequency: "monthly",
//       metal_type: selectedMetal,
//       client_id: clientId,
//     };
//     console.log(data);
//     try {
//       const response = await axios.post(
//         `${baseUrl}/partnerPortal/sip/proposal/create/${clientId}`,
//         data,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${Token}`,
//           },
//         }
//       );

//       if (response.data.message == "the proposal created successfully") {
//         Swal.fire({
//           title: "Thank You!",
//           text: "Emandate is creating Succussfully!",
//           icon: "success",
//         });
//         navigate("/sip");
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: response.data.message,
//         });
//       }
//     } catch (error) {
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Failed to create SIP. Please try again.",
//       });
//     }
//   };

//   const handleMetalButtonClick = (isGold) => {
//     setIsGoldSelected(isGold);
//     setSelectedMetal(isGold ? "gold" : "silver");
//     setTimer(180); // Reset the timer to 3 minutes
//     if (isGold) {
//       getGoldLive();
//     } else {
//       getSilverLive();
//     }
//   };

//   const handleUnitButtonClick = (isRupee) => {
//     setIsRupeeSelected(isRupee);
//   };

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     if (isRupeeSelected) {
//       setRupeeValue(value);
//       setGramValue(
//         value
//           ? (value / (isGoldSelected ? goldPrice : silverPrice)).toFixed(4)
//           : ""
//       );
//     } else {
//       setGramValue(value);
//       setRupeeValue(
//         value
//           ? (value * (isGoldSelected ? goldPrice : silverPrice)).toFixed(2)
//           : ""
//       );
//     }
//   };

//   const handleAmountButtonClick = (amount) => {
//     const value = amount.replace("₹", "");
//     if (isRupeeSelected) {
//       setRupeeValue(value);
//       setGramValue(
//         value
//           ? (value / (isGoldSelected ? goldPrice : silverPrice)).toFixed(4)
//           : ""
//       );
//     } else {
//       setGramValue(value);
//       setRupeeValue(
//         value
//           ? (value * (isGoldSelected ? goldPrice : silverPrice)).toFixed(2)
//           : ""
//       );
//     }
//   };

//   const metal = isGoldSelected ? "GOLD" : "SILVER";
//   const metalPrice = isGoldSelected ? `₹${goldPrice}/gm` : `₹${silverPrice}/gm`;
//   const metalImage = isGoldSelected ? GOLD : SILVER;
//   const unit = isRupeeSelected ? "₹" : "gm";
//   const metalTag = isGoldSelected ? "24K 99.9% Pure Gold" : "999.9 Purity";

//   const formatTime = (seconds) => {
//     const minutes = Math.floor(seconds / 60);
//     const secs = seconds % 60;
//     return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
//   };

//   return (
//     <>
//       <header className="header-container">
//         <div className="button-group">
//           <button
//             className={`final-btn gold-btn gl ${
//               isGoldSelected ? "active" : ""
//             }`}
//             onClick={() => handleMetalButtonClick(true)}
//           >
//             Gold
//           </button>
//           <button
//             className={`final-btn silver-btn gl ${
//               !isGoldSelected ? "active" : ""
//             }`}
//             onClick={() => handleMetalButtonClick(false)}
//           >
//             Silver
//           </button>
//         </div>
//       </header>
//       <div className="bordered-div">
//         <div className="inner-container">
//           <div className="inner-div">
//             <button
//               className="btn card-btn"
//               style={{ backgroundColor: "#4a2a7d", color: "white" }}
//             >
//               {metal}
//             </button>
//             <h3>{`${metal} PRICE`}</h3>
//             <div className="paragst">
//               <p className="medium-text">{metalPrice}</p>
//               <p className="gst">+3% GST</p>
//             </div>
//             <p className="mini-text">{metalTag}</p>
//           </div>
//           <div className="inner-div hide-text">Div 2</div>
//           <div className="inner-div">
//             <div
//               className=""
//               style={{
//                 width: "100%",
//                 display: "flex",
//                 justifyContent: "center",
//               }}
//             >
//               <img
//                 src={metalImage}
//                 alt={`${metal} image`}
//                 style={{ width: "110px", marginBottom: "1rem" }}
//               />
//             </div>
//             <div className="timer">
//               <p className="mini-text">{`${metal} rate expires in :`}</p>
//               <p className="time">{formatTime(timer)}</p>
//             </div>
//           </div>
//         </div>
//         <div className="button-row-bottom">
//           <div className="sip-irg">
//             <button
//               className={`final-btn ir-btn ${isRupeeSelected ? "active" : ""}`}
//               onClick={() => handleUnitButtonClick(true)}
//             >
//               In Rupee
//             </button>
//           </div>
//         </div>
//         <div className="input-container">
//           <div className="input-border">
//             <div className="input-1">
//               <span className="rupee">{unit}</span>
//             </div>
//             <input
//               type="text"
//               value={isRupeeSelected ? rupeeValue : gramValue}
//               onChange={handleInputChange}
//             />
//             <div className="input-2">
//               <span className="rupee">
//                 {isRupeeSelected ? `${gramValue}gm` : `₹ ${rupeeValue}`}
//               </span>
//             </div>
//           </div>
//         </div>
//         <div className="button-container">
//           {["100", "200", "300", "400", "500"].map((amount) => (
//             <button
//               className="btn card-btn"
//               key={amount}
//               onClick={() => handleAmountButtonClick(amount)}
//               style={{
//                 backgroundColor: "#4a2a7d",
//                 color: "white",
//               }}
//             >
//               {isRupeeSelected ? `₹ ${amount}` : `${amount}gm`}
//             </button>
//           ))}
//         </div>

//         <div
//           className="mt-5"
//           style={{
//             Width: "100%",
//             display: "flex",
//             justifyContent: "center",
//             gap: "3px",
//           }}
//         >
//           <DropdownButton
//             id="custom-dropdown"
//             title="Select SIP Date"
//             onSelect={handleDayClick}
//           >
//             {groupedDays.map((group, index) => (
//               <div key={index} style={{ display: "flex", flexWrap: "wrap" }}>
//                 {group.map((day) => (
//                   <Dropdown.Item
//                     key={day}
//                     eventKey={day.toString()}
//                     active={selectedDay === day.toString()}
//                     style={{
//                       width: "14.28%",
//                       textAlign: "center",
//                       display: "inline-block",
//                     }}
//                   >
//                     {day}
//                   </Dropdown.Item>
//                 ))}
//               </div>
//             ))}
//           </DropdownButton>
//           {selectedDay && (
//             <div className="selected-day" style={{ color: "white" }}>
//               {selectedDay}
//             </div>
//           )}
//         </div>
//         <div className="bottom-button-container">
//           <button
//             type="button"
//             className="btn card-btn submit-btn"
//             onClick={createSIP}
//             style={{ backgroundColor: "#4a2a7d", color: "white" }}
//           >
//             Create SIP
//           </button>
//         </div>
//       </div>
//     </>
//   );
// };

// export default FinalCreateSip;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import GOLD from "../../assets/Icons/gold_Icon (2).png";
// import SILVER from "../../assets/Icons/silver.png";
// import "../../assets/css/final_create.css";
// import { baseUrl } from "../../utils/baseUrl";
// import { useSelector } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import { getMonthDays } from "../../utils/monthDays";
// import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
// import { ArrowDropDownIcon } from "@mui/x-date-pickers";
// import { Dropdown, DropdownButton } from "react-bootstrap";

// const FinalCreateSip = () => {
//   const clientData = useSelector((state) => state);
//   const Token = sessionStorage.getItem("token");
//   const location = useLocation();
//   const navigate = useNavigate();
//   let client = location.state?.client;

//   const [selectedMetal, setSelectedMetal] = useState("gold");
//   const [isGoldSelected, setIsGoldSelected] = useState(true);
//   const [isRupeeSelected, setIsRupeeSelected] = useState(true);
//   const [rupeeValue, setRupeeValue] = useState("");
//   const [gramValue, setGramValue] = useState("");
//   const [goldPrice, setGoldPrice] = useState("");
//   const [silverPrice, setSilverPrice] = useState("");
//   const [clientId, setClientId] = useState(client._id);
//   const [timer, setTimer] = useState(180);

//   const [selectedDay, setSelectedDay] = useState("");

//   const days = Array.from({ length: getMonthDays(2, 2023) }, (_, i) => i + 1); // Only 28 days for February

//   const groupedDays = [];
//   for (let i = 0; i < days.length; i += 7) {
//     groupedDays.push(days.slice(i, i + 7));
//   }

//   const handleDayClick = (day) => {
//     setSelectedDay(day.toString());
//   };

//   const handleInpChange = (event) => {
//     const { value } = event.target;
//     setSelectedDay(value.toString());
//   };

//   useEffect(() => {
//     if (isGoldSelected) {
//       getGoldLive();
//     } else {
//       getSilverLive();
//     }
//   }, [isGoldSelected]);

//   useEffect(() => {
//     if (clientData && clientData.client && clientData.client._id) {
//       setClientId(clientData.client._id);
//     }
//   }, [clientData]);

//   useEffect(() => {
//     let countdown;
//     if (timer > 0) {
//       countdown = setInterval(() => {
//         setTimer((prevTimer) => prevTimer - 1);
//       }, 1000);
//     } else {
//       clearInterval(countdown);
//     }
//     return () => clearInterval(countdown);
//   }, [timer]);

//   const getGoldLive = async () => {
//     try {
//       const url = `${baseUrl}/liveprice/gold`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const liveValue = response.data.data;
//       setGoldPrice(liveValue);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const getSilverLive = async () => {
//     try {
//       const url = `${baseUrl}/liveprice/silver`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const liveValue = response.data.data;
//       setSilverPrice(liveValue);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const createSIP = async () => {
//     const weight = isRupeeSelected
//       ? (rupeeValue / (isGoldSelected ? goldPrice : silverPrice)).toFixed(4)
//       : gramValue;
//     const amount = isRupeeSelected
//       ? rupeeValue
//       : (gramValue * (isGoldSelected ? goldPrice : silverPrice)).toFixed(2);

//     let data = JSON.stringify({
//       metal_type: selectedMetal,
//       amount: amount,
//       quantity: weight,
//     });

//     let config = {
//       method: "post",
//       maxBodyLength: Infinity,
//       url: `${baseUrl}/partnerPortal/sip/create`,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${Token}`,
//       },
//       data: data,
//     };

//     axios
//       .request(config)
//       .then((response) => {
//         return response.data;
//       })
//       .then((d) => {
//         if (d.message === "The proposal was created successfully") {
//           Swal.fire({
//             title: "Thank You!",
//             text: "The proposal was created successfully!",
//             icon: "success",
//           });
//           navigate("/proposal");
//         } else {
//           Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: "Please complete the KYC.",
//           });
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const handleMetalButtonClick = (isGold) => {
//     setIsGoldSelected(isGold);
//     setSelectedMetal(isGold ? "gold" : "silver");
//     setTimer(180); // Reset the timer to 3 minutes
//     if (isGold) {
//       getGoldLive();
//     } else {
//       getSilverLive();
//     }
//   };

//   const handleUnitButtonClick = (isRupee) => {
//     setIsRupeeSelected(isRupee);
//   };

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     if (isRupeeSelected) {
//       setRupeeValue(value);
//       setGramValue(
//         value
//           ? (value / (isGoldSelected ? goldPrice : silverPrice)).toFixed(4)
//           : ""
//       );
//     } else {
//       setGramValue(value);
//       setRupeeValue(
//         value
//           ? (value * (isGoldSelected ? goldPrice : silverPrice)).toFixed(2)
//           : ""
//       );
//     }
//   };

//   const handleAmountButtonClick = (amount) => {
//     const value = amount.replace("₹", "");
//     if (isRupeeSelected) {
//       setRupeeValue(value);
//       setGramValue(
//         value
//           ? (value / (isGoldSelected ? goldPrice : silverPrice)).toFixed(4)
//           : ""
//       );
//     } else {
//       setGramValue(value);
//       setRupeeValue(
//         value
//           ? (value * (isGoldSelected ? goldPrice : silverPrice)).toFixed(2)
//           : ""
//       );
//     }
//   };

//   const metal = isGoldSelected ? "GOLD" : "SILVER";
//   const metalPrice = isGoldSelected ? `₹${goldPrice}/gm` : `₹${silverPrice}/gm`;
//   const metalImage = isGoldSelected ? GOLD : SILVER;
//   const unit = isRupeeSelected ? "₹" : "gm";
//   const metalTag = isGoldSelected ? "24K 99.9% Pure Gold" : "999.9 Purity";

//   const formatTime = (seconds) => {
//     const minutes = Math.floor(seconds / 60);
//     const secs = seconds % 60;
//     return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
//   };

//   return (
//     <>
//       <header className="header-container">
//         <div className="button-group">
//           <button
//             className={`final-btn gold-btn gl ${
//               isGoldSelected ? "active" : ""
//             }`}
//             onClick={() => handleMetalButtonClick(true)}
//           >
//             Gold
//           </button>
//           <button
//             className={`final-btn silver-btn gl ${
//               !isGoldSelected ? "active" : ""
//             }`}
//             onClick={() => handleMetalButtonClick(false)}
//           >
//             Silver
//           </button>
//         </div>
//       </header>
//       <div className="bordered-div">
//         <div className="inner-container">
//           <div className="inner-div">
//             <button
//               className="btn card-btn"
//               style={{ backgroundColor: "#4a2a7d", color: "white" }}
//             >
//               {metal}
//             </button>
//             <h3>{`${metal} PRICE`}</h3>
//             <div className="paragst">
//               <p className="medium-text">{metalPrice}</p>
//               <p className="gst">+3% GST</p>
//             </div>
//             <p className="mini-text">{metalTag}</p>
//           </div>
//           <div className="inner-div hide-text">Div 2</div>
//           <div className="inner-div">
//             <img
//               src={metalImage}
//               alt={`${metal} image`}
//               style={{ width: "100%", marginBottom: "1rem" }}
//             />
//             <div className="timer">
//               <p className="mini-text">{`${metal} rate expires in :`}</p>
//               <p className="time">{formatTime(timer)}</p>
//             </div>
//           </div>
//         </div>
//         <div className="button-row-bottom">
//           <div className="irg">
//             <button
//               className={`final-btn ir-btn ${isRupeeSelected ? "active" : ""}`}
//               onClick={() => handleUnitButtonClick(true)}
//             >
//               In Rupee
//             </button>
//             <div className="vl"></div>
//             <button
//               className={`final-btn ig-btn ${!isRupeeSelected ? "active" : ""}`}
//               onClick={() => handleUnitButtonClick(false)}
//             >
//               In Grams
//             </button>
//           </div>
//         </div>
//         <div className="input-container">
//           <div className="input-border">
//             <div className="input-1">
//               <span className="rupee">{unit}</span>
//             </div>
//             <input
//               type="text"
//               value={isRupeeSelected ? rupeeValue : gramValue}
//               onChange={handleInputChange}
//             />
//             <div className="input-2">
//               <span className="rupee">
//                 {isRupeeSelected ? `${gramValue}gm` : `₹ ${rupeeValue}`}
//               </span>
//             </div>
//           </div>
//         </div>
//         <div className="button-container grid">
//           {["100", "200", "300", "400", "500"].map((amount) => (
//             <button
//               className="btn card-btn"
//               key={amount}
//               onClick={() => handleAmountButtonClick(amount)}
//               style={{ backgroundColor: "#4a2a7d", color: "white" }}
//             >
//               {isRupeeSelected ? `₹ ${amount}` : `${amount}gm`}
//             </button>
//           ))}
//         </div>
//         <div
//           className="mt-5"
//           style={{ Width: "100%", display: "flex", justifyContent: "center" }}
//         >
//           <DropdownButton
//             id="custom-dropdown"
//             title={
//               selectedDay ? `Selected SIP ${selectedDay}` : "Select SIP Date"
//             }
//             onSelect={handleDayClick}
//           >
//             {groupedDays.map((group, index) => (
//               <div key={index} style={{ display: "flex", flexWrap: "wrap" }}>
//                 {group.map((day) => (
//                   <Dropdown.Item
//                     key={day}
//                     eventKey={day.toString()}
//                     active={selectedDay === day.toString()}
//                     style={{
//                       width: "14.28%",
//                       textAlign: "center",
//                     }}
//                   >
//                     {day}
//                   </Dropdown.Item>
//                 ))}
//               </div>
//             ))}
//           </DropdownButton>
//         </div>
//         <div className="bottom-button-container">
//           <button
//             type="button"
//             className="btn card-btn submit-btn"
//             onClick={createSIP}
//             style={{ backgroundColor: "#4a2a7d", color: "white" }}
//           >
//             Create SIP
//           </button>
//         </div>
//       </div>
//     </>
//   );
// };

// export default FinalCreateSip;
