import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";

const FormPart1 = ({ formData, handleChange, nextStep }) => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (
      form.checkValidity() === false ||
      formData.password !== formData.confirmPassword
    ) {
      e.stopPropagation();
    } else {
      nextStep();
    }
    setValidated(true);
  };

  return (
    <div className="registration-form">
      <h2 className="mb-4 center-text">Register as Individual Partner</h2>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="row-bottom">
          <Col md={6}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Full Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                minLength="2"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid name with at least 2 characters.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email address.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="row-bottom">
          <Col md={6}>
            <Form.Group controlId="formDob">
              <Form.Label>DOB</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide your date of birth.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formPhone">
              <Form.Label>Phone no.</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Mobile No"
                name="mobileNo"
                value={formData.mobileNo}
                onChange={handleChange}
                required
                maxLength={10}
                pattern="\d{10}"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid 10-digit phone number.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="row-bottom">
          <Col md={6}>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                minLength={6}
              />
              <Form.Control.Feedback type="invalid">
                Password must be at least 6 characters long.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formConfirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
                minLength="6"
                isInvalid={formData.password !== formData.confirmPassword}
              />
              <Form.Control.Feedback type="invalid">
                {formData.password !== formData.confirmPassword
                  ? "Passwords do not match."
                  : "Please confirm your password."}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-4 text-center" style={{ marginTop: "3rem" }}>
          <p style={{ marginBottom: "3rem" }}>
            Already have an account? <a href="/login">Login</a>
          </p>
          <Button
            className="w-50"
            style={{ backgroundColor: "#4a2a7d", borderRadius: "30px" }}
            type="submit"
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormPart1;

// import React from "react";
// import { Form, Button, Row, Col } from "react-bootstrap";

// const FormPart1 = ({ formData, handleChange, nextStep }) => {
//   return (
//     <div className="registration-form">
//       <h2 className="mb-4 center-text">Register as Individual Partner</h2>
//       <Form>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="formName">
//               <Form.Label>Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Full Name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formEmail">
//               <Form.Label>Email</Form.Label>
//               <Form.Control
//                 type="email"
//                 placeholder="Enter Email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="formDob">
//               <Form.Label>DOB</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="dd-mm-yyyy"
//                 name="dob"
//                 value={formData.dob}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formPhone">
//               <Form.Label>Phone no.</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Mobile No"
//                 name="phone"
//                 value={formData.phone}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="formPassword">
//               <Form.Label>Password</Form.Label>
//               <Form.Control
//                 type="password"
//                 placeholder="Enter password"
//                 name="password"
//                 value={formData.password}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formConfirmPassword">
//               <Form.Label>Confirm Password</Form.Label>
//               <Form.Control
//                 type="password"
//                 placeholder="Confirm password"
//                 name="confirmPassword"
//                 value={formData.confirmPassword}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <div className="mt-4 text-center">
//           <p>
//             Already have an account? <a href="/login">Login</a>
//           </p>
//           <Button
//             className="w-100"
//             style={{ backgroundColor: "#4a2a7d", borderRadius: "30px" }}
//             onClick={nextStep}
//           >
//             Next
//           </Button>
//         </div>
//       </Form>
//     </div>
//   );
// };

// export default FormPart1;
