import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/landing-page/partner-Landing-logo.png";
import playStore from "../assets/landing-page/playStore.png";
import appStore from "../assets/landing-page/appStore.png";

import { CiFacebook } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import { CiInstagram } from "react-icons/ci";

import "./footer.css";

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export default function Footer() {
  return (
    <div style={{ backgroundColor: "#4a2a7d" }}>
      <div
        style={{
          maxWidth: "1240px",
          margin: "auto",
          padding: "2rem 0.5rem",
          color: "white",
        }}
      >
        <div className="footer_main">
          <div className="footer_logo">
            <Link to="/">
              <img src={Logo} width="180" height="auto" alt="batukLogo" />
            </Link>
            <div className="footer_app_logo">
              <Link
                to="https://play.google.com/store/apps/details?id=com.batuk.application&pcampaignid=web_share"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={playStore}
                  width="180"
                  height="auto"
                  alt="playstoreLogo"
                />
              </Link>
              <Link
                to="https://apps.apple.com/in/app/batuk/id6478106976"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appStore}
                  width="180"
                  height="auto"
                  alt="appstoreLogo"
                />
              </Link>
            </div>
          </div>
          {/* CONTACT US==========  */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "white",
            }}
          >
            <h4 style={{ color: "white" }}>Contact Us</h4>
            <span>
              <span style={{ fontWeight: "bold" }}>Head Office:</span> Office No
              2, 3rd floor, A-1, Sector 9,
              <br /> Noida, Uttar Pradesh- 201301
            </span>
            <span>
              <span style={{ fontWeight: "bold" }}>Branch Office:</span> 10/B
              Yunus Building, S.S Gaikwad Marg <br /> Near Metro Cinema, Mumbai,
              400002
            </span>
            <a
              href="tel:+918448695359"
              target="_blank"
              style={{ color: "white", textDecoration: "none" }}
            >
              <span className="animated-link">
                <strong>Phone:</strong> +91 8448695359
              </span>
            </a>
            <a
              href="mailto:connect@batuk.in"
              target="_blank"
              style={{ color: "white", textDecoration: "none" }}
            >
              <span className="animated-link">
                <strong>Email:</strong> connect@batuk.in
              </span>
            </a>
          </div>
          {/* EXPLORE==========  */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "white",
            }}
          >
            <h4>Explore</h4>
            <Link to="/">
              <span className="animated-link">Home</span>
            </Link>
            <Link to="/blog" onClick={scrollToTop}>
              <span className="animated-link">Blogs</span>
            </Link>
          </div>
          {/* RESOURSE==========  */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "white",
            }}
          >
            <h4>Resourse</h4>
            <Link to="/faq" onClick={scrollToTop}>
              <span className="animated-link">FAQ</span>
            </Link>

            <Link to="/shipping-policy" onClick={scrollToTop}>
              <span className="animated-link">Shipping Policy</span>
            </Link>
            <Link to="/terms-conditions" onClick={scrollToTop}>
              <span className="animated-link">Terms & Conditions</span>
            </Link>
            <Link to="/privacy-policy" onClick={scrollToTop}>
              <span className="animated-link">Privacy Policy</span>
            </Link>
          </div>
        </div>
        {/* =========================================================================  */}
        <div className="social_icons_main">
          <h4>Follow us on</h4>
          <div className="social-links">
            <Link
              to="https://www.instagram.com/batukify/"
              target="_blank"
              className="social-link"
            >
              <CiInstagram size={35} color="white" />
            </Link>
            <Link
              to="https://www.facebook.com/profile.php?id=61567131733842&sk"
              target="_blank"
              className="social-link"
            >
              <CiFacebook size={35} color="white" />
            </Link>
            <Link
              to="https://www.linkedin.com/company/bharat-batuk-private-limited/posts/?feedView=all"
              target="_blank"
              className="social-link"
            >
              <CiLinkedin size={35} color="white" />
            </Link>
            <Link
              to="https://x.com/Batukify"
              target="_blank"
              className="social-link"
            >
              <FaXTwitter size={35} color="white" />
            </Link>
          </div>
        </div>
        {/* ================================================================================= */}
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "20px",
            paddingTop: "1rem",
            borderTop: "1px solid white",
          }}
        >
          © Copyright 2024 &nbsp;
          <Link to="/" style={{ fontWeight: "bold", color: "white" }}>
            Bharat Batuk Private Limited &nbsp;
          </Link>
          All rights reserved.
        </div>
      </div>
    </div>
  );
}

// import React from "react";
// import "./footer.css";
// import Logo from "../assets/landing-page/partner-Landing-logo.png";
// import playStore from "../assets/landing-page/playStore.png";
// import appStore from "../assets/landing-page/appStore.png";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFacebookSquare,
//   faTwitterSquare,
//   faInstagram,
//   faYoutubeSquare,
//   faLinkedin,
// } from "@fortawesome/free-brands-svg-icons";

// const Footer = () => {
//   return (
//     <footer className="footer-container">
//       <div className="footer-content">
//         <div className="footer-section logo-contact">
//           <img
//             src={Logo}
//             alt="Batuk Logo"
//             className="footer-logo"
//             style={{ width: "13rem" }}
//           />
//           <p>Install App </p>
//           <div className="footer-contact">
//             <a
//               href="https://play.google.com/store/apps/details?id=com.batuk.application&pcampaignid=web_share"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img src={playStore} alt="Batuk Logo" className="footer-logo" />
//             </a>
//           </div>
//           <div className="footer-contact">
//             <a
//               href="https://apps.apple.com/in/app/batuk/id6478106976"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img src={appStore} alt="Batuk Logo" className="footer-logo" />
//             </a>
//           </div>
//           {/* <div className="footer-address">
//             <span className="footer-sapn">Address:</span>
//             <p className="footer-p" style={{ marginBottom: "0px" }}>
//               Office No 2, 3rd floor, A-1, Sector 9, Noida, Uttar Pradesh-
//               201301
//             </p>
//           </div>
//           <div className="footer-add">
//             <span className="footer-sapn">Phone: </span>
//             <a
//               href="tel:+91844695359"
//               className="footer-p"
//               style={{ marginBottom: "0px", color: "white" }}
//             >
//               +91 844695359
//             </a>
//           </div>
//           <div className="footer-add">
//             <span className="footer-sapn">Email: </span>
//             <a
//               href="mailto:connect@batuk.in"
//               className="footer-p"
//               style={{ marginBottom: "0px", color: "white" }}
//             >
//               connect@batuk.in
//             </a>
//           </div> */}
//         </div>
//         <div className="footer-section links" style={{ paddingTop: "1.5rem" }}>
//           <h4>Address</h4>
//           <div className="footer-address">
//             {/* <span className="footer-sapn">Address 1</span> */}
//             <p className="footer-p" style={{ marginBottom: "0px" }}>
//               --> Office No 2, 3rd floor, <br /> A-1, Sector 9, <br /> Noida,
//               Uttar Pradesh- 201301
//             </p>
//           </div>
//           <div className="footer-address">
//             {/* <span className="footer-sapn">Address 2</span> */}
//             <p className="footer-p" style={{ marginBottom: "0px" }}>
//               --> 10/B Yunus Building, <br /> S.S Gaikwad Marg Near Metro
//               Cinema,
//               <br /> Mumbai, 400002
//             </p>
//           </div>
//           <div className="footer-add">
//             <span className="footer-sapn">Phone: </span>
//             <a
//               href="tel:+91844695359"
//               className="footer-p"
//               style={{ marginBottom: "0px", color: "white" }}
//             >
//               +91 844695359
//             </a>
//           </div>
//           <div className="footer-add">
//             <span className="footer-sapn">Email: </span>
//             <a
//               href="mailto:connect@batuk.in"
//               className="footer-p"
//               style={{ marginBottom: "0px", color: "white" }}
//             >
//               connect@batuk.in
//             </a>
//           </div>

//           {/* <ul>
//             <li>
//               <a href="/home">Home</a>
//             </li>
//             <li>
//               <a href="/about">About Us</a>
//             </li>

//             <li>
//               <a href="/terms-conditions">Terms & Conditions</a>
//             </li>
//             <li>
//               <a href="/privacy-policy">Privacy Policy</a>
//             </li>
//           </ul> */}
//         </div>
//         <div className="footer-section links" style={{ paddingTop: "1.5rem" }}>
//           <h4>Resourse</h4>
//           <ul>
//             <li>
//               <a href="/faq">FAQ</a>
//             </li>
//             <li>
//               <a href="/blog">Blogs</a>
//             </li>
//             <li>
//               <a href="/terms-conditions">Terms & Conditions</a>
//             </li>
//             <li>
//               <a href="/privacy-policy">Privacy Policy</a>
//             </li>
//             <li>
//               <a href="/shipping">Shipping & Returns</a>
//             </li>
//           </ul>
//         </div>
//         {/* <div className="footer-section links" style={{ paddingTop: "1.5rem" }}>
//           <div className="subscribe">
//             <input type="email" placeholder="Enter email" />
//           </div>
//           <button className="sub-button">Subscribe Newslatter</button>
//         </div> */}
//         <div className="footer-section links" style={{ paddingTop: "1.5rem" }}>
//           <h4>Follow us on</h4>
//           <div className="social-icons">
//             <a
//               href="https://www.facebook.com/batukify"
//               title="facebook"
//               target="_blank"
//             >
//               <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
//             </a>
//             <a href="https://x.com/Batukify" title="twitter">
//               <FontAwesomeIcon icon={faTwitterSquare} size="2x" />
//             </a>
//             <a
//               href="https://www.instagram.com/batukify"
//               title="instagram"
//               target="_blank"
//             >
//               <FontAwesomeIcon icon={faInstagram} size="2x" />
//             </a>
//             {/* <a
//               href="https://www.youtube.com/channel/UCva-iZHewRk2J70xv1Uteig?sub_confirmation=1"
//               title="youtube"
//               target="_blank"
//             >
//               <FontAwesomeIcon icon={faYoutubeSquare} size="2x" />
//             </a> */}
//             <a
//               href="https://www.linkedin.com/company/bharat-batuk-private-limited/mycompany/"
//               title="linkedin"
//               target="_blank"
//             >
//               <FontAwesomeIcon icon={faLinkedin} size="2x" />
//             </a>
//           </div>

//           {/* <p style={{ marginTop: "1rem" }}>Install App </p>
//           <div className="footer-contact">
//             <a
//               href="https://play.google.com/store/apps/details?id=com.batuk.application&pcampaignid=web_share"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img src={playStore} alt="Batuk Logo" className="footer-logo" />
//             </a>
//             <a
//               href="https://apps.apple.com/in/app/batuk/id6478106976"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img src={appStore} alt="Batuk Logo" className="footer-logo" />
//             </a>
//           </div> */}
//         </div>
//       </div>
//       <hr />
//       <div className="footer-bottom">
//         <p>
//           © Copyright 2024 Bharat Batuk Private Limited All rights reserved.
//         </p>
//       </div>
//     </footer>
//   );
// };

// export default Footer;
