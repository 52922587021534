// import React, { useState } from "react";
// import { Form, Button, Row, Col } from "react-bootstrap";
// import {
//   CountrySelect,
//   StateSelect,
//   CitySelect,
// } from "react-country-state-city";
// import "react-country-state-city/dist/react-country-state-city.css";
// import axios from "axios";

// const FormPart2 = ({ formData, handleChange, nextStep, prevStep }) => {
//   const [validated, setValidated] = useState(false);
//   const [country, setCountryid] = useState(101);
//   const [state, setStateid] = useState(0);
//   const [city, setCityid] = useState(0);
//   const [otpSent, setOtpSent] = useState(false);
//   const [transId, setTransId] = useState("");

//   console.log(transId, ">>>>>>>>>>>>");

//   const handleCountryChange = (country) => {
//     setCountryid(country.id);
//     setStateid(0);
//     setCityid(0);
//   };

//   const handleStateChange = (state) => {
//     setStateid(state.id);
//     handleChange({ target: { name: "state", value: state.name } });
//     setCityid(0); // Reset city selection when state changes
//   };

//   const handleCityChange = (city) => {
//     setCityid(city.id);
//     handleChange({ target: { name: "city", value: city.name } });
//   };

//   const handleSendOtp = async () => {
//     try {
//       const response = await axios.post(
//         "https://app.batuk.in/partnerPortal/adharVerify",
//         {
//           adharnumber: formData.aadharNo,
//         }
//       );
//       console.log(response, "response send otp");
//       const tsTransId = JSON.parse(response.data.data[0]);
//       console.log(tsTransId, "tsTransId");
//       setTransId(tsTransId);
//       if (response.status === 200) {
//         setOtpSent(true);
//         alert("OTP sent successfully!");
//       } else {
//         alert("Failed to send OTP. Please try again.");
//       }
//     } catch (error) {
//       console.error("Error sending OTP:", error);
//       alert("Failed to send OTP. Please try again.");
//     }
//   };

//   const handleVerifyOtp = async () => {
//     try {
//       const response = await axios.post(
//         "https://app.batuk.in/partnerPortal/verifyAdharOtp",
//         {
//           transId: transId.tsTransId,
//           otp: formData.otp,
//         }
//       );
//       console.log(response, "verification response");
//       if (response.status === 200) {
//         alert("Aadhar verification successful!");
//       } else {
//         alert("Aadhar verification failed. Please try again.");
//       }
//     } catch (error) {
//       console.error("Error verifying Aadhar:", error);
//       alert("Failed to verify Aadhar. Please try again.");
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const form = e.currentTarget;
//     if (form.checkValidity() === false) {
//       e.stopPropagation();
//     } else {
//       if (!otpSent) {
//         await handleSendOtp();
//       } else {
//         await handleVerifyOtp();
//       }
//     }
//     setValidated(true);
//   };

//   return (
//     <div className="registration-form">
//       <h2 className="mb-4 center-text">Complete Your KYC</h2>
//       <Form noValidate validated={validated} onSubmit={handleSubmit}>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="formAddress">
//               <Form.Label>Address</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Your Address"
//                 name="Address"
//                 value={formData.Address}
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formState">
//               <Form.Label>State</Form.Label>
//               <StateSelect
//                 placeHolder="Select State"
//                 countryid={country}
//                 onChange={(state) => handleStateChange(state)}
//                 value={formData.state}
//                 className="form-control"
//                 required
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="formCity">
//               <Form.Label>City</Form.Label>
//               <CitySelect
//                 placeHolder="Select City"
//                 countryid={country}
//                 stateid={state}
//                 onChange={(city) => handleCityChange(city)}
//                 value={formData.city}
//                 className="form-control"
//                 required
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formPincode">
//               <Form.Label>Pincode</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="6-digit Pincode"
//                 name="pincode"
//                 value={formData.pincode}
//                 onChange={handleChange}
//                 required
//                 maxLength={6}
//                 pattern="\d{6}"
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="row-bottom">
//           <Col md={6} style={{ display: "flex" }}>
//             <Col md={7}>
//               <Form.Group controlId="formAadhar">
//                 <Form.Label>Aadhar no.</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter Aadhar no"
//                   name="aadharNo"
//                   value={formData.aadharNo}
//                   onChange={handleChange}
//                   required
//                   maxLength={12}
//                   pattern="\d{12}"
//                 />
//               </Form.Group>
//             </Col>
//             <Col md={2} style={{ marginLeft: "0px" }}>
//               <Button
//                 onClick={otpSent ? handleVerifyOtp : handleSendOtp}
//                 className="mt-4"
//                 type="button"
//                 style={{
//                   borderRadius: "5px",
//                   padding: "8px",
//                   marginTop: "10px",
//                 }}
//               >
//                 {otpSent ? "Verify Aadhar" : "Send OTP"}
//               </Button>
//             </Col>
//           </Col>

//           {otpSent && (
//             <Col md={4}>
//               <Form.Group controlId="formOtp">
//                 <Form.Label>OTP</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter OTP"
//                   name="otp"
//                   value={formData.otp}
//                   onChange={handleChange}
//                   required
//                   maxLength={6}
//                   pattern="\d{6}"
//                 />
//               </Form.Group>
//             </Col>
//           )}
//           <Col md={6}>
//             <Form.Group controlId="formPan">
//               <Form.Label>PAN no.</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter PAN"
//                 name="panNo"
//                 value={formData.panNo}
//                 onChange={handleChange}
//                 required
//                 maxLength={10}
//                 pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <div className="mt-4 text-center">
//           <p style={{ marginBottom: "3rem" }}>
//             Already have an account? <a href="/login">Login</a>
//           </p>
//           <div style={{ display: "flex", justifyContent: "space-around" }}>
//             <Button
//               variant="secondary"
//               className="mr-2 prev-btn"
//               onClick={prevStep}
//             >
//               Previous
//             </Button>
//             <Button
//               className="submit-btn mr-2 prev-btn"
//               style={{
//                 backgroundColor: "#4a2a7d",
//                 borderRadius: "30px",
//               }}
//               type="submit"
//             >
//               Next
//             </Button>
//           </div>
//         </div>
//       </Form>
//     </div>
//   );
// };

// export default FormPart2;

import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import {
  CountrySelect,
  StateSelect,
  CitySelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

const FormPart2 = ({ formData, handleChange, nextStep, prevStep }) => {
  const [validated, setValidated] = useState(false);

  const [country, setCountryid] = useState(101);
  const [state, setStateid] = useState(0);
  const [city, setCityid] = useState(0);

  const handleCountryChange = (country) => {
    setCountryid(country.id);
    setStateid(0);
    setCityid(0);
  };

  const handleStateChange = (state) => {
    setStateid(state.id);
    formData.state = state.name;
    setCityid(0); // Reset city selection when state changes
  };

  const handleCityChange = (city) => {
    setCityid(city.id);
    formData.city = city.name;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      nextStep();
    }
    setValidated(true);
  };

  return (
    <div className="registration-form">
      <h2 className="mb-4 center-text">Complete Your KYC</h2>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="row-bottom">
          <Col md={6}>
            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Your Address"
                name="Address"
                value={formData.Address}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formState">
              <Form.Label>State</Form.Label>
              <StateSelect
                placeHolder="Select State"
                countryid={country}
                onChange={(state) => handleStateChange(state)}
                value={formData.state}
                className="form-control"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="row-bottom">
          <Col md={6}>
            <Form.Group controlId="formCity">
              <Form.Label>City</Form.Label>
              <CitySelect
                placeHolder="Select City"
                countryid={country}
                stateid={state}
                onChange={(city) => handleCityChange(city)}
                value={formData.city}
                className="form-control"
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formPincode">
              <Form.Label>Pincode</Form.Label>
              <Form.Control
                type="text"
                placeholder="6-digit Pincode"
                name="pincode"
                value={formData.pincode}
                onChange={handleChange}
                required
                maxLength={6}
                pattern="\d{6}"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="row-bottom">
          <Col md={6}>
            <Form.Group controlId="formAadhar">
              <Form.Label>Aadhar no.</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Aadhar no"
                name="aadharNo"
                value={formData.aadharNo}
                onChange={handleChange}
                required
                maxLength={12}
                pattern="\d{12}"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formPan">
              <Form.Label>PAN no.</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter PAN"
                name="panNo"
                value={formData.panNo}
                onChange={handleChange}
                required
                maxLength={10}
                pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-4 text-center">
          <p style={{ marginBottom: "3rem" }}>
            Already have an account? <a href="/login">Login</a>
          </p>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              variant="secondary"
              className="mr-2 prev-btn"
              onClick={prevStep}
            >
              Previous
            </Button>
            <Button
              className="submit-btn mr-2 prev-btn"
              style={{
                backgroundColor: "#4a2a7d",
                borderRadius: "30px",
              }}
              type="submit"
            >
              Next
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FormPart2;

// import React from "react";
// import { Form, Button, Row, Col } from "react-bootstrap";

// const FormPart2 = ({ formData, handleChange, nextStep, prevStep }) => {
//   return (
//     <div className="registration-form">
//       <h2 className="mb-4 center-text">Complete Your KYC</h2>
//       <Form>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="formAddress">
//               <Form.Label>Address</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Your Address"
//                 name="address"
//                 value={formData.address}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formCity">
//               <Form.Label>City</Form.Label>
//               <Form.Control
//                 as="select"
//                 name="city"
//                 value={formData.city}
//                 onChange={handleChange}
//               >
//                 <option value="">Select City</option>
//                 <option value="City1">City1</option>
//                 <option value="City2">City2</option>
//               </Form.Control>
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="formState">
//               <Form.Label>State</Form.Label>
//               <Form.Control
//                 as="select"
//                 name="state"
//                 value={formData.state}
//                 onChange={handleChange}
//               >
//                 <option value="">Select State</option>
//                 <option value="State1">State1</option>
//                 <option value="State2">State2</option>
//               </Form.Control>
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formPincode">
//               <Form.Label>Pincode</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="6-digit Pincode"
//                 name="pincode"
//                 value={formData.pincode}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="formAadhar">
//               <Form.Label>Aadhar no.</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Aadhar no"
//                 name="aadhar"
//                 value={formData.aadhar}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formPan">
//               <Form.Label>PAN no.</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter PAN"
//                 name="pan"
//                 value={formData.pan}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <div className="mt-4 text-center">
//           <p>
//             Already have an account? <a href="/login">Login</a>
//           </p>
//           <Button
//             variant="secondary"
//             className="mr-2 prev-btn"
//             onClick={prevStep}
//           >
//             Previous
//           </Button>
//           <Button
//             className="submit-btn"
//             style={{ backgroundColor: "#4a2a7d", borderRadius: "30px" }}
//             onClick={nextStep}
//           >
//             Next
//           </Button>
//         </div>
//       </Form>
//     </div>
//   );
// };

// export default FormPart2;
