import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import moment from "moment/moment";
import {
  Delete as DeleteIcon,
  GroupAdd as GroupAddIcon,
  RemoveRedEye,
} from "@mui/icons-material";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import { ToastContainer, toast } from "react-toastify";
import { baseUrl } from "../../utils/baseUrl";
import TableSkeleton from "../../utils/TableSkeleton";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("proposal_no", {
    header: "Order Number",
    size: 40,
  }),
  columnHelper.accessor("clientName", {
    header: "Client",
    size: 40,
    Cell: (cell) => cell.row.original.client_id.name,
  }),
  columnHelper.accessor("metal_type", {
    header: "Metal Type",
    size: 40,
  }),
  columnHelper.accessor("id", {
    header: "Link",
    size: 40,
    Cell: (cell) => (
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(
            `${baseUrl}/partnerPortal/sip/proposal/link/${cell.row.original.proposal_no}`
          );
          toast.success("Link copied to clipboard!");
        }}
      >
        <ContentCopyIcon />
      </IconButton>
    ),
  }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 40,
    Cell: (cell) => (
      <span
        style={{
          backgroundColor: "rgb(74 42 125)",
          padding: " 7px 14px",
          borderRadius: "12px",
          fontSize: "13px",
          color: "white",
        }}
      >
        {cell.row.original.status}
      </span>
    ),
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    size: 40,
    Cell: (cell) => "₹ " + cell.row.original.amount,
  }),
  columnHelper.accessor("createdAt", {
    header: "Created At",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {moment(cell.row.original.createdAt.split("T")[0]).format("DD-MM-YYYY")}
      </div>
    ),
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});
const SipInProgress = () => {
  const [metal_type, setMetalType] = useState("");
  const [loading, setLoading] = useState(true);
  const token = sessionStorage.getItem("token");
  const [progressData, setprogressData] = useState([]);
  const [quantity, setquantity] = useState("");
  const [amount, setAmount] = useState("");
  const [editModalIsOpen, seteditModalIsOpen] = useState(false);
  const [purposalDataSpecific, setpurposalDataSpecific] = useState(null);

  useEffect(() => {
    GetInprogressClient();
  }, []);

  const GetInprogressClient = async () => {
    try {
      const url = `${baseUrl}/partnerPortal/sip/listInProgress`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const SipInProgress = response.data.data;
      setLoading(false);
      setprogressData(SipInProgress);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      proposal_no: row.original.proposal_no,
      clientName: row.original.client_id.name,
      metal_type: row.original.metal_type,
      amount: row.original.amount,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const rowData = progressData.map((data) => ({
      proposal_no: data.proposal_no,
      clientName: data.client_id.name,
      metal_type: data.metal_type,
      amount: data.amount,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };
  const table = useMaterialReactTable({
    columns,
    data: progressData,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ cell, row, table }) => [
      <Box>
        <IconButton color="error" onClick={() => deleteSIP(row.original)}>
          <DeleteIcon color="error" />
        </IconButton>
      </Box>,
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          Export All Data
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });
  const editPurposal = async (row) => {
    setpurposalDataSpecific(row);

    setMetalType(row.metal_type);
    setquantity(row.quantity);
    setAmount(row.amount);
    seteditModalIsOpen(true);
  };

  const handleSave = async () => {
    if (purposalDataSpecific !== null) {
      try {
        const url = `${baseUrl}/proposalUpdate/${purposalDataSpecific._id}`;
        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.post(
          url,
          { metal_type: metal_type },
          { headers }
        );
        if (response.status === 200) {
          Swal.fire({
            title: "Thank You!",
            text: "Purposal updated Successfully!",
            icon: "success",
          });

          response.data.data?.map((item) => {
            setMetalType(item.metal_type);
            setquantity(item.quantity);
            setAmount(item.amount);
          });
          GetInprogressClient();
          seteditModalIsOpen(false);
        }
      } catch (error) {
        console.error("Error connecting to API", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="#">Why do I have this issue?</a>',
        });
      }
    } else {
      console.log("purposal data is none");
    }
  };

  const deleteSIP = async (row) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${baseUrl}/partnerPortal/sip/proposal/delete/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          GetInprogressClient();
        } catch (error) {
          console.error("Error connecting to API", error);
        }
        swalWithBootstrapButtons.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",

          customClass: {
            confirmButton: "my-custom-button-class",
          },
        });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your imaginary file is safe :)",
          icon: "error",
        });
      }
    });
  };

  return (
    <>
      <ToastContainer />
      {loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}
    </>
  );
};

export default SipInProgress;
