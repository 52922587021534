import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Skeleton,
  Button,
} from "@mui/material";

const ProductCard = () => {
  return (
    <Card style={{ width: 200, margin: "10px", textAlign: "center" }}>
      <CardMedia>
        <Skeleton variant="rectangular" width="100%" height={140} />
      </CardMedia>

      <CardContent>
        <Typography variant="h6">
          <Skeleton width="60%" />
        </Typography>
        <Typography variant="h6" color="textSecondary">
          <Skeleton width="40%" />
        </Typography>
      </CardContent>
      <Button
        disabled
        variant="rectangular"
        color="textSecondary"
        sx={{ width: "100%" }}
      >
        <Skeleton width="100%" />
      </Button>
    </Card>
  );
};

export default ProductCard;
