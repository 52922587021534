import React, { useState, useEffect } from "react";
import axios from "axios";
import GOLD from "../assets/Icons/gold_shield.png";
import SILVER from "../assets/Icons/silver_shield.png";
import "../assets/css/final_create.css";
import { baseUrl } from "../utils/baseUrl";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../components/Loader";

const FinalCreate = () => {
  const clientData = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const Token = sessionStorage.getItem("token");
  const location = useLocation();
  const navigate = useNavigate();
  let client = location.state?.client;

  const [selectedMetal, setSelectedMetal] = useState("gold");
  const [isGoldSelected, setIsGoldSelected] = useState(true);
  const [isRupeeSelected, setIsRupeeSelected] = useState(true);
  const [rupeeValue, setRupeeValue] = useState("");
  const [gramValue, setGramValue] = useState("");
  const [goldPrice, setGoldPrice] = useState("");
  const [silverPrice, setSilverPrice] = useState("");
  const [clientId, setClientId] = useState(client._id);
  const [timer, setTimer] = useState(180);

  useEffect(() => {
    if (isGoldSelected) {
      getGoldLive();
    } else {
      getSilverLive();
    }
  }, [isGoldSelected]);

  useEffect(() => {
    if (clientData && clientData.client && clientData.client._id) {
      setClientId(clientData.client._id);
    }
  }, [clientData]);

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer]);

  const getGoldLive = async () => {
    try {
      const url = `${baseUrl}/liveprice/gold`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const liveValue = response.data.data;
      console.log(response, "goldddddddddddddddd");
      setGoldPrice(liveValue);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const getSilverLive = async () => {
    try {
      const url = `${baseUrl}/liveprice/silver`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const liveValue = response.data.data;
      console.log(response, "silverrrrrrrrrrrrrrrr");
      setSilverPrice(liveValue);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const createPurposal = async () => {
    setLoading(true);
    const weight = isRupeeSelected
      ? (rupeeValue / (isGoldSelected ? goldPrice : silverPrice)).toFixed(4)
      : gramValue;
    const amount = isRupeeSelected
      ? rupeeValue
      : (gramValue * (isGoldSelected ? goldPrice : silverPrice)).toFixed(2);

    let data = {
      metal_type: selectedMetal,
    };

    if (isRupeeSelected) {
      data.amount = amount;
    } else {
      data.quantity = weight;
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/proposal_create/${clientId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
      data: JSON.stringify(data),
    };

    axios
      .request(config)
      .then((response) => {
        return response.data;
      })
      .then((d) => {
        if (d.message === "The proposal was created successfully") {
          setLoading(false);
          Swal.fire({
            title: "Thank You!",
            text: "The proposal was created successfully!",
            icon: "success",
          });
          navigate("/dashboard/proposal");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: d.message,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleMetalButtonClick = (isGold) => {
    setIsGoldSelected(isGold);
    setSelectedMetal(isGold ? "gold" : "silver");
    setTimer(180); // Reset the timer to 3 minutes
    if (isGold) {
      getGoldLive();
    } else {
      getSilverLive();
    }
  };

  const handleUnitButtonClick = (isRupee) => {
    setIsRupeeSelected(isRupee);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const gstRate = 1.03;
    if (isRupeeSelected) {
      setRupeeValue(value);
      setGramValue(
        value
          ? (
              value /
              gstRate /
              (isGoldSelected ? goldPrice : silverPrice)
            ).toFixed(4)
          : ""
      );
    } else {
      setGramValue(value);
      setRupeeValue(
        value
          ? (
              value *
              (isGoldSelected ? goldPrice : silverPrice) *
              gstRate
            ).toFixed(2)
          : ""
      );
    }
  };

  const handleAmountButtonClick = (amount) => {
    const value = amount.replace("₹", "");
    const gstRate = 1.03;
    if (isRupeeSelected) {
      setRupeeValue(value);
      setGramValue(
        value
          ? (
              value /
              gstRate /
              (isGoldSelected ? goldPrice : silverPrice)
            ).toFixed(4)
          : ""
      );
    } else {
      setGramValue(value);
      setRupeeValue(
        value
          ? (
              value *
              (isGoldSelected ? goldPrice : silverPrice) *
              gstRate
            ).toFixed(2)
          : ""
      );
    }
  };

  const metal = isGoldSelected ? "GOLD" : "SILVER";
  const metalPrice = isGoldSelected ? `₹${goldPrice}/gm` : `₹${silverPrice}/gm`;
  const metalImage = isGoldSelected ? GOLD : SILVER;
  const unit = isRupeeSelected ? "₹" : "gm";
  const metalTag = isGoldSelected ? "24K 99.9% Pure Gold" : "999.9 Purity";

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <div className={loading ? "blurred" : ""}>
      {loading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <header className="header-container">
        <div className="button-group">
          <button
            className={`final-btn gold-btn gl ${
              isGoldSelected ? "active" : ""
            }`}
            onClick={() => handleMetalButtonClick(true)}
          >
            Gold
          </button>
          <button
            className={`final-btn silver-btn gl ${
              !isGoldSelected ? "active" : ""
            }`}
            onClick={() => handleMetalButtonClick(false)}
          >
            Silver
          </button>
        </div>
      </header>
      <div className="bordered-div">
        <div className="inner-container">
          <div className="inner-div">
            <button
              className="btn card-btn"
              style={{ backgroundColor: "#4a2a7d", color: "white" }}
            >
              {metal}
            </button>
            <h3>{`${metal} PRICE`}</h3>
            <div className="paragst">
              <p className="medium-text">{metalPrice}</p>
              <p className="gst">+3% GST</p>
            </div>
            <p className="mini-text">{metalTag}</p>
          </div>
          <div className="inner-div hide-text">Div 2</div>
          <div className="inner-div">
            <div
              className=""
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={metalImage}
                alt={`${metal} image`}
                style={{ width: "110px", marginBottom: "1rem" }}
              />
            </div>

            <div className="timer">
              <p className="mini-text">{`${metal} rate expires in :`}</p>
              <p className="time">{formatTime(timer)}</p>
            </div>
          </div>
        </div>
        <div className="button-row-bottom">
          <div className="irg">
            <button
              className={`final-btn ir-btn ${isRupeeSelected ? "active" : ""}`}
              onClick={() => handleUnitButtonClick(true)}
            >
              In Rupee
            </button>
            <div className="vl"></div>
            <button
              className={`final-btn ig-btn ${!isRupeeSelected ? "active" : ""}`}
              onClick={() => handleUnitButtonClick(false)}
            >
              In Grams
            </button>
          </div>
        </div>
        <div className="input-container">
          <div className="input-border">
            <div className="input-1">
              <span className="rupee">{unit}</span>
            </div>
            <input
              type="text"
              value={isRupeeSelected ? rupeeValue : gramValue}
              onChange={handleInputChange}
              style={{ width: "100%" }}
            />
            <div className="input-2" style={{ width: "100%" }}>
              <span className="rupee">
                {isRupeeSelected ? `${gramValue}gm` : `₹ ${rupeeValue}`}
              </span>
            </div>
          </div>
        </div>
        <div className="button-container grid">
          {["100", "200", "300", "400", "500"].map((amount) => (
            <button
              className="btn card-btn"
              key={amount}
              onClick={() => handleAmountButtonClick(amount)}
              style={{ backgroundColor: "#4a2a7d", color: "white" }}
            >
              {isRupeeSelected ? `₹ ${amount}` : `${amount}gm`}
            </button>
          ))}
        </div>
        <div className="button-container">
          <button
            type="button"
            className="btn card-btn submit-btn"
            onClick={createPurposal}
            style={{
              backgroundColor: "#4a2a7d",
              color: "white",
              width: "10rem",
              marginBottom: "0px",
            }}
          >
            Create Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinalCreate;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import GOLD from "../assets/Icons/Gold.png";
// import SILVER from "../assets/Icons/Silver (1).png";
// import "../assets/css/final_create.css";
// import { baseUrl } from "../utils/baseUrl";
// import { useSelector } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import Loader from "../components/Loader";

// const FinalCreate = () => {
//   const clientData = useSelector((state) => state);
//   const [loading, setLoading] = useState(true);
//   const Token = sessionStorage.getItem("token");
//   const location = useLocation();
//   const navigate = useNavigate();
//   let client = location.state?.client;

//   const [selectedMetal, setSelectedMetal] = useState("gold");
//   const [isGoldSelected, setIsGoldSelected] = useState(true);
//   const [isRupeeSelected, setIsRupeeSelected] = useState(true);
//   const [rupeeValue, setRupeeValue] = useState("");
//   const [gramValue, setGramValue] = useState("");
//   const [goldPrice, setGoldPrice] = useState("");
//   const [silverPrice, setSilverPrice] = useState("");
//   const [clientId, setClientId] = useState(client._id);
//   const [timer, setTimer] = useState(180);

//   useEffect(() => {
//     if (isGoldSelected) {
//       getGoldLive();
//     } else {
//       getSilverLive();
//     }
//   }, [isGoldSelected]);

//   useEffect(() => {
//     if (clientData && clientData.client && clientData.client._id) {
//       setClientId(clientData.client._id);
//     }
//   }, [clientData]);

//   useEffect(() => {
//     let countdown;
//     if (timer > 0) {
//       countdown = setInterval(() => {
//         setTimer((prevTimer) => prevTimer - 1);
//       }, 1000);
//     } else {
//       clearInterval(countdown);
//     }
//     return () => clearInterval(countdown);
//   }, [timer]);

//   const getGoldLive = async () => {
//     try {
//       const url = `${baseUrl}/liveprice/gold`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const liveValue = response.data.data;
//       console.log(response, "goldddddddddddddddd");
//       setGoldPrice(liveValue);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const getSilverLive = async () => {
//     try {
//       const url = `${baseUrl}/liveprice/silver`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const liveValue = response.data.data;
//       console.log(response, "silverrrrrrrrrrrrrrrr");
//       setSilverPrice(liveValue);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const createPurposal = async () => {
//     setLoading(true);
//     const weight = isRupeeSelected
//       ? (rupeeValue / (isGoldSelected ? goldPrice : silverPrice)).toFixed(4)
//       : gramValue;
//     const amount = isRupeeSelected
//       ? rupeeValue
//       : (gramValue * (isGoldSelected ? goldPrice : silverPrice)).toFixed(2);

//     let data = {
//       metal_type: selectedMetal,
//     };

//     if (isRupeeSelected) {
//       data.amount = amount;
//     } else {
//       data.quantity = weight;
//     }

//     let config = {
//       method: "post",
//       maxBodyLength: Infinity,
//       url: `${baseUrl}/proposal_create/${clientId}`,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${Token}`,
//       },
//       data: JSON.stringify(data),
//     };

//     axios
//       .request(config)
//       .then((response) => {
//         return response.data;
//       })
//       .then((d) => {
//         if (d.message === "The proposal was created successfully") {
//           setLoading(false);
//           Swal.fire({
//             title: "Thank You!",
//             text: "The proposal was created successfully!",
//             icon: "success",
//           });
//           navigate("/dashboard/proposal");
//         } else {
//           Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: d.message,
//           });
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const handleMetalButtonClick = (isGold) => {
//     setIsGoldSelected(isGold);
//     setSelectedMetal(isGold ? "gold" : "silver");
//     setTimer(180); // Reset the timer to 3 minutes
//     if (isGold) {
//       getGoldLive();
//     } else {
//       getSilverLive();
//     }
//   };

//   const handleUnitButtonClick = (isRupee) => {
//     setIsRupeeSelected(isRupee);
//   };

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     const gstRate = 1.03;
//     if (isRupeeSelected) {
//       setRupeeValue(value);
//       setGramValue(
//         value
//           ? (
//               value /
//               gstRate /
//               (isGoldSelected ? goldPrice : silverPrice)
//             ).toFixed(4)
//           : ""
//       );
//     } else {
//       setGramValue(value);
//       setRupeeValue(
//         value
//           ? (
//               value *
//               (isGoldSelected ? goldPrice : silverPrice) *
//               gstRate
//             ).toFixed(2)
//           : ""
//       );
//     }
//   };

//   const handleAmountButtonClick = (amount) => {
//     const value = amount.replace("₹", "");
//     const gstRate = 1.03;
//     if (isRupeeSelected) {
//       setRupeeValue(value);
//       setGramValue(
//         value
//           ? (
//               value /
//               gstRate /
//               (isGoldSelected ? goldPrice : silverPrice)
//             ).toFixed(4)
//           : ""
//       );
//     } else {
//       setGramValue(value);
//       setRupeeValue(
//         value
//           ? (
//               value *
//               (isGoldSelected ? goldPrice : silverPrice) *
//               gstRate
//             ).toFixed(2)
//           : ""
//       );
//     }
//   };

//   const metal = isGoldSelected ? "GOLD" : "SILVER";
//   const metalPrice = isGoldSelected ? `₹${goldPrice}/gm` : `₹${silverPrice}/gm`;
//   const metalImage = isGoldSelected ? GOLD : SILVER;
//   const unit = isRupeeSelected ? "₹" : "gm";
//   const metalTag = isGoldSelected ? "24K 99.9% Pure Gold" : "999.9 Purity";

//   const formatTime = (seconds) => {
//     const minutes = Math.floor(seconds / 60);
//     const secs = seconds % 60;
//     return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
//   };

//   return (
//     <div>
//       {loading ? (
//         <Loader />
//       ) : (
//         <>
//           <header className="header-container">
//             <div className="button-group">
//               <button
//                 className={`final-btn gold-btn gl ${
//                   isGoldSelected ? "active" : ""
//                 }`}
//                 onClick={() => handleMetalButtonClick(true)}
//               >
//                 Gold
//               </button>
//               <button
//                 className={`final-btn silver-btn gl ${
//                   !isGoldSelected ? "active" : ""
//                 }`}
//                 onClick={() => handleMetalButtonClick(false)}
//               >
//                 Silver
//               </button>
//             </div>
//           </header>
//           <div className="bordered-div">
//             <div className="inner-container">
//               <div className="inner-div">
//                 <button
//                   className="btn card-btn"
//                   style={{ backgroundColor: "#4a2a7d", color: "white" }}
//                 >
//                   {metal}
//                 </button>
//                 <h3>{`${metal} PRICE`}</h3>
//                 <div className="paragst">
//                   <p className="medium-text">{metalPrice}</p>
//                   <p className="gst">+3% GST</p>
//                 </div>
//                 <p className="mini-text">{metalTag}</p>
//               </div>
//               <div className="inner-div hide-text">Div 2</div>
//               <div className="inner-div">
//                 <div
//                   className=""
//                   style={{
//                     width: "100%",
//                     display: "flex",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <img
//                     src={metalImage}
//                     alt={`${metal} image`}
//                     style={{ width: "110px", marginBottom: "1rem" }}
//                   />
//                 </div>

//                 <div className="timer">
//                   <p className="mini-text">{`${metal} rate expires in :`}</p>
//                   <p className="time">{formatTime(timer)}</p>
//                 </div>
//               </div>
//             </div>
//             <div className="button-row-bottom">
//               <div className="irg">
//                 <button
//                   className={`final-btn ir-btn ${
//                     isRupeeSelected ? "active" : ""
//                   }`}
//                   onClick={() => handleUnitButtonClick(true)}
//                 >
//                   In Rupee
//                 </button>
//                 <div className="vl"></div>
//                 <button
//                   className={`final-btn ig-btn ${
//                     !isRupeeSelected ? "active" : ""
//                   }`}
//                   onClick={() => handleUnitButtonClick(false)}
//                 >
//                   In Grams
//                 </button>
//               </div>
//             </div>
//             <div className="input-container">
//               <div className="input-border">
//                 <div className="input-1">
//                   <span className="rupee">{unit}</span>
//                 </div>
//                 <input
//                   type="text"
//                   value={isRupeeSelected ? rupeeValue : gramValue}
//                   onChange={handleInputChange}
//                   style={{ width: "100%" }}
//                 />
//                 <div className="input-2" style={{ width: "100%" }}>
//                   <span className="rupee">
//                     {isRupeeSelected ? `${gramValue}gm` : `₹ ${rupeeValue}`}
//                   </span>
//                 </div>
//               </div>
//             </div>
//             <div className="button-container grid">
//               {["100", "200", "300", "400", "500"].map((amount) => (
//                 <button
//                   className="btn card-btn"
//                   key={amount}
//                   onClick={() => handleAmountButtonClick(amount)}
//                   style={{ backgroundColor: "#4a2a7d", color: "white" }}
//                 >
//                   {isRupeeSelected ? `₹ ${amount}` : `${amount}gm`}
//                 </button>
//               ))}
//             </div>
//             <div className="button-container">
//               <button
//                 type="button"
//                 className="btn card-btn submit-btn"
//                 onClick={createPurposal}
//                 style={{
//                   backgroundColor: "#4a2a7d",
//                   color: "white",
//                   width: "10rem",
//                   marginBottom: "0px",
//                 }}
//               >
//                 Create Order
//               </button>
//             </div>
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default FinalCreate;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import GoldLogo from "../images/agmond_gold_logo01.png";

// import SilverLogo from "../images/agmond_gold_logo02.png";
// import Accordion from "@mui/material/Accordion";
// import AccordionActions from "@mui/material/AccordionActions";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Button from "@mui/material/Button";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import { useLocation } from "react-router-dom";

// import Swal from "sweetalert2";
// import {
//   FormControl,
//   FormControlLabel,
//   FormLabel,
//   Radio,
//   RadioGroup,
// } from "@mui/material";
// import { styled } from "@mui/material/styles";
// import { useSelector } from "react-redux";
// import { baseUrl } from "../utils/baseUrl";

// const FinalCreate = () => {
//   const clientData = useSelector((state) => state);
//   const Token = sessionStorage.getItem("token");
//   const location = useLocation();
//   const navigate = useNavigate();
//   let client = location.state?.client;
//   console.log(client, "client=====================");

//   const [selectedMetal, setSelectedMetal] = useState("gold"); // default selected metal is gold
//   const [goldPrice, setGoldPrice] = useState("");
//   const [silverPrice, setSilverPrice] = useState("");
//   const [amountValue, setAmountValue] = useState("");
//   const [amountInputEmpty, setAmountInputEmpty] = useState(true);
//   const [weightInputEmpty, setWeightInputEmpty] = useState(true);
//   const [clientId, setclientId] = useState(client._id);
//   useEffect(() => {
//     getLivePrices();
//   }, []);

//   const getLivePrices = async () => {
//     await getGoldLive();
//     await getSilverLive();
//   };

//   const getGoldLive = async () => {
//     try {
//       const url = `${baseUrl}/liveprice/gold`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.get(url, { headers });
//       console.log("gold ptice api", response);
//       const liveValue = response.data.data;
//       setGoldPrice(liveValue);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const getSilverLive = async () => {
//     try {
//       const url = `${baseUrl}/liveprice/silver`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.get(url, { headers });

//       const liveValue = response.data.data;
//       setSilverPrice(liveValue);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const handleInputChange = (inputId) => {
//     const num1 = parseFloat(document.getElementById("num1").value);
//     const num2 = parseFloat(document.getElementById("num2").value);
//     const num3 = parseFloat(document.getElementById("num3").value);

//     if (inputId === "num1") {
//       const result = isNaN(num1) || isNaN(num2) ? "" : num2 / num1;
//       document.getElementById("num3").value = result;
//     }
//     if (inputId === "num3") {
//       const amount = isNaN(num3) || isNaN(num1) ? "" : num3 * num1;
//       var amountFormatted = amount === "" ? "" : amount.toFixed(2);
//       document.getElementById("num2").value = amountFormatted;
//     } else if (inputId === "num2") {
//       setAmountValue(num2);
//       var weight =
//         isNaN(num2) || isNaN(num1) || num1 === 0 || num2 === ""
//           ? ""
//           : num2 / num1;
//       var weightFormatted = weight === "" ? "" : weight.toFixed(4);
//       document.getElementById("num3").value = weightFormatted;
//     }
//     if (inputId === "num2" || inputId === "num3") {
//       const inputValue = document.getElementById(inputId).value;
//       const sanitizedValue = inputValue.replace(/[^0-9.]/g, "");
//       const parts = sanitizedValue.split(".");
//       document.getElementById(inputId).value = parts.join(".");
//     }

//     if (inputId === "num2") {
//       setAmountInputEmpty(num2 === "");
//     } else if (inputId === "num3") {
//       setWeightInputEmpty(num3 === "");
//     }
//   };

//   // const createPurposal = async () => {
//   //   console.log(selectedMetal);
//   //   const weight = document.getElementById("num3").value;
//   //   console.log(`https://app.batuk.in/proposal_create/${clientId}`);
//   //   try {
//   //     const url = `https://app.batuk.in/proposal_create/${clientId}`;
//   //     const headers = {
//   //       "Content-Type": "application/json",
//   //       Accept: "application/json",
//   //       Authorization: `Bearer ${Token}`,
//   //     };
//   //     const body = {
//   //       metal_type: selectedMetal,
//   //       quantity: weight,
//   //       amount: amountValue,
//   //     };
//   //     console.log("aftetr url 1");

//   //     const response = await axios.post(
//   //       url,
//   //       {
//   //         metal_type: goldPrice ? goldPrice : silverPrice,
//   //         quantity: weight,
//   //         amount: amountValue,
//   //       },
//   //       { headers }
//   //     );
//   //     console.log("aftetr url");

//   //     console.log("body", body);
//   //     console.log("response of create purposal", response);
//   //   } catch (error) {
//   //     console.error("Error creating purposal to API", error);
//   //   }
//   // };

//   const createPurposal3 = async () => {
//     const weight = document.getElementById("num3").value;
//     let data = JSON.stringify({
//       metal_type: selectedMetal,
//       amount: amountValue,
//       quantity: weight,
//     });
//     console.log({
//       _id: clientId,
//       _id: Token,
//     });
//     let config = {
//       method: "post",
//       maxBodyLength: Infinity,
//       url: `${baseUrl}/proposal_create/${clientId}`,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${Token}`,
//       },
//       data: data,
//     };

//     axios
//       .request(config)
//       .then((response) => {
//         return JSON.stringify(response.data);
//       })
//       .then((d) => {
//         navigate();
//         console.log(d.json());
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const createPurposal = async () => {
//     const weight = document.getElementById("num3").value;
//     let data = JSON.stringify({
//       metal_type: selectedMetal,
//       amount: amountValue,
//       quantity: weight,
//     });

//     console.log({
//       _id: clientId,
//       _id: Token,
//     });

//     let config = {
//       method: "post",
//       maxBodyLength: Infinity,
//       url: `${baseUrl}/proposal_create/${clientId}`,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${Token}`,
//       },
//       data: data,
//     };

//     axios
//       .request(config)
//       .then((response) => {
//         return response.data;
//       })
//       .then((d) => {
//         console.log(d);
//         if (d.message == "The proposal was created successfully") {
//           Swal.fire({
//             title: "Thank You!",
//             text: "The proposal was created successfully!",
//             icon: "success",
//           });
//           navigate("/proposal");
//         } else {
//           Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: "Please complete the KYC.",
//           });
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   return (
//     <div className="container-fluid" style={{ color: "#880E4F" }}>
//       <div
//         className="row"
//         style={{ display: "flex", justifyContent: "center" }}
//       >
//         <div
//           className="col-md-8 card"
//           style={{
//             borderRadius: "10px",
//             boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)",
//             padding: "25px 34px",
//           }}
//         >
//           <div className="row">
//             <div
//               className="col-md-6 mb-2"
//               style={{
//                 display: "flex",
//                 justifyContent: "flex-start",
//                 alignItems: "center",
//               }}
//             >
//               {/* <h4 style={{ color: "#4a2a7d" }}>Name : {client.name}</h4> */}
//               <span className="form-label" style={{ color: "#4a2a7d" }}>
//                 Client Name : {client.name}
//               </span>
//             </div>
//             <div className="col-md-6 d-flex justify-content-end">
//               <div className="div">
//                 {/* ========================================================== */}
//                 <div className="row">
//                   <div className="col-sm-6">
//                     <RadioGroup
//                       aria-labelledby="demo-radio-buttons-group-label"
//                       defaultValue="female"
//                       name="radio-buttons-group"
//                     >
//                       <FormControlLabel
//                         value="gold"
//                         control={<Radio />}
//                         label="Gold"
//                         name="live"
//                         id="gold"
//                         checked={selectedMetal === "gold"}
//                         onChange={() => setSelectedMetal("gold")}
//                       />
//                     </RadioGroup>
//                   </div>
//                   <div className="col-sm-6">
//                     <RadioGroup
//                       aria-labelledby="demo-radio-buttons-group-label"
//                       defaultValue="female"
//                       name="radio-buttons-group"
//                     >
//                       <FormControlLabel
//                         value="silver"
//                         control={<Radio />}
//                         label="Silver"
//                         checked={selectedMetal === "silver"}
//                         onChange={() => setSelectedMetal("silver")}
//                       />
//                     </RadioGroup>
//                   </div>
//                 </div>

//                 {/* '===============================================' */}
//               </div>
//             </div>
//           </div>

//           {selectedMetal === "gold" && (
//             <div
//               className="row mt-2"
//               style={{
//                 borderRadius: "10px",
//                 border: "2px solid #E3B041",
//                 color: "#4A2A7D",
//                 padding: "20px 15px",
//               }}
//             >
//               <div className="row d-flex justify-content-between">
//                 <div
//                   className="col-md-1 d-flex justify-content-start my-auto p-0"
//                   style={{ height: "auto" }}
//                 >
//                   <img
//                     src={GoldLogo}
//                     className="img-fluid p-0 mx-2"
//                     style={{ height: "auto", width: "150px" }}
//                   />
//                 </div>
//                 <div className="col-md-4 p-2 text-center">
//                   <h5 className="fw-bold">AUGMONT Gold</h5>
//                   <h6>24k 999.9 Purity</h6>
//                 </div>
//                 <div className="col-sm-2 col-2 d-flex align-items-center">
//                   {/* <div className="col-sm-2 col-2  d-flex align-items-center justify-content-center"> */}
//                   {/* &#8377;&nbsp; */}
//                   {console.log("goldPrice", goldPrice)}
//                   ₹
//                   <input
//                     type="text"
//                     id="num1"
//                     value={goldPrice}
//                     onInput={() => handleInputChange("num1")}
//                     className="live fw-bold"
//                     // style={{
//                     //   width: "100px",
//                     //   border: "none",
//                     //   background: "transparent",
//                     //   outline: "none",
//                     //   color: "#4a2a7d",
//                     // }}
//                     style={{
//                       width: "80px",
//                       border: "none",
//                       background: "transparent",
//                       outline: "none",
//                       color: "#4A2A7D",
//                     }}
//                     readOnly
//                   />
//                 </div>
//                 <div className="col-md-2 mt-4 ">
//                   <h6
//                     className=""
//                     style={{ color: "#4A2A7D", fontSize: "20px" }}
//                   >
//                     <span className="blinking-circle"></span> &nbsp;Live
//                   </h6>
//                 </div>
//               </div>
//             </div>
//           )}

//           {selectedMetal === "silver" && (
//             <div
//               className="row mt-2"
//               style={{
//                 borderRadius: "10px",
//                 border: "2px solid #E3B041",
//                 color: "#4A2A7D",
//                 padding: "20px 15px",
//               }}
//             >
//               <div className="row d-flex justify-content-around">
//                 <div
//                   className="col-md-1 d-flex justify-content-start my-auto p-0"
//                   style={{ height: "auto" }}
//                 >
//                   <img
//                     src={SilverLogo}
//                     className="img-fluid p-0 mx-2"
//                     style={{ height: "auto", width: "150px" }}
//                   />
//                 </div>
//                 <div className="col-md-4  p-2 text-center">
//                   <h5 className="fw-bold">AUGMONT Silver</h5>
//                   <h6>999.9 Purity</h6>
//                 </div>
//                 <div className="col-md-2 my-auto ">
//                   &#8377;&nbsp;
//                   <input
//                     type="text"
//                     id="num1"
//                     value={silverPrice}
//                     onInput={() => handleInputChange("num1")}
//                     className="live fw-bold"
//                     style={{
//                       width: "80px",
//                       border: "none",
//                       background: "transparent",
//                       outline: "none",
//                       color: "#4A2A7D",
//                     }}
//                     readOnly
//                   />
//                 </div>
//                 <div className="col-md-2 mt-4">
//                   <h6
//                     className=""
//                     style={{ color: "#4A2A7D", fontSize: "20px" }}
//                   >
//                     <span className="blinking-circle"></span> &nbsp;Live
//                   </h6>
//                 </div>
//               </div>
//             </div>
//           )}

//           <div className="row d-flex justify-content-center mt-4">
//             <div className="col-md-5 mt-2">
//               <div className="col-md-4 mt-3">
//                 <h5 style={{ color: "#4A2A7D" }}>Amount</h5>
//               </div>
//               <div className="col">
//                 <div className="input-group mb-3">
//                   <span className="input-group-text" id="basic-addon1">
//                     &#8377;
//                   </span>
//                   <input
//                     type="text"
//                     className="form-control text-dark"
//                     aria-describedby="basic-addon1"
//                     id="num2"
//                     onInput={() => handleInputChange("num2")}
//                     style={{ width: "70%" }}
//                   />
//                 </div>
//                 <p
//                   style={{
//                     color: "red",
//                     fontSize: "14px",
//                     marginTop: "5px",
//                   }}
//                 ></p>
//               </div>
//             </div>
//             <div
//               className="col-md-1 py-3 mt-5 mx-2"
//               style={{ color: "#4A2A7D" }}
//             >
//               <i className="fa-solid fa-arrows-left-right fs-5"></i>
//             </div>
//             <div className="col-md-5 mt-2">
//               <div className="col-md-4 mt-3">
//                 <h5 style={{ color: "#4A2A7D" }}>Weight</h5>
//               </div>
//               <div className="col">
//                 <div className="input-group mb-3">
//                   <span className="input-group-text" id="basic-addon1">
//                     gm
//                   </span>
//                   <input
//                     type="text"
//                     className="form-control text-dark"
//                     aria-describedby="basic-addon1"
//                     id="num3"
//                     onInput={() => handleInputChange("num3")}
//                     style={{ width: "70%" }}
//                   />
//                 </div>
//                 <p
//                   style={{
//                     color: "red",
//                     fontSize: "14px",
//                     marginTop: "5px",
//                   }}
//                 ></p>
//               </div>
//             </div>
//           </div>
//           {/* </div> */}
//           {/* </div> */}
//           {/* </div> */}

//           <div className="d-flex justify-content-center">
//             <button
//               type="button"
//               className="btn p-2 mt-3 text-light"
//               onClick={createPurposal}
//               style={{
//                 minWidth: "300px",
//                 borderRadius: "20px",
//                 fontSize: "20px",
//                 backgroundColor: "#4A2A7D",
//               }}
//             >
//               Create Proposal
//             </button>
//           </div>
//           <div className="col-md-12 mt-3" style={{ color: "#4A2A7D" }}>
//             <p className="text-center">Exclusive of 3% GST</p>
//             <h6 className="text-center mt-1" style={{ color: "#4A2A7D" }}>
//               Bharat Batuk Pvt Ltd (BBPL)
//             </h6>
//             <p className="text-center mt-2">
//               Gold bought in last 7 days can not be sold.
//               <br />
//               BBPL offers gold products through our partners.
//               <br />
//               Refer to{" "}
//               <Link target="blank" to="https://batuk.in/terms-conditions/">
//                 {" "}
//                 Terms & Conditions
//               </Link>{" "}
//               of Gold for details.
//             </p>
//           </div>
//         </div>
//         {/* <div
//           className="col-sm-4 py-3 ms-5"
//           style={{ boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)" }}
//         >
//           <h4 className="text-center mb-4 top_Header">FAQ</h4>
//           <Accordion className="description">
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls="panel1-content"
//               id="panel1-header"
//             >
//               <div className="summarry">
//                 <h2>
//                   <span>01.</span> Non consectetur a erat nam at lectus urna
//                   duis?{" "}
//                 </h2>
//               </div>
//             </AccordionSummary>
//             <AccordionDetails>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//               Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
//               eget.
//             </AccordionDetails>
//           </Accordion>
//           <Accordion className="description">
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls="panel2-content"
//               id="panel2-header"
//             >
//               <div className="summarry">
//                 <h2>
//                   <span>02.</span> Non consectetur a erat nam at lectus urna
//                   duis?{" "}
//                 </h2>
//               </div>
//             </AccordionSummary>
//             <AccordionDetails>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//               Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
//               eget.
//             </AccordionDetails>
//           </Accordion>
//           <Accordion className="description">
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls="panel2-content"
//               id="panel2-header"
//             >
//               <div className="summarry">
//                 <h2>
//                   <span>03.</span> Non consectetur a erat nam at lectus urna
//                   duis?{" "}
//                 </h2>
//               </div>
//             </AccordionSummary>
//             <AccordionDetails>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//               Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
//               eget.
//             </AccordionDetails>
//           </Accordion>
//           <Accordion className="description">
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls="panel2-content"
//               id="panel2-header"
//             >
//               <div className="summarry">
//                 <h2>
//                   <span>04.</span> Non consectetur a erat nam at lectus urna
//                   duis?{" "}
//                 </h2>
//               </div>
//             </AccordionSummary>
//             <AccordionDetails>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//               Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
//               eget.
//             </AccordionDetails>
//           </Accordion>
//           <Accordion className="description">
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls="panel2-content"
//               id="panel2-header"
//             >
//               <div className="summarry">
//                 <h2>
//                   <span>05.</span> Non consectetur a erat nam at lectus urna
//                   duis?{" "}
//                 </h2>
//               </div>
//             </AccordionSummary>
//             <AccordionDetails>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//               Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
//               eget.
//             </AccordionDetails>
//           </Accordion>
//           <Accordion className="description">
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls="panel2-content"
//               id="panel2-header"
//             >
//               <div className="summarry">
//                 <h2>
//                   <span>06.</span> Non consectetur a erat nam at lectus urna
//                   duis?{" "}
//                 </h2>
//               </div>
//             </AccordionSummary>
//             <AccordionDetails>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//               Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
//               eget.
//             </AccordionDetails>
//           </Accordion>
//           <Accordion className="description">
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls="panel2-content"
//               id="panel2-header"
//             >
//               <div className="summarry">
//                 <h2>
//                   <span>07.</span> Non consectetur a erat nam at lectus urna
//                   duis?{" "}
//                 </h2>
//               </div>
//             </AccordionSummary>
//             <AccordionDetails>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//               Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
//               eget.
//             </AccordionDetails>
//           </Accordion>
//           <Accordion className="description">
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls="panel2-content"
//               id="panel2-header"
//             >
//               <div className="summarry">
//                 <h2>
//                   <span>08.</span> Non consectetur a erat nam at lectus urna
//                   duis?{" "}
//                 </h2>
//               </div>
//             </AccordionSummary>
//             <AccordionDetails>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//               Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
//               eget.
//             </AccordionDetails>
//           </Accordion>
//         </div> */}
//       </div>
//     </div>
//   );
// };

// export default FinalCreate;
