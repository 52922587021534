import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import ApexCharts from "apexcharts";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Skeleton } from "@mui/material";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";

import TotalAmount from "../assets/Icons/Untitled design (2).png";
import TotalGoldAmount from "../assets/Icons/Untitled design (3).png";
import TotalSilverAmount from "../assets/Icons/Untitled design (5).png";
import TotalGoldGrams from "../assets/Icons/Untitled design (4).png";
import TotalSilverGrams from "../assets/Icons/Untitled design (6).png";
import { baseUrl } from "../utils/baseUrl";
import TableSkeleton from "../utils/TableSkeleton";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { VscFilter } from "react-icons/vsc";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("clientDetails.name", {
    header: "Name",
    size: 40,
  }),
  columnHelper.accessor("clientDetails.mobileNo", {
    header: "Mobile Number",
    size: 40,
  }),
  columnHelper.accessor("clientDetails.Email", {
    header: "Email Id",
    size: 40,
  }),
  columnHelper.accessor("totalInvestment", {
    header: "Total Investment",
    size: 40,
    Cell: (cell) => "₹" + cell.row.original.totalInvestment,
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

function Dashboard() {
  const Token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [TotalAugmontData, setTotalAugmontData] = useState([]);
  const [topInvesters, setTopInvesters] = useState([]);
  const [dataReady, setDataReady] = useState(false);

  const totalSilverPrecentage = TotalAugmontData.totalSilverPrecentage || "0%";
  const totalGoldPercentage = TotalAugmontData.totalGoldPercentage || "0%";

  const percentageSilver = parseFloat(totalSilverPrecentage.replace("%", ""));
  const percentageGold = parseFloat(totalGoldPercentage.replace("%", ""));

  const [filter, setFilter] = useState("Today");
  const [financialYear, setFinancialYear] = useState("2024");
  const [graphData, setGraphData] = useState([]);
  const [viewIn, setViewIn] = useState("grams"); // State to toggle between grams and rupees

  ////////////////////////////////////////////////////////////

  const [filterType, setFilterType] = useState("Today");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [customDate, setCustomDate] = useState({ startDate: "", endDate: "" });
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      await getTotalAugmontData();
      await getGraphData();
      await getTopInvesters();
      setDataReady(true);
    };

    fetchData();
  }, []);

  const getTotalAugmontData = async (startDate, endDate) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await axios.get(`${baseUrl}/getTotalInvestments`, {
        headers,
        params: {
          startDate: startDate,
          endDate,
        },
      });
      setTotalAugmontData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching total Augmont data:", error);
    }
  };

  const getTopInvesters = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await axios.get(`${baseUrl}/topInvesters`, {
        headers,
      });
      setLoading(false);
      const investors = response.data.data.topClients;
      const data = investors.map((investor, index) => ({
        ...investor,
        slno: index + 1,
      }));
      setTopInvesters(data);
    } catch (error) {
      console.error("Error fetching top investors:", error);
    }
  };

  const flattenData = (data) => {
    return data.map((item) => ({
      slno: item.slno,
      name: item.clientDetails.name,
      mobileNo: item.clientDetails.mobileNo,
      email: item.clientDetails.Email,
      totalInvestment: item.totalInvestment,
    }));
  };

  const handleExportData = () => {
    const flattenedData = flattenData(topInvesters);
    const csv = generateCsv(csvConfig)(flattenedData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: topInvesters,
    enableRowSelection: false,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: false,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          Export All Data
        </Button>
      </Box>
    ),
  });

  const financialYearOptions = ["2024", "2023"];

  const getGraphData = async (queryParam) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await axios.get(`${baseUrl}/graphdata?${queryParam}`, {
        headers,
      });

      const processedData = response.data.data.map((item) => ({
        month: item.month,
        gold: item.totalGold,
        silver: item.totalSilver,
        goldInRupees: parseFloat(item.totalGoldAmount),
        silverInRupees: parseFloat(item.totalSilverAmount),
        year: item.year,
      }));

      setGraphData(processedData);
    } catch (error) {
      console.error("Error fetching graph data:", error);
    }
  };

  useEffect(() => {
    let queryParam = "";

    if (filter === "financialyear") {
      queryParam = `financialYear=${financialYear}`;
    } else {
      queryParam = `months=${filter}`;
    }

    getGraphData(queryParam);
  }, [filter, financialYear]);

  const handleGraphFilterChange = (event) => {
    setFilter(event.target.value);
    if (event.target.value !== "financialyear") {
      setFinancialYear(financialYearOptions[0]);
    }
  };

  const handleFinancialYearChange = (event) => {
    setFinancialYear(event.target.value);
  };

  const handleToggleView = (view) => {
    setViewIn(view);
  };

  const chartData = {
    labels: graphData.map((item) => item.month),
    datasets: [
      {
        label: "Gold",
        data:
          viewIn === "grams"
            ? graphData.map((item) => item.gold)
            : graphData.map((item) => item.goldInRupees),
        backgroundColor: "rgba(255, 215, 0, 0.7)", // Gold color
      },
      {
        label: "Silver",
        data:
          viewIn === "grams"
            ? graphData.map((item) => item.silver)
            : graphData.map((item) => item.silverInRupees),
        backgroundColor: "rgba(192, 192, 192, 0.7)",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Months",
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: viewIn === "grams" ? "Grams (g)" : "Rupees (₹)",
        },
        grid: {
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    if (dataReady) {
      const optionsVisitorsProfile = {
        series: [percentageSilver, percentageGold],
        labels: ["Silver", "Gold"],
        colors: ["#C0C0C0", "#FFD700"],
        chart: {
          type: "donut",
          width: "100%",
          height: "350px",
        },
        legend: {
          position: "bottom",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "30%",
            },
          },
        },
      };

      const chartVisitorsProfile = new ApexCharts(
        document.getElementById("chart-visitors-profile"),
        optionsVisitorsProfile
      );

      chartVisitorsProfile.render();
      return () => {
        chartVisitorsProfile.destroy();
      };
    }
  }, [dataReady, percentageSilver, percentageGold]);

  // ------------------------end---------------------------------

  const handleFilterChange = async (e) => {
    const selectedFilter = e.target.value;
    setFilterType(selectedFilter);

    let startDate = "";
    let endDate = moment().format("YYYY-MM-DD");

    if (selectedFilter === "Today") {
      startDate = moment().format("YYYY-MM-DD");
    } else if (selectedFilter === "7Days") {
      startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
    } else if (selectedFilter === "30Days") {
      startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
    } else if (selectedFilter === "Custom") {
      setIsDropdownOpen(true);
      return;
    }

    await getTotalAugmontData(startDate, endDate);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setCustomDate({ ...customDate, [name]: value });
  };

  const handleSubmit = async () => {
    const { startDate, endDate } = customDate;

    if (!startDate || !endDate) {
      toast.error("Please select both start and end dates!");
      return;
    }
    setIsDropdownOpen(false);
    await getTotalAugmontData(startDate, endDate);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="dashboard section"
      style={{ marginTop: "1rem", paddingRight: "1.5rem" }}
    >
      <div className="row">
        <div
          className="col-md-12 ms-2 mt-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col
            xs={6}
            style={{ width: "25%", display: "flex", alignItems: "center" }}
          >
            <h2 className="TABLE_HEADING" style={{ margin: "0px" }}>
              Dashboard
            </h2>
          </Col>
          <div xs={6} className="filter-data-drop">
            <div className="dropdown-wrapper" ref={dropdownRef}>
              <select
                className="filter-dropdown"
                value={filterType}
                onChange={handleFilterChange}
                onClick={() => setIsDropdownOpen(true)}
              >
                <option value="Today">Today</option>
                <option value="7Days">7 Days</option>
                <option value="30Days">30 Days</option>
                <option value="Custom">Custom Date</option>
              </select>

              {isDropdownOpen && filterType === "Custom" && (
                <div className="custom-dropdown">
                  <input
                    type="date"
                    name="startDate"
                    value={customDate.startDate}
                    onChange={handleDateChange}
                    className="date-input"
                  />
                  <input
                    type="date"
                    name="endDate"
                    value={customDate.endDate}
                    onChange={handleDateChange}
                    className="date-input"
                  />
                  <button
                    onClick={handleSubmit}
                    className="submit-button"
                    disabled={!customDate.startDate || !customDate.endDate}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="page-content">
        <section className="row">
          <div className="col-12 col-lg-9">
            <div className="row">
              <div className="col-12 col-lg-4 col-md-6">
                <div className="card" style={{ width: "100%" }}>
                  <div className="card-body px-4 py-4-5">
                    <div className="row">
                      <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                        <div className="stats-icon purple mb-2">
                          <img
                            src={TotalAmount}
                            alt="Total Amount"
                            width="100"
                            className="me-3"
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                        <h6 className="text-muted font-semibold">
                          Total Amount
                        </h6>
                        {loading ? (
                          <Skeleton variant="text" width={100} height={20} />
                        ) : (
                          <h6 className="font-extrabold mb-0">
                            ₹ {TotalAugmontData.totalAumAmount}
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-6">
                <div className="card" style={{ width: "100%" }}>
                  <div className="card-body px-4 py-4-5">
                    <div className="row">
                      <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                        <div className="stats-icon blue mb-2">
                          <img
                            src={TotalGoldAmount}
                            alt="Total Gold Amount"
                            width="100"
                            className="me-3"
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                        <h6 className="text-muted font-semibold">
                          Gold Amount
                        </h6>
                        <h6 className="font-extrabold mb-0">
                          ₹ {TotalAugmontData.totalGoldAmount}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-6">
                <div className="card" style={{ width: "100%" }}>
                  <div className="card-body px-4 py-4-5">
                    <div className="row">
                      <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                        <div className="stats-icon green mb-2">
                          <img
                            src={TotalSilverAmount}
                            alt="Total Silver Amount"
                            width="100"
                            className="me-3"
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                        <h6 className="text-muted font-semibold">
                          Silver Amount
                        </h6>
                        <h6 className="font-extrabold mb-0">
                          ₹ {TotalAugmontData.totalSilverAmount}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-6">
                <div className="card" style={{ width: "100%" }}>
                  <div className="card-body px-4 py-4-5">
                    <div className="row">
                      <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                        <div className="stats-icon purple mb-2">
                          <img
                            src={TotalAmount}
                            alt="Total Amount"
                            width="100"
                            className="me-3"
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                        <h6 className="text-muted font-semibold">
                          Total Grams
                        </h6>
                        <h6 className="font-extrabold mb-0">
                          {TotalAugmontData.totalGrams}g
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-6">
                <div className="card" style={{ width: "100%" }}>
                  <div className="card-body px-4 py-4-5">
                    <div className="row">
                      <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                        <div className="stats-icon red mb-2">
                          <img
                            src={TotalGoldGrams}
                            alt="Total Gold Grams"
                            width="100"
                            className="me-3"
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                        <h6 className="text-muted font-semibold">Gold Grams</h6>
                        <h6 className="font-extrabold mb-0">
                          {TotalAugmontData.totalGoldGrams}g
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-6">
                <div className="card" style={{ width: "100%" }}>
                  <div className="card-body px-4 py-4-5">
                    <div className="row">
                      <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                        <div className="stats-icon red mb-2">
                          <img
                            src={TotalSilverGrams}
                            alt="Total Silver Grams"
                            width="100"
                            className="me-3"
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                        <h6 className="text-muted font-semibold">
                          Silver Grams
                        </h6>
                        <h6 className="font-extrabold mb-0">
                          {TotalAugmontData.totalSilverGrams}g
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div className="card" style={{ width: "100%" }}>
              <div className="card-header">
                <h2 className="TEXT_HEADING">Total Gold/Silver</h2>
              </div>
              <div className="card-body">
                <div id="chart-visitors-profile"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ms-2 mt-2">
              <Col xs={6} style={{ width: "100%", marginBottom: "10px" }}>
                <h2 className="TABLE_HEADING" style={{ marginLeft: "0px" }}>
                  Gold and Silver Price Analysis
                </h2>
              </Col>
            </div>
          </div>
          <div className="col-12 col-lg-12">
            <div className="card" style={{ width: "98%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <div className="toggle-buttons">
                  <button
                    className={`toggle-button ${
                      viewIn === "grams" ? "active" : ""
                    }`}
                    onClick={() => handleToggleView("grams")}
                  >
                    In Grams
                  </button>
                  <button
                    className={`toggle-button ${
                      viewIn === "rupee" ? "active" : ""
                    }`}
                    onClick={() => handleToggleView("rupee")}
                  >
                    In Amount
                  </button>
                </div>

                <div className="graph-filter-dropdown">
                  <div className="filter-container">
                    <label className="filter-label">
                      <VscFilter />
                    </label>
                    <select
                      className="filter-select"
                      onChange={handleGraphFilterChange}
                      value={filter}
                    >
                      <option value="3month">Last 3 Months</option>
                      <option value="6month">Last 6 Months</option>
                      <option value="financialyear">Financial Year</option>
                    </select>

                    {filter === "financialyear" && (
                      <select
                        className="filter-select nested-select"
                        onChange={handleFinancialYearChange}
                        value={financialYear}
                      >
                        {financialYearOptions.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div
                className="chart-container"
                style={{ height: "400px", width: "100%" }}
              >
                <Bar data={chartData} options={chartOptions} />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12} style={{ padding: "12px 0px 0px 12px" }}>
            <h2 className="TABLE_HEADING" style={{ marginLeft: "0px" }}>
              Top Investers
            </h2>
            {loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Dashboard;

// import React, { useEffect, useRef, useState } from "react";
// import { BarChart } from "@mui/x-charts/BarChart";
// import { axisClasses } from "@mui/x-charts/ChartsAxis";
// import axios from "axios";
// import ApexCharts from "apexcharts";
// import { Row, Col, Container } from "react-bootstrap";
// import {
//   MaterialReactTable,
//   createMRTColumnHelper,
//   useMaterialReactTable,
// } from "material-react-table";
// import { Box, Skeleton } from "@mui/material";
// import Button from "@mui/material/Button";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import { mkConfig, generateCsv, download } from "export-to-csv";

// import TotalAmount from "../assets/Icons/Untitled design (2).png";
// import TotalGoldAmount from "../assets/Icons/Untitled design (3).png";
// import TotalSilverAmount from "../assets/Icons/Untitled design (5).png";
// import TotalGoldGrams from "../assets/Icons/Untitled design (4).png";
// import TotalSilverGrams from "../assets/Icons/Untitled design (6).png";
// import { baseUrl } from "../utils/baseUrl";
// import TableSkeleton from "../utils/TableSkeleton";

// const columnHelper = createMRTColumnHelper();

// const columns = [
//   columnHelper.accessor("clientDetails.name", {
//     header: "Name",
//     size: 40,
//   }),
//   columnHelper.accessor("clientDetails.mobileNo", {
//     header: "Mobile Number",
//     size: 40,
//   }),
//   columnHelper.accessor("clientDetails.Email", {
//     header: "Email Id",
//     size: 40,
//   }),
//   columnHelper.accessor("totalInvestment", {
//     header: "Total Investment",
//     size: 40,
//     Cell: (cell) => "₹" + cell.row.original.totalInvestment,
//   }),
// ];

// const csvConfig = mkConfig({
//   fieldSeparator: ",",
//   decimalSeparator: ".",
//   useKeysAsHeaders: true,
// });

// function Dashboard() {
//   const Token = sessionStorage.getItem("token");
//   const [loading, setLoading] = useState(true);
//   const [TotalAugmontData, setTotalAugmontData] = useState([]);
//   const [graphdata, setGraphData] = useState([]);
//   const [topInvesters, setTopInvesters] = useState([]);
//   const [dataReady, setDataReady] = useState(false);

//   const totalSilverPrecentage = TotalAugmontData.totalSilverPrecentage || "0%";
//   const totalGoldPercentage = TotalAugmontData.totalGoldPercentage || "0%";

//   const percentageSilver = parseFloat(totalSilverPrecentage.replace("%", ""));
//   const percentageGold = parseFloat(totalGoldPercentage.replace("%", ""));

//   console.log(graphdata, ">>>>>>>>>>>>> Data");

//   useEffect(() => {
//     const fetchData = async () => {
//       await getTotalAugmontData();
//       await getGraphData();
//       await getTopInvesters();
//       setDataReady(true);
//     };

//     fetchData();
//   }, []);

//   const getTotalAugmontData = async () => {
//     try {
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };

//       const response = await axios.get(`${baseUrl}/getTotalInvestments`, {
//         headers,
//       });
//       setTotalAugmontData(response.data.data);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching total Augmont data:", error);
//     }
//   };

//   const getGraphData = async () => {
//     try {
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };

//       const response = await axios.get(`${baseUrl}/graphdata`, {
//         headers,
//       });
//       const processedData = response.data.data.map((item) => ({
//         month: item.month,
//         gold: item.totalGold,
//         silver: item.totalSilver,
//         year: item.year,
//       }));
//       setGraphData(processedData);
//     } catch (error) {
//       console.error("Error fetching graph data:", error);
//     }
//   };

//   const getTopInvesters = async () => {
//     try {
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };

//       const response = await axios.get(`${baseUrl}/topInvesters`, {
//         headers,
//       });
//       setLoading(false);
//       const investors = response.data.data.topClients;
//       const data = investors.map((investor, index) => ({
//         ...investor,
//         slno: index + 1,
//       }));
//       setTopInvesters(data);
//     } catch (error) {
//       console.error("Error fetching top investors:", error);
//     }
//   };

//   const flattenData = (data) => {
//     return data.map((item) => ({
//       slno: item.slno,
//       name: item.clientDetails.name,
//       mobileNo: item.clientDetails.mobileNo,
//       email: item.clientDetails.Email,
//       totalInvestment: item.totalInvestment,
//     }));
//   };

//   const handleExportData = () => {
//     const flattenedData = flattenData(topInvesters);
//     const csv = generateCsv(csvConfig)(flattenedData);
//     download(csvConfig)(csv);
//   };

//   const table = useMaterialReactTable({
//     columns,
//     data: topInvesters,
//     enableRowSelection: false,
//     enableGlobalFilter: true,
//     columnFilterDisplayMode: "popover",
//     enableColumnActions: false,
//     enableColumnFilters: false,
//     enableSorting: true,
//     enableDensityToggle: false,
//     enableHiding: false,
//     maxSize: 100,
//     enableRowActions: false,
//     positionActionsColumn: "last",
//     muiTableHeadCellProps: {
//       sx: (theme) => ({
//         color: "#4a2a7d",
//         background: "#DCDAFF",
//         fontWeight: "normal",
//         fontSize: "10px",
//       }),
//     },
//     renderTopToolbarCustomActions: ({ table }) => (
//       <Box
//         sx={{
//           display: "flex",
//           gap: "16px",
//           padding: "8px",
//           flexWrap: "wrap",
//         }}
//       >
//         <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
//           Export All Data
//         </Button>
//       </Box>
//     ),
//   });

//   const chartSetting = {
//     yAxis: [
//       {
//         label: "Grams",
//       },
//     ],
//     width: 1000,
//     height: 400,
//     padding: "1rem",
//     sx: {
//       [`.${axisClasses.left} .${axisClasses.label}`]: {
//         transform: "translate(-20px, -20px)",
//       },
//     },
//   };

//   useEffect(() => {
//     if (dataReady) {
//       const optionsVisitorsProfile = {
//         series: [percentageSilver, percentageGold],
//         labels: ["Silver", "Gold"],
//         colors: ["#C0C0C0", "#FFD700"],
//         chart: {
//           type: "donut",
//           width: "100%",
//           height: "350px",
//         },
//         legend: {
//           position: "bottom",
//         },
//         plotOptions: {
//           pie: {
//             donut: {
//               size: "30%",
//             },
//           },
//         },
//       };

//       const chartVisitorsProfile = new ApexCharts(
//         document.getElementById("chart-visitors-profile"),
//         optionsVisitorsProfile
//       );

//       chartVisitorsProfile.render();

//       return () => {
//         chartVisitorsProfile.destroy();
//       };
//     }
//   }, [dataReady, percentageSilver, percentageGold]);

//   // ------------------------end---------------------------------

//   const [filterType, setFilterType] = useState("Today");
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [customDate, setCustomDate] = useState({ startDate: "", endDate: "" });
//   const dropdownRef = useRef(null);

//   const handleFilterChange = (e) => {
//     setFilterType(e.target.value);
//     if (e.target.value === "Custom") {
//       setIsDropdownOpen(true);
//     } else {
//       setIsDropdownOpen(false);
//     }
//   };

//   const handleDateChange = (e) => {
//     const { name, value } = e.target;
//     setCustomDate({ ...customDate, [name]: value });
//   };

//   const handleSubmit = () => {
//     alert(
//       `Filtering data from ${customDate.startDate} to ${customDate.endDate}`
//     );
//     setIsDropdownOpen(false); // Close dropdown after submission
//   };

//   const handleClickOutside = (event) => {
//     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//       setIsDropdownOpen(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   return (
//     <div className="dashboard section">
//       <div className="row">
//         <div
//           className="col-md-12 ms-2 mt-2"
//           style={{ display: "flex", justifyContent: "space-between" }}
//         >
//           <Col
//             xs={6}
//             style={{ width: "25%", display: "flex", alignItems: "center" }}
//           >
//             <h2 className="TABLE_HEADING" style={{ margin: "0px" }}>
//               Dashboard
//             </h2>
//           </Col>
//           <Col xs={6} style={{ width: "20%" }}>
//             <div className="dropdown-wrapper" ref={dropdownRef}>
//               <select
//                 className="filter-dropdown"
//                 value={filterType}
//                 onChange={handleFilterChange}
//                 onClick={() => setIsDropdownOpen(true)}
//               >
//                 <option value="Today">Today</option>
//                 <option value="7Days">7 Days</option>
//                 <option value="30Days">30 Days</option>
//                 <option value="Custom">Custom Date</option>
//               </select>

//               {isDropdownOpen && filterType === "Custom" && (
//                 <div className="custom-dropdown">
//                   <input
//                     type="date"
//                     name="startDate"
//                     value={customDate.startDate}
//                     onChange={handleDateChange}
//                     className="date-input"
//                   />
//                   <input
//                     type="date"
//                     name="endDate"
//                     value={customDate.endDate}
//                     onChange={handleDateChange}
//                     className="date-input"
//                   />
//                   <button
//                     onClick={handleSubmit}
//                     className="submit-button"
//                     disabled={!customDate.startDate || !customDate.endDate}
//                   >
//                     Submit
//                   </button>
//                 </div>
//               )}
//             </div>
//           </Col>
//         </div>
//       </div>
//       <hr />
//       <div className="page-content">
//         <section className="row">
//           <div className="col-12 col-lg-9">
//             <div className="row">
//               <div className="col-12 col-lg-4 col-md-6">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-body px-4 py-4-5">
//                     <div className="row">
//                       <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
//                         <div className="stats-icon purple mb-2">
//                           <img
//                             src={TotalAmount}
//                             alt="Total Amount"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
//                         <h6 className="text-muted font-semibold">
//                           Total Amount
//                         </h6>
//                         {loading ? (
//                           <Skeleton variant="text" width={100} height={20} />
//                         ) : (
//                           <h6 className="font-extrabold mb-0">
//                             ₹ {TotalAugmontData.totalAumAmount}
//                           </h6>
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-12 col-lg-4 col-md-6">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-body px-4 py-4-5">
//                     <div className="row">
//                       <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
//                         <div className="stats-icon blue mb-2">
//                           <img
//                             src={TotalGoldAmount}
//                             alt="Total Gold Amount"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
//                         <h6 className="text-muted font-semibold">
//                           Gold Amount
//                         </h6>
//                         <h6 className="font-extrabold mb-0">
//                           ₹ {TotalAugmontData.totalGoldAmount}
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-12 col-lg-4 col-md-6">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-body px-4 py-4-5">
//                     <div className="row">
//                       <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
//                         <div className="stats-icon green mb-2">
//                           <img
//                             src={TotalSilverAmount}
//                             alt="Total Silver Amount"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
//                         <h6 className="text-muted font-semibold">
//                           Silver Amount
//                         </h6>
//                         <h6 className="font-extrabold mb-0">
//                           ₹ {TotalAugmontData.totalSilverAmount}
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-12 col-lg-4 col-md-6">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-body px-4 py-4-5">
//                     <div className="row">
//                       <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
//                         <div className="stats-icon purple mb-2">
//                           <img
//                             src={TotalAmount}
//                             alt="Total Amount"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
//                         <h6 className="text-muted font-semibold">
//                           Total Grams
//                         </h6>
//                         <h6 className="font-extrabold mb-0">
//                           {TotalAugmontData.totalGrams}g
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-12 col-lg-4 col-md-6">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-body px-4 py-4-5">
//                     <div className="row">
//                       <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
//                         <div className="stats-icon red mb-2">
//                           <img
//                             src={TotalGoldGrams}
//                             alt="Total Gold Grams"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
//                         <h6 className="text-muted font-semibold">Gold Grams</h6>
//                         <h6 className="font-extrabold mb-0">
//                           {TotalAugmontData.totalGoldGrams}g
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-12 col-lg-4 col-md-6">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-body px-4 py-4-5">
//                     <div className="row">
//                       <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
//                         <div className="stats-icon red mb-2">
//                           <img
//                             src={TotalSilverGrams}
//                             alt="Total Silver Grams"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
//                         <h6 className="text-muted font-semibold">
//                           Silver Grams
//                         </h6>
//                         <h6 className="font-extrabold mb-0">
//                           {TotalAugmontData.totalSilverGrams}g
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-12 col-lg-3">
//             <div className="card" style={{ width: "100%" }}>
//               <div className="card-header">
//                 <h2 className="TEXT_HEADING">Total Gold/Silver</h2>
//               </div>
//               <div className="card-body">
//                 <div id="chart-visitors-profile"></div>
//               </div>
//             </div>
//           </div>
//           <div className="col-12 col-lg-12">
//             <div className="row">
//               <div className="col-12">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-header">
//                     <h2 className="TEXT_HEADING">Graph Data</h2>
//                   </div>
//                   <div className="card-body">
//                     <div id="chart-profile-visit">
//                       <Col
//                         style={{ display: "flex", justifyContent: "center" }}
//                       >
//                         <BarChart
//                           dataset={graphdata}
//                           xAxis={[{ scaleType: "band", dataKey: "month" }]}
//                           yAxis={{ dataKey: "year", scaleType: "linear" }}
//                           series={[
//                             {
//                               dataKey: "gold",
//                               label: "Gold",
//                               color: "gold",
//                               valueFormatter: (value) => value.toFixed(4),
//                             },
//                             {
//                               dataKey: "silver",
//                               label: "Silver",
//                               color: "silver",
//                               valueFormatter: (value) => value.toFixed(4),
//                             },
//                           ]}
//                           {...chartSetting}
//                         />
//                       </Col>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//       <Container fluid>
//         <Row>
//           <Col xs={12}>
//             <h2 className="TABLE_HEADING">Top Investers</h2>
//             {loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// }

// export default Dashboard;
