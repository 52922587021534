import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AboutBatukPartener = () => {
  return (
    <>
      <div className="AboutBatuk">
        <div className="container">
          <div className="row">
            <Link target="blank" to="https://batuk.in/">
              <Accordion className="mb-4">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header">
                  Mission Vission
                </AccordionSummary>
              </Accordion>
            </Link>

            <Link target="blank" to="https://batuk.in/terms-conditions/">
              <Accordion className="mb-4">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header">
                  Terms & Conditions
                </AccordionSummary>
              </Accordion>
            </Link>

            <Link target="blank" to="https://batuk.in/terms-conditions/">
              <Accordion className="mb-4">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header">
                  Privacy Policy
                </AccordionSummary>
              </Accordion>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutBatukPartener;
