import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import { ToastContainer, toast } from "react-toastify";
import { baseUrl } from "../../utils/baseUrl";
import moment from "moment/moment";
import TableSkeleton from "../../utils/TableSkeleton";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("order_no", {
    header: "Order No.",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>{cell.row.original.order_no}</div>
    ),
  }),
  columnHelper.accessor("client_name", {
    header: "Client",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>{cell.row.original.client_name}</div>
    ),
  }),
  columnHelper.accessor("metal_type", {
    header: "Metal Type",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.productDetails[0].metal_type}
      </div>
    ),
  }),
  columnHelper.accessor("id", {
    header: "Link",
    size: 40,
    Cell: (cell) => (
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(
            `${baseUrl}/emi/partner_portal/Orderdetails/${cell.row.original.order_no}`
          );
          toast.success("Link copied to clipboard!");
        }}
      >
        <ContentCopyIcon />
      </IconButton>
    ),
  }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 40,
    Cell: (cell) => (
      <span
        style={{
          backgroundColor: "rgb(74 42 125)",
          padding: " 7px 14px",
          borderRadius: "12px",
          fontSize: "13px",
          color: "white",
        }}
      >
        {cell.row.original.status}
      </span>
    ),
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {"₹ " + cell.row.original.productDetails[0].finalOrderPrice}
      </div>
    ),
  }),
  columnHelper.accessor("createdAt", {
    header: "Created At",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {moment(cell.row.original.createdAt.split("T")[0]).format("DD-MM-YYYY")}
      </div>
    ),
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const OrderInProgressTable = () => {
  const token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [progressData, setprogressData] = useState([]);

  useEffect(() => {
    GetInprogressClient();
  }, []);

  const GetInprogressClient = async () => {
    try {
      const url = `${baseUrl}/emi/partner_portal/getOrderSummary`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });

      const spotgold = response.data.data.PendingOrder;
      setLoading(false);
      setprogressData(spotgold);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      order_no: row.original.order_no,
      client_name: row.original.firstName + " " + row.original.lastName,
      metal_type: row.original.productDetails[0].metal_type,
      amount: row.original.productDetails[0].finalOrderPrice,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const rowData = progressData.map((data) => ({
      order_no: data.order_no,
      client_name: data.client_name,
      metal_type: data.productDetails[0].metal_type,
      amount: data.productDetails[0].finalOrderPrice,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };
  const table = useMaterialReactTable({
    columns,
    data: progressData,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: false,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          Export All Data
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });
  return (
    <>
      <ToastContainer />
      {loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}
    </>
  );
};

export default OrderInProgressTable;
