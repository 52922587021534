const faqs = [
  {
    title: "How do I register on the Batuk Partner Portal?",
    steps: [
      "To register, visit the Batuk Partner Portal and click on the 'Register' button.",
      "Fill in the required details, including your company information, and submit the form.",
      "Once approved, you'll receive a confirmation email with login details.",
    ],
  },
  {
    title: "How can I add a new client through the portal?",
    steps: [
      <>
        Log in to the portal{" "}
        <a
          href="https://partner.batuk.gold"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://partner.batuk.gold
        </a>
        .
      </>,
      "Go to the 'Clients' section.",
      "Click 'Add Client.'",
      "Enter client details.",
      "Save the information.",
    ],
  },
  {
    title:
      "How do I complete the KYC (Know Your Customer) process for a client?",
    steps: [
      <>
        Log in to the portal{" "}
        <a
          href="https://partner.batuk.gold"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://partner.batuk.gold
        </a>
        .
      </>,
      "Go to the 'Clients' section.",
      "Find the client from the list.",
      "Click on the 'KYC' button.",
      "Fill in the required details.",
      "Click 'Update.'",
      "The KYC process will be completed.",
    ],
  },
  {
    title: "What if I forget my password?",
    steps: [
      "If you forget your password, click on the 'Forgot Password' on the login page.",
      "Enter your registered email address, and we'll send you a link to reset your password.",
    ],
  },
  {
    title: "How do I update my company or personal information on the portal?",
    steps: [
      "To update your information, go to the 'Profile' section after logging in, where you can edit your company or personal details as needed.",
    ],
  },
  {
    title: "How can I access client transaction history?",
    steps: [
      "You can view a client's transaction history by logging into the portal, navigating to the 'Clients' section, and selecting the client whose history you wish to review.",
      "You'll find a detailed log of all transactions under their profile.",
    ],
  },
  {
    title: "How can I add a new Employee through the portal?",
    steps: [
      <>
        Log in to the portal{" "}
        <a
          href="https://partner.batuk.gold"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://partner.batuk.gold
        </a>
        .
      </>,
      "Go to the 'Employee' section.",
      "Click 'Add Employee.'",
      "Enter Employee details.",
      "Save the information.",
    ],
  },
  {
    title: "How do I create SIP (Systematic Investment Plan) for a client?",
    steps: [
      "Log in to the portal.",
      "Navigate to the 'SIP' section.",
      "From the list, select the client or search for the client's name.",
      "Click on 'Place Order.'",
      "Enter the amount and select the day for the SIP.",
      "Click on 'Create.'",
      "Copy the link and share it with your client, and we will send an email to your client as well.",
    ],
  },
  {
    title: "How do I delete SIP for a client?",
    steps: [
      "Log in to the portal.",
      "Go to the 'SIP' section.",
      "Locate the SIP you want to delete from the list.",
      "Delete the selected SIP.",
    ],
  },
  {
    title: "How do I create FD (Fixed Deposit) for a client?",
    steps: [
      "Log in to the portal.",
      "Navigate to the 'Gold FD' section.",
      "From the list, select the client or search for the client's name.",
      "Click on 'Start FD.'",
    ],
  },
  {
    title: "How do I create an order for a client?",
    steps: [
      "Log in to the portal.",
      "Navigate to the 'Orders' section.",
      "Click on 'Create Order.'",
      "From the list, select the client or search for the client's name.",
      "Click on 'Place Order.'",
      "Enter the product details and specify the quantity.",
      "Review the order details and click on 'Create Order.'",
      "Copy the order link and share it with your client. You will also send an email confirmation to your client.",
    ],
  },
  {
    title: "How do I delete an order for a client?",
    steps: [
      "Log in to the portal.",
      "Go to the 'Orders' section.",
      "Locate the Order you want to delete from the list.",
      "Delete the selected Order.",
    ],
  },
  {
    title: "How do I track my earnings on the Batuk Partner Portal?",
    steps: [
      "Log in to the portal.",
      "Go to the 'Earnings' section.",
      "View a breakdown of your Total Earnings, Gold Amount, and other incentives.",
      "Filter by date to see earnings over specific periods.",
      "Download reports for your records or tax purposes.",
    ],
  },
  {
    title: "How do I track my sales on the Batuk Partner Portal?",
    steps: [
      "Log in to the portal.",
      "Navigate to the 'Sales' section.",
      "View detailed reports of your sales, including client transactions and product orders.",
      "Filter by date range to analyze sales performance over specific periods.",
      "Download sales reports for your records.",
    ],
  },
];

export default faqs;

// import React from "react";

// const faqs = [
//   {
//     title: "How do I register on the Batuk Partner Portal?",
//     steps: [
//       "To register, visit the Batuk Partner Portal and click on the 'Register' button.",
//       "Fill in the required details, including your company information, and submit the form.",
//       "Once approved, you'll receive a confirmation email with login details.",
//     ],
//   },
//   {
//     title: "How can I add a new client through the portal?",
//     steps: [
//       <>
//         Log in to the portal{" "}
//         <a
//           href="https://partner.batuk.gold"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           https://partner.batuk.gold
//         </a>
//         .
//       </>,
//       "Go to the 'Clients' section.",
//       "Click 'Add Client.'",
//       "Enter client details.",
//       "Save the information.",
//     ],
//   },
//   {
//     title:
//       "How do I complete the KYC (Know Your Customer) process for a client?",
//     steps: [
//       <>
//         Log in to the portal{" "}
//         <a
//           href="https://partner.batuk.gold"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           https://partner.batuk.gold
//         </a>
//         .
//       </>,
//       "Go to the 'Clients' section.",
//       "Find the client from the list.",
//       "Click on the 'KYC' button.",
//       "Fill in the required details.",
//       "Click 'Update.'",
//       "The KYC process will be completed.",
//     ],
//   },
//   {
//     title: "What if I forget my password?",
//     steps: [
//       "If you forget your password, click on the 'Forgot Password' on the login page.",
//       "Enter your registered email address, and we'll send you a link to reset your password.",
//     ],
//   },
//   {
//     title: "How do I update my company or personal information on the portal?",
//     steps: [
//       "To update your information, go to the 'Profile' section after logging in, where you can edit your company or personal details as needed.",
//     ],
//   },
//   {
//     title: "How can I access client transaction history?",
//     steps: [
//       "You can view a client's transaction history by logging into the portal, navigating to the 'Clients' section, and selecting the client whose history you wish to review.",
//       "You'll find a detailed log of all transactions under their profile.",
//     ],
//   },
//   {
//     title: "How can I add a new Employee through the portal?",
//     steps: [
//       <>
//         Log in to the portal{" "}
//         <a
//           href="https://partner.batuk.gold"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           https://partner.batuk.gold
//         </a>
//         .
//       </>,
//       "Go to the 'Employee' section.",
//       "Click 'Add Employee.'",
//       "Enter Employee details.",
//       "Save the information.",
//     ],
//   },
//   {
//     title: "How do I create SIP (Systematic Investment Plan) for a client?",
//     steps: [
//       "Log in to the portal.",
//       "Navigate to the 'SIP' section.",
//       "From the list, select the client or search for the client's name.",
//       "Click on 'Place Order.'",
//       "Enter the amount and select the day for the SIP.",
//       "Click on 'Create.'",
//       "Copy the link and share it with your client, and we will send an email to your client as well.",
//     ],
//   },
//   {
//     title: "How do I delete SIP for a client?",
//     steps: [
//       "Log in to the portal.",
//       "Go to the 'SIP' section.",
//       "Locate the SIP you want to delete from the list.",
//       "Delete the selected SIP.",
//     ],
//   },
//   {
//     title: "How do I create FD (Fixed Deposit) for a client?",
//     steps: [
//       "Log in to the portal.",
//       "Navigate to the 'Gold FD' section.",
//       "From the list, select the client or search for the client's name.",
//       "Click on 'Start FD.'",
//     ],
//   },

// ];

// export default faqs;
