import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { baseUrl } from "../../utils/baseUrl";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import TableSkeleton from "../../utils/TableSkeleton";
import { Col, Form, Modal, Row } from "react-bootstrap";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("id", {
    header: "FD Id",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>{cell.row.original.data.id}</div>
    ),
  }),
  columnHelper.accessor("userAccountName", {
    header: "Client",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.data.userAccountName}
      </div>
    ),
  }),
  columnHelper.accessor("schemaName", {
    header: "Scheme Name",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.data.schemaName}
      </div>
    ),
  }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 40,
    Cell: (cell) => (
      <span
        style={{
          backgroundColor: "rgb(74 42 125)",
          padding: "7px 14px",
          borderRadius: "12px",
          fontSize: "13px",
          color: "white",
        }}
      >
        {cell.row.original.data.status}
      </span>
    ),
  }),
  columnHelper.accessor("goldLease", {
    header: "Quantity (gm)",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.data.goldLease}gm
      </div>
    ),
  }),
  columnHelper.accessor("interestRate", {
    header: "Interest Rate (%)",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.data.interestRate}%
      </div>
    ),
  }),

  columnHelper.accessor("startDate", {
    header: "Start Date",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {moment(cell.row.original.data.startDate).format("DD-MM-YYYY")}
      </div>
    ),
  }),
  columnHelper.accessor("endDate", {
    header: "End Date",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {moment(cell.row.original.data.endDate).format("DD-MM-YYYY")}
      </div>
    ),
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const FdHistory = () => {
  const Token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [fdHistory, setFdHistory] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [closeFdDetails, setCloseFdDetails] = useState({});

  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

  useEffect(() => {
    getFdHistory();
  }, []);

  const getFdHistory = async () => {
    try {
      const url = `${baseUrl}/partnerPortal/fd/orderList`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });

      // Log the response to understand its structure

      const activeFD = response.data.data?.FdActive || [];

      setLoading(false);
      setFdHistory(activeFD);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      id: row.original.data.id,
      userAccountName: row.original.data.userAccountName,
      schemaName: row.original.data.schemaName,
      goldLease: row.original.data.goldLease,
      interestRate: row.original.data.interestRate,
      startDate: row.original.data.startDate,
      endDate: row.original.data.endDate,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const rowData = fdHistory?.map((data) => ({
      id: data.data.id,
      userAccountName: data.data.userAccountName,
      schemaName: data.data.schemaName,
      goldLease: data.data.goldLease,
      interestRate: data.data.interestRate,
      startDate: data.data.startDate,
      endDate: data.data.endDate,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: fdHistory,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ row }) => [
      <Box key={row.id}>
        <IconButton
          onClick={() => handleOpenDialog(row.original.data.transactionId)}
          style={{
            backgroundColor: "rgb(74 42 125)",
            width: "6rem",
            padding: "7px 14px",
            borderRadius: "12px",
            fontSize: "13px",
            color: "white",
          }}
        >
          Close FD
        </IconButton>
      </Box>,
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          style={{ backgroundColor: "none" }}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  const handleCancelOrder = async (transactionId) => {
    try {
      const url = `${baseUrl}/partnerPortal/fd/preorderClose/${transactionId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.put(url, {}, { headers });

      Swal.fire("Closed!", "Your FD has been closed.", "success");

      // Refresh FD history after cancellation
      getFdHistory();
      closeCreateModal();
    } catch (error) {
      console.error("Error cancelling the order", error);
      Swal.fire("Error", "Could not cancel the FD. Please try again.", "error");
    }
  };

  const preorderCloseCalculate = async (selectedId) => {
    try {
      const url = `${baseUrl}/partnerPortal/fd/preorderCloseCalculate/${selectedId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.put(url, {}, { headers });
      const closeFd = response?.data?.data?.result?.data;
      setCloseFdDetails(closeFd);
    } catch (error) {
      console.error("Error calculating preorder close", error);
    }
  };

  useEffect(() => {
    preorderCloseCalculate(selectedId);
  }, [selectedId]);

  const handleOpenDialog = (transactionId) => {
    setSelectedId(transactionId);
    setCreateModalIsOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
    setSelectedId(null);
  };
  return (
    <>
      <Box sx={{ margin: "2%" }}>
        {loading ? (
          <TableSkeleton columns={columns.length} />
        ) : (
          <MaterialReactTable table={table} />
        )}
      </Box>
      {createModalIsOpen && (
        <Modal
          show={createModalIsOpen}
          onHide={closeCreateModal}
          centered
          size="lg"
        >
          <Modal.Body>
            <h3 className="text-center fw-bold form_header">
              FD Closure Summary
            </h3>
            <form>
              <div className="row p-2">
                <dt className="col-6">Gold Lease:</dt>
                <dd
                  className="col-6"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "0px",
                  }}
                >
                  {closeFdDetails.endOfLease}
                </dd>
                <dt className="col-6">Scheme Name:</dt>
                <dd
                  className="col-6"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "0px",
                  }}
                >
                  {closeFdDetails?.fdScheme?.description}
                </dd>
                <dt className="col-6">User Account Name:</dt>
                <dd
                  className="col-6"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "0px",
                  }}
                >
                  {closeFdDetails?.fdScheme?.jewellerUserAccountName}
                </dd>
                <dt className="col-6">Start Date:</dt>
                <dd
                  className="col-6"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "0px",
                  }}
                >
                  {closeFdDetails.startDate}
                </dd>
                <dt className="col-6">End Date:</dt>
                <dd
                  className="col-6"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "0px",
                  }}
                >
                  {closeFdDetails.endDate}
                </dd>
                <dt className="col-6">Tenure:</dt>
                <dd
                  className="col-6"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "0px",
                  }}
                >
                  {closeFdDetails?.fdScheme?.tenure}
                </dd>
                <dt className="col-6">Remaining Tenure:</dt>
                <dd
                  className="col-6"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "0px",
                  }}
                >
                  {closeFdDetails?.fdScheme?.remaingTenure}
                </dd>
                <dt className="col-6">Interest Rate:</dt>
                <dd
                  className="col-6"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "0px",
                  }}
                >
                  {closeFdDetails?.fdScheme?.interestRate}
                </dd>
                <dt className="col-6">TDS Rate:</dt>
                <dd
                  className="col-6"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "0px",
                  }}
                >
                  {closeFdDetails?.fdScheme?.tdsRate}
                </dd>
                <hr />
                <dt className="col-6">Unique ID:</dt>
                <dd
                  className="col-6"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "0px",
                  }}
                >
                  {closeFdDetails.uniqueId}
                </dd>
                <hr />
                <dt className="col-6">Status:</dt>
                <dd
                  className="col-6"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "0px",
                  }}
                >
                  {closeFdDetails?.fdScheme?.status}
                </dd>
              </div>
              <div
                className="text-center mt-3"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  style={{
                    backgroundColor: "rgb(74 42 125)",
                    width: "6rem",
                    padding: "7px 14px",
                    borderRadius: "5px",
                    fontSize: "13px",
                    color: "white",
                  }}
                  onClick={closeCreateModal}
                >
                  Exit
                </Button>
                <Button
                  style={{
                    backgroundColor: "rgb(74 42 125)",
                    width: "6rem",
                    padding: "7px 14px",
                    borderRadius: "5px",
                    fontSize: "13px",
                    color: "white",
                  }}
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure?",
                      text: `Are you sure you want to Close this FD?`,
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleCancelOrder(selectedId);
                      }
                    });
                  }}
                >
                  Close FD
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default FdHistory;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Swal from "sweetalert2";
// import {
//   MaterialReactTable,
//   createMRTColumnHelper,
//   useMaterialReactTable,
// } from "material-react-table";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import { Box, IconButton } from "@mui/material";
// import Button from "@mui/material/Button";
// import { mkConfig, generateCsv, download } from "export-to-csv";
// import { baseUrl } from "../../utils/baseUrl";
// import moment from "moment/moment";
// import { useNavigate } from "react-router-dom";
// import TableSkeleton from "../../utils/TableSkeleton";

// const columnHelper = createMRTColumnHelper();

// const columns = [
//   columnHelper.accessor("id", {
//     header: "FD Id",
//     size: 40,
//     Cell: (cell) => (
//       <div style={{ textAlign: "center" }}>{cell.row.original.data.id}</div>
//     ),
//   }),
//   columnHelper.accessor("userAccountName", {
//     header: "Client",
//     size: 40,
//     Cell: (cell) => (
//       <div style={{ textAlign: "center" }}>
//         {cell.row.original.data.userAccountName}
//       </div>
//     ),
//   }),
//   columnHelper.accessor("schemaName", {
//     header: "Scheme Name",
//     size: 40,
//     Cell: (cell) => (
//       <div style={{ textAlign: "center" }}>
//         {cell.row.original.data.schemaName}
//       </div>
//     ),
//   }),
//   columnHelper.accessor("status", {
//     header: "Status",
//     size: 40,
//     Cell: (cell) => (
//       <span
//         style={{
//           backgroundColor: "rgb(74 42 125)",
//           padding: "7px 14px",
//           borderRadius: "12px",
//           fontSize: "13px",
//           color: "white",
//         }}
//       >
//         {cell.row.original.data.status}
//       </span>
//     ),
//   }),
//   columnHelper.accessor("goldLease", {
//     header: "Quantity (gm)",
//     size: 40,
//     Cell: (cell) => (
//       <div style={{ textAlign: "center" }}>
//         {cell.row.original.data.goldLease}gm
//       </div>
//     ),
//   }),
//   columnHelper.accessor("interestRate", {
//     header: "Interest Rate (%)",
//     size: 40,
//     Cell: (cell) => (
//       <div style={{ textAlign: "center" }}>
//         {cell.row.original.data.interestRate}%
//       </div>
//     ),
//   }),

//   columnHelper.accessor("startDate", {
//     header: "Start Date",
//     size: 40,
//     Cell: (cell) => (
//       <div style={{ textAlign: "center" }}>
//         {moment(cell.row.original.data.startDate).format("DD-MM-YYYY")}
//       </div>
//     ),
//   }),
//   columnHelper.accessor("endDate", {
//     header: "End Date",
//     size: 40,
//     Cell: (cell) => (
//       <div style={{ textAlign: "center" }}>
//         {moment(cell.row.original.data.endDate).format("DD-MM-YYYY")}
//       </div>
//     ),
//   }),
// ];

// const csvConfig = mkConfig({
//   fieldSeparator: ",",
//   decimalSeparator: ".",
//   useKeysAsHeaders: true,
// });

// const FdHistory = () => {
//   const Token = sessionStorage.getItem("token");
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);
//   const [fdHistory, setFdHistory] = useState([]);
//   const [selectedId, setSelectedId] = useState(null);

//   useEffect(() => {
//     getFdHistory();
//   }, []);

//   const getFdHistory = async () => {
//     try {
//       const url = `${baseUrl}/partnerPortal/fd/orderList`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.get(url, { headers });

//       // Log the response to understand its structure

//       const activeFD = response.data.data?.FdActive || [];
//       console.log("API Response:==========", activeFD);
//       activeFD.forEach((fd) => {
//         console.log("Transaction ID:", fd.data?.transactionId);
//       });

//       setLoading(false);
//       setFdHistory(activeFD);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const handleExportRows = (rows) => {
//     const rowData = rows.map((row) => ({
//       id: row.original.data.id,
//       userAccountName: row.original.data.userAccountName,
//       schemaName: row.original.data.schemaName,
//       goldLease: row.original.data.goldLease,
//       interestRate: row.original.data.interestRate,
//       startDate: row.original.data.startDate,
//       endDate: row.original.data.endDate,
//     }));
//     const csv = generateCsv(csvConfig)(rowData);
//     download(csvConfig)(csv);
//   };

//   const handleExportData = () => {
//     const rowData = fdHistory?.map((data) => ({
//       id: data.data.id,
//       userAccountName: data.data.userAccountName,
//       schemaName: data.data.schemaName,
//       goldLease: data.data.goldLease,
//       interestRate: data.data.interestRate,
//       startDate: data.data.startDate,
//       endDate: data.data.endDate,
//     }));
//     const csv = generateCsv(csvConfig)(rowData);
//     download(csvConfig)(csv);
//   };

//   const table = useMaterialReactTable({
//     columns,
//     data: fdHistory,
//     enableRowSelection: true,
//     enableGlobalFilter: true,
//     columnFilterDisplayMode: "popover",
//     enableColumnActions: false,
//     enableColumnFilters: false,
//     enableSorting: true,
//     enableDensityToggle: false,
//     enableHiding: false,
//     maxSize: 100,
//     enableRowActions: true,
//     positionActionsColumn: "last",
//     muiTableHeadCellProps: {
//       sx: (theme) => ({
//         color: "#4a2a7d",
//         background: "#DCDAFF",
//         fontWeight: "normal",
//         fontSize: "10px",
//       }),
//     },
//     renderRowActions: ({ row }) => [
//       <Box key={row.id}>
//         <IconButton
//           onClick={() => {
//             setSelectedId(row.original.data.transactionId);
//             Swal.fire({
//               title: "Are you sure?",
//               text: "Are you sure you want to Close this FD?",
//               icon: "warning",
//               showCancelButton: true,
//               confirmButtonColor: "#3085d6",
//               cancelButtonColor: "#d33",
//               confirmButtonText: "Yes, close it!",
//             }).then((result) => {
//               if (result.isConfirmed) {
//                 handleCancelOrder(row.original.data.transactionId);
//               }
//             });
//           }}
//           style={{
//             backgroundColor: "rgb(74 42 125)",
//             width: "6rem",
//             padding: "7px 14px",
//             borderRadius: "12px",
//             fontSize: "13px",
//             color: "white",
//           }}
//         >
//           Close FD
//         </IconButton>
//       </Box>,
//     ],
//     renderTopToolbarCustomActions: ({ table }) => (
//       <Box
//         sx={{
//           display: "flex",
//           gap: "16px",
//           padding: "8px",
//           flexWrap: "wrap",
//         }}
//       >
//         <Button
//           style={{ backgroundColor: "none" }}
//           onClick={handleExportData}
//           startIcon={<FileDownloadIcon />}
//         >
//           Export All Data
//         </Button>
//         <Button
//           disabled={
//             !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
//           }
//           onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
//           startIcon={<FileDownloadIcon />}
//         >
//           Export Selected Rows
//         </Button>
//       </Box>
//     ),
//   });

//   const handleCancelOrder = async (transactionId) => {
//     try {
//       const url = `${baseUrl}/partnerPortal/fd/preorderClose/${transactionId}`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.put(url, {}, { headers });
//       console.log("Order cancelled", response);

//       Swal.fire("Closed!", "Your FD has been closed.", "success");

//       // Refresh FD history after cancellation
//       getFdHistory();
//     } catch (error) {
//       console.error("Error cancelling the order", error);
//       Swal.fire("Error", "Could not cancel the FD. Please try again.", "error");
//     }
//   };

//   const preorderCloseCalculate = async (selectedId) => {
//     try {
//       const url = `${baseUrl}/partnerPortal/fd/preorderCloseCalculate/${selectedId}`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.put(url, {}, { headers });
//       console.log("Preorder close calculated", response);
//     } catch (error) {
//       console.error("Error calculating preorder close", error);
//       Swal.fire(
//         "Error",
//         "Could not calculate preorder close. Please try again.",
//         "error"
//       );
//     }
//   };

//   useEffect(() => {
//     preorderCloseCalculate(selectedId);
//   }, [selectedId]);

//   return (
//     <>
//       <Box sx={{ margin: "2%" }}>
//         {loading ? (
//           <TableSkeleton columns={columns.length} />
//         ) : (
//           <MaterialReactTable table={table} />
//         )}
//       </Box>
//     </>
//   );
// };

// export default FdHistory;
