import { createSlice } from '@reduxjs/toolkit'

const clientDataSlice = createSlice({
    name: 'clientDataSlice',
    initialState: {
       
    },
    reducers: {
        Addclient: (state, action) => {
            state = action.payload;
        },

    }
});



export const { Addclient } = clientDataSlice.actions
export default clientDataSlice.reducer
