import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import moment from "moment/moment";
import {
  Delete as DeleteIcon,
  GroupAdd as GroupAddIcon,
  RemoveRedEye,
} from "@mui/icons-material";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { ToastContainer, toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import TableSkeleton from "../utils/TableSkeleton";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("proposal_no", {
    header: "Order Number",
    size: 40,
  }),
  columnHelper.accessor("client_name", {
    header: "Client",
    size: 40,
  }),
  columnHelper.accessor("metal_type", {
    header: "Metal Type",
    size: 40,
  }),
  columnHelper.accessor("id", {
    header: "Link",
    size: 40,
    Cell: (cell) => (
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(
            `${baseUrl}/proposaldetails/${cell.row.original._id}`
          );
          toast.success("Link copied to clipboard!");
        }}
      >
        <ContentCopyIcon />
      </IconButton>
    ),
  }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 40,
    Cell: (cell) => (
      <span
        style={{
          backgroundColor: "rgb(74 42 125)",
          padding: " 7px 14px",
          borderRadius: "12px",
          fontSize: "13px",
          color: "white",
        }}
      >
        {cell.row.original.status}
      </span>
    ),
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    size: 40,
    Cell: (cell) => "₹ " + cell.row.original.amount,
  }),
  columnHelper.accessor("createdAt", {
    header: "Created At",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {moment(cell.row.original.createdAt.split("T")[0]).format("DD-MM-YYYY")}
      </div>
    ),
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const Inprogresstable = () => {
  const [loading, setLoading] = useState(true);
  const [metal_type, setMetalType] = useState("");
  const token = sessionStorage.getItem("token");
  const [progressData, setprogressData] = useState([]);
  const [openProgressModel, setopenProgressModel] = useState(false);
  const [quantity, setquantity] = useState("");
  const [amount, setAmount] = useState("");
  const [editModalIsOpen, seteditModalIsOpen] = useState(false);
  const [purposalDataSpecific, setpurposalDataSpecific] = useState(null);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    GetInprogressClient();
  }, []);

  const GetInprogressClient = async () => {
    try {
      const url = `${baseUrl}/getProposal`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const employees = response.data.data.PendingProposals;
      employees?.map((index, item) => {
        index.slno = ++item;
      });
      setLoading(false);
      setprogressData(response.data.data.PendingProposals);
      setEmployees(employees);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      proposal_no: row.original.proposal_no,
      client_name: row.original.client_name,
      metal_type: row.original.metal_type,
      amount: row.original.amount,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const rowData = progressData.map((data) => ({
      proposal_no: data.proposal_no,
      client_name: data.client_name,
      metal_type: data.metal_type,
      amount: data.amount,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };
  const table = useMaterialReactTable({
    columns,
    data: employees,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ cell, row, table }) => [
      <Box>
        <IconButton color="error" onClick={() => deletePurposal(row.original)}>
          <DeleteIcon color="error" />
        </IconButton>
      </Box>,
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          Export All Data
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });
  const editPurposal = async (row) => {
    setpurposalDataSpecific(row);

    setMetalType(row.metal_type);
    setquantity(row.quantity);
    setAmount(row.amount);
    seteditModalIsOpen(true);
  };

  const handleSave = async () => {
    if (purposalDataSpecific !== null) {
      try {
        const url = `${baseUrl}/proposalUpdate/${purposalDataSpecific._id}`;
        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.post(
          url,
          { metal_type: metal_type },
          { headers }
        );
        if (response.status === 200) {
          Swal.fire({
            title: "Thank You!",
            text: "Purposal updated Successfully!",
            icon: "success",
          });

          response.data.data?.map((item) => {
            setMetalType(item.metal_type);
            setquantity(item.quantity);
            setAmount(item.amount);
          });
          GetInprogressClient();
          seteditModalIsOpen(false);
        }
      } catch (error) {
        console.error("Error connecting to API", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="#">Why do I have this issue?</a>',
        });
      }
    } else {
    }
  };

  const deletePurposal = async (row) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${baseUrl}/proposalDelete/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          GetInprogressClient();
        } catch (error) {
          console.error("Error connecting to API", error);
        }
        swalWithBootstrapButtons.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",

          customClass: {
            confirmButton: "my-custom-button-class",
          },
        });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your imaginary file is safe :)",
          icon: "error",
        });
      }
    });
  };
  return (
    <>
      <ToastContainer />
      {loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}
    </>
  );
};

export default Inprogresstable;

// import React, { useCallback, useEffect, useState } from "react";
// import axios from "axios";
// import Button from "@mui/material/Button";
// import Swal from "sweetalert2";
// import { IconButton } from "@mui/material";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import {
//   Delete as DeleteIcon,
//   GroupAdd as GroupAddIcon,
//   RemoveRedEye,
// } from "@mui/icons-material";
// import {
//   MaterialReactTable,
//   createMRTColumnHelper,
//   useMaterialReactTable,
// } from "material-react-table";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import { Box } from "@mui/material";
// import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
// import { data } from "./makeData";
// import { ToastContainer, toast } from "react-toastify";
// import { baseUrl } from "../utils/baseUrl";

// const columnHelper = createMRTColumnHelper();

// const columns = [
//   columnHelper.accessor("proposal_no", {
//     header: "Order Number",
//     size: 40,
//   }),
//   columnHelper.accessor("client_name", {
//     header: "Client",
//     size: 40,
//   }),
//   columnHelper.accessor("metal_type", {
//     header: "Metal Type",
//     size: 40,
//   }),
//   columnHelper.accessor("id", {
//     header: "Link",
//     size: 40,
//     Cell: (cell) => (
//       <IconButton
//         onClick={() => {
//           navigator.clipboard.writeText(
//             `${baseUrl}/proposaldetails/${cell.row.original._id}`
//           );
//           toast.success("Link copied to clipboard!");
//         }}
//       >
//         <ContentCopyIcon />
//       </IconButton>
//     ),
//   }),
//   columnHelper.accessor("status", {
//     header: "Status",
//     size: 40,
//     Cell: (cell) => (
//       <span
//         style={{
//           backgroundColor: "rgb(74 42 125)",
//           padding: " 7px 14px",
//           borderRadius: "12px",
//           fontSize: "13px",
//           color: "white",
//         }}
//       >
//         {cell.row.original.status}
//       </span>
//     ),
//   }),
//   columnHelper.accessor("amount", {
//     header: "Amount",
//     size: 40,
//     Cell: (cell) => "₹ " + cell.row.original.amount,
//   }),
// ];

// const csvConfig = mkConfig({
//   fieldSeparator: ",",
//   decimalSeparator: ".",
//   useKeysAsHeaders: true,
// });

// const Inprogresstable = () => {
//   const [metal_type, setMetalType] = useState("");
//   const token = sessionStorage.getItem("token");
//   const [progressData, setprogressData] = useState();
//   const [openProgressModel, setopenProgressModel] = useState(false);
//   const [quantity, setquantity] = useState("");
//   const [amount, setAmount] = useState("");
//   const [editModalIsOpen, seteditModalIsOpen] = useState(false);
//   const [purposalDataSpecific, setpurposalDataSpecific] = useState(null);
//   const [employees, setEmployees] = useState([]);

//   useEffect(() => {
//     GetInprogressClient();
//   }, []);

//   const GetInprogressClient = async () => {
//     try {
//       const url = `${baseUrl}/getProposal`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.get(url, { headers });
//       // const employees = response.data.data;
//       console.log("In Progress Api data", response.data.data.PendingProposals);
//       const employees = response.data.data.PendingProposals;
//       // setprogressData(response.data.data.PendingProposals);
//       employees?.map((index, item) => {
//         // console.log("Employee list=", employees.index.id);
//         //  setclientCreatedDate(item.createdAt);

//         // employees.set("slno",++item)
//         index.slno = ++item;
//       });
//       setprogressData(response.data.data.PendingProposals);
//       setEmployees(employees);
//       // console.log("employee")
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const [query, setQuery] = useState("");

//   const handleInputChange = (e) => {
//     setQuery(e.target.value);
//   };

//   const handleSearch = (e) => {
//     e.preventDefault();
//     console.log("data input search", query);
//   };

//   // useEffect(() => {
//   //   editPurposal();
//   // }, []);
//   const handleExportRows = (rows) => {
//     const rowData = rows.map((row) => ({
//       proposal_no: row.original.proposal_no,
//       client_name: row.original.client_name,
//       metal_type: row.original.metal_type,
//       amount: row.original.amount,
//     }));
//     const csv = generateCsv(csvConfig)(rowData);
//     download(csvConfig)(csv);
//   };

//   const handleExportData = (rows) => {
//     const rowData = rows.map((row) => ({
//       proposal_no: row.original.proposal_no,
//       client_name: row.original.client_name,
//       metal_type: row.original.metal_type,
//       amount: row.original.amount,
//     }));
//     const csv = generateCsv(csvConfig)(rowData);
//     download(csvConfig)(csv);
//   };
//   const table = useMaterialReactTable({
//     columns,
//     data: employees,
//     enableRowSelection: true, //enable some features
//     // enableColumnOrdering: true, //enable a feature for all columns
//     enableGlobalFilter: true, //turn off a feature
//     columnFilterDisplayMode: "popover",
//     enableColumnActions: false,
//     enableColumnFilters: false,
//     enableSorting: true,
//     enableDensityToggle: false,
//     enableHiding: false,
//     maxSize: 100,
//     enableRowActions: true,
//     positionActionsColumn: "last",
//     muiTableHeadCellProps: {
//       //simple styling with the `sx` prop, works just like a style prop in this example

//       sx: (theme) => ({
//         color: "#4a2a7d",
//         background: "#DCDAFF",
//         fontWeight: "normal",
//         fontSize: "10px",
//       }),
//     },
//     renderRowActions: ({ cell, row, table }) => [
//       <Box>
//         <IconButton color="error" onClick={() => deletePurposal(row.original)}>
//           <DeleteIcon color="error" />
//         </IconButton>
//       </Box>,
//     ],
//     renderTopToolbarCustomActions: ({ table }) => (
//       <Box
//         sx={{
//           display: "flex",
//           gap: "16px",
//           padding: "8px",
//           flexWrap: "wrap",
//         }}
//       >
//         <Button
//
//           onClick={() => handleExportData(table.getRowModel().rows)}
//           startIcon={<FileDownloadIcon />}
//         >
//           Export All Data
//         </Button>

//         <Button
//
//           disabled={
//             !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
//           }
//           onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
//           startIcon={<FileDownloadIcon />}
//         >
//           Export Selected Rows
//         </Button>
//       </Box>
//     ),
//   });
//   const editPurposal = async (row) => {
//     setpurposalDataSpecific(row);

//     setMetalType(row.metal_type);
//     setquantity(row.quantity);
//     setAmount(row.amount);
//     seteditModalIsOpen(true);

//     //  await handleSave(row);
//   };

//   const handleSave = async () => {
//     if (purposalDataSpecific !== null) {
//       try {
//         const url = `${baseUrl}/proposalUpdate/${purposalDataSpecific._id}`;
//         const headers = {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         };
//         const response = await axios.post(
//           url,
//           { metal_type: metal_type },
//           { headers }
//         );
//         // const employees = response.data.data;
//         console.log("In Progress update Api data", response);
//         if (response.status === 200) {
//           Swal.fire({
//             title: "Thank You!",
//             text: "Purposal updated Successfully!",
//             icon: "success",
//           });
//           // setprogressData(response.data.data);

//           response.data.data?.map((item) => {
//             console.log("item", item);
//             setMetalType(item.metal_type);
//             setquantity(item.quantity);
//             setAmount(item.amount);
//           });
//           GetInprogressClient();
//           seteditModalIsOpen(false);
//         }
//       } catch (error) {
//         console.error("Error connecting to API", error);
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "Something went wrong!",
//           footer: '<a href="#">Why do I have this issue?</a>',
//         });
//       }
//     } else {
//       console.log("purposal data is none");
//     }
//   };

//   const closeEditModal = () => {
//     seteditModalIsOpen(false);
//   };
//   //   const handleSave = async (row) => {
//   // console.log("row edit",row)
//   //     try {
//   //       const url = `https://app.batuk.in/proposalUpdate/${row._id}`;
//   //       const headers = {
//   //         "Content-Type": "application/json",
//   //         Accept: "application/json",
//   //         Authorization: `Bearer ${Token}`,
//   //       };
//   //       const response = await axios.post(url,{ "metal_type":metal_type}, { headers });
//   //       // const employees = response.data.data;
//   //       console.log("In Progress update Api data", response);
//   //       seteditModalIsOpen(false);

//   //       // console.log("employee")
//   //     } catch (error) {
//   //       console.error("Error connecting to API", error);
//   //     }

//   //   };

//   const deletePurposal = async (row) => {
//     console.log("row", row);
//     const swalWithBootstrapButtons = Swal.mixin({
//       customClass: {
//         confirmButton: "btn btn-success",
//         cancelButton: "btn btn-danger",
//       },
//       buttonsStyling: false,
//     });
//     Swal.fire({
//       title: "Are you sure?",
//       text: "You won't be able to revert this!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#4A2A7D",
//       cancelButtonColor: "#d33",
//     }).then(async (result) => {
//       if (result.isConfirmed) {
//         // =============================================================================

//         try {
//           const url = `${baseUrl}/proposalDelete/${row._id}`;
//           const headers = {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           };
//           const response = await axios.delete(url, { headers });
//           // const employees = response.data.data;
//           console.log("In Progress update Api data", response);
//           GetInprogressClient();

//           // console.log("employee")
//         } catch (error) {
//           console.error("Error connecting to API", error);
//         }

//         // =============================================================================
//         swalWithBootstrapButtons.fire({
//           title: "Deleted!",
//           text: "Your file has been deleted.",
//           icon: "success",

//           customClass: {
//             confirmButton: "my-custom-button-class",
//           },
//         });
//       } else if (
//         /* Read more about handling dismissals below */
//         result.dismiss === Swal.DismissReason.cancel
//       ) {
//         swalWithBootstrapButtons.fire({
//           title: "Cancelled",
//           text: "Your imaginary file is safe :)",
//           icon: "error",
//         });
//       }
//     });
//   };
//   return (
//     <>
//       <ToastContainer />
//       <MaterialReactTable table={table} />
//     </>
//     //     <div className="conatiner-fluid  p-2">
//     //       <div className="row">
//     //         <div className="col-sm-12 client">
//     //           <Paper>
//     //             <TableContainer>
//     //               <Table>
//     //                 <TableHead sx={{ backgroundColor: "#F2F3F7", color: "black" }}>
//     //                   <TableRow>
//     //                   <TableCell align="center">#</TableCell>

//     //                     <TableCell align="center">Proposal Number</TableCell>
//     //                     <TableCell align="center">Client</TableCell>
//     //                     <TableCell align="center">Metal Type</TableCell>

//     //                     <TableCell align="center">Status</TableCell>

//     //                     <TableCell align="center">Amount</TableCell>

//     //                     <TableCell align="center">Action</TableCell>
//     //                   </TableRow>
//     //                 </TableHead>
//     //                 <TableBody>
//     //                   {progressData &&
//     //                     progressData.map((row, index) => {
//     //                       return (
//     //                         <TableRow
//     //                           // key={}
//     //                           sx={{
//     //                             "&:last-child td, &:last-child th": { border: 0 },
//     //                             backgroundColor: "#ffff",
//     //                           }}>
//     //                           <TableCell align="center">
//     //                             {index + 1}
//     //                           </TableCell>
//     //                           <TableCell align="center">
//     //                             {row.proposal_no}
//     //                           </TableCell>
//     //                           <TableCell align="center">
//     //                             {row.client_name}
//     //                           </TableCell>
//     //                           <TableCell align="center">
//     //                             {row.metal_type
//     // }
//     //                           </TableCell>
//     //                           <TableCell align="center">
//     //                             <span
//     //                               style={{
//     //                                 backgroundColor: "rgb(74 42 125)",
//     //                                 padding: " 7px 14px",
//     //                                 borderRadius: "12px",
//     //                                 fontSize: "13px",
//     //                                 color: "white",
//     //                               }}>
//     //                               {row.status}
//     //                             </span>
//     //                           </TableCell>
//     //                           <TableCell align="center">{"₹ "+row.amount}</TableCell>

//     //                           <TableCell align="center">
//     //                             {/* <Button
//     //                               variant="contained"
//     //                               onClick={() => editPurposal(row)}
//     //                               sx={{ marginRight: "10px" }}>
//     //                               Edit
//     //                             </Button> */}
//     //                             <Button
//     //                               variant="contained"
//     //                               onClick={() => deletePurposal(row)}>
//     //                               Delete
//     //                             </Button>
//     //                           </TableCell>
//     //                         </TableRow>
//     //                       );
//     //                     })}

//     //                   {console.log("progressData", progressData)}
//     //                   {/* ================================================================ */}
//     //                 </TableBody>
//     //               </Table>
//     //             </TableContainer>
//     //             {editModalIsOpen && (
//     //                     <Modal
//     //                       isOpen={editModalIsOpen}
//     //                       onRequestClose={closeEditModal}
//     //                       contentLabel="Edit Employee"
//     //                       style={{
//     //                         overlay: {
//     //                           backgroundColor: "rgba(0, 0, 0, 0.5)",
//     //                         },
//     //                         content: {
//     //                           top: "50%",
//     //                           left: "50%",
//     //                           right: "auto",
//     //                           bottom: "auto",
//     //                           marginRight: "-50%",
//     //                           transform: "translate(-50%, -50%)",
//     //                           width: "50%",
//     //                         },
//     //                       }}>
//     //                       <h3 className="text-center fw-bold form_header">
//     //                         Edit Purposal
//     //                       </h3>
//     //                       <form>
//     //                         <div className="form-group p-2">
//     //                           <label>Metal Type</label>

//     //                           <input
//     //                             type="text"
//     //                             name="name"
//     //                             value={metal_type}
//     //                             onChange={(e) => setMetalType(e.target.value)}
//     //                           />
//     //                         </div>
//     //                         <div className="form-group p-2">
//     //                           <label>Quantity</label>
//     //                           <input
//     //                             type="text"
//     //                             name="Email"
//     //                             value={quantity}
//     //                             onChange={(e) => setquantity(e.target.value)}
//     //                           />
//     //                         </div>
//     //                         <div className="form-group p-2">
//     //                           <label>Amount</label>
//     //                           <input
//     //                             type="text"
//     //                             name="designation"
//     //                             value={amount}
//     //                             onChange={(e) => setAmount(e.target.value)}
//     //                           />
//     //                         </div>

//     //                         <Button
//     //                           variant="contained"
//     //                           type="button"
//     //                           className="btn btn-secondary mt-2"
//     //                           style={{ float: "left" }}
//     //                           onClick={closeEditModal}>
//     //                           Cancel
//     //                         </Button>
//     //                         <Button
//     //                           variant="contained"
//     //                           type="button"
//     //                           className="btn btn-primary mt-2"
//     //                           style={{ float: "right" }}
//     //                           onClick={() => handleSave()}>
//     //                           Update
//     //                         </Button>
//     //                       </form>
//     //                     </Modal>
//     //                   )}
//     //             <TablePagination
//     //               rowsPerPageOptions={[5, 10, 25]}
//     //               component="div"
//     //               count={15}
//     //               rowsPerPage={rowsPerPage}
//     //               page={page}
//     //               onPageChange={handleChangePage}
//     //               onRowsPerPageChange={handleChangeRowsPerPage}
//     //             />
//     //           </Paper>
//     //         </div>
//     //       </div>
//     //     </div>
//   );
// };

// export default Inprogresstable;
