/**
 * Converts an Excel date serial number to a readable date format (DD/MM/YYYY).
 * @param {number} excelDate - The Excel date serial number.
 * @returns {string} - The formatted date as DD/MM/YYYY.
 */
export const formatDate = (excelDate) => {
  if (!excelDate) return "";

  // Convert Excel serial date to JavaScript Date object
  const jsDate = new Date((excelDate - 25569) * 86400 * 1000);

  // Format the date as DD/MM/YYYY
  const day = String(jsDate.getDate()).padStart(2, "0");
  const month = String(jsDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const year = jsDate.getFullYear();

  return `${day}/${month}/${year}`;
};
